import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  id?: string;
}

export default function PropertyHeading(props: Props) {
  return (
    <Box className="propHeading" userSelect="none" {...props}>
      {props.children}
    </Box>
  );
}
