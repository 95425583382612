import { Flex, Heading, IconButton, Kbd, Stack, Text } from '@chakra-ui/react';
import { BsLayers } from 'react-icons/bs';
import { RiArrowLeftLine, RiChat3Line, RiText } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'src/components/Tooltip';
import Invite from 'src/features/Invite';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { selectTool } from 'src/store/reducers/storyboard';
import { ToolType } from 'src/types';
import { ROUTES } from 'src/utils/constants';
import { SHORTCUTS } from 'src/utils/shortcuts';

import Collaborators from 'src/features/Collaboration/components/Collaborators';
import { Shortcut } from 'src/components/Shortcut';

const StoryboardTools = () => {
  const dispatch = useAppDispatch();
  const activeTool = useAppSelector((store) => store.storyboard.tool);

  const handleOnToolClick = (newTool: ToolType | undefined) => {
    dispatch(selectTool(newTool));
  };

  return (
    <Flex gap={2}>
      <Tooltip label={<Shortcut shortcut={SHORTCUTS.addText} />}>
        <IconButton
          size="sm"
          bgColor={activeTool === ToolType.text ? 'blue.500' : '#3d3d3d'}
          onClick={() => handleOnToolClick(ToolType.text)}
          color="#e6e6e6"
          variant="outline"
          colorScheme="white"
          borderColor="gray"
          aria-label={SHORTCUTS.addText.title}
          icon={<RiText size={20} />}
        />
      </Tooltip>
      <Tooltip label={<Shortcut shortcut={SHORTCUTS.addScene} />}>
        <IconButton
          size="sm"
          bgColor={activeTool === ToolType.scene ? 'blue.500' : '#3d3d3d'}
          borderColor="gray"
          onClick={() => handleOnToolClick(ToolType.scene)}
          variant="outline"
          colorScheme="white"
          aria-label={SHORTCUTS.addScene.title}
          color="#e6e6e6"
          icon={<BsLayers size={20} />}
        />
      </Tooltip>
      <Tooltip label={<Shortcut shortcut={SHORTCUTS.addComment} />}>
        <IconButton
          size="sm"
          disabled
          onClick={() => handleOnToolClick(ToolType.comment)}
          bgColor={activeTool === ToolType.comment ? 'blue.500' : '#3d3d3d'}
          borderColor="gray"
          colorScheme="white"
          variant="outline"
          color="#e6e6e6"
          aria-label={SHORTCUTS.addComment.title}
          icon={<RiChat3Line size={20} />}
        />
      </Tooltip>
    </Flex>
  );
};

const BoardTools = () => {
  const navigate = useNavigate();

  return (
    <Flex w="100%" alignItems="center" justifyContent="space-between">
      <Flex flexBasis="30%" justifyContent="flex-start" gap={5}>
        <Tooltip label="Back to projects">
          <IconButton
            size="sm"
            borderColor="gray"
            bgColor="#3d3d3d"
            color="#eee"
            colorScheme="white"
            variant="outline"
            onClick={() => {
              navigate(ROUTES.projects);
            }}
            aria-label="Add Comment"
            icon={<RiArrowLeftLine size={18} />}
          />
        </Tooltip>
        <StoryboardTools />
      </Flex>
      <Flex flexBasis="40%" justifyContent="center">
        <Heading as="h4" m={0} size="md" color="#e6e6e6" userSelect="none">
          Storyboard
        </Heading>
      </Flex>
      <Flex flexBasis="30%" gap={2} justifyContent="flex-end">
        <Collaborators />
        <Invite />
      </Flex>
    </Flex>
  );
};

export default BoardTools;
