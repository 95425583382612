import { supabase } from 'src/SupabaseClient';

export const createStorylineAPI = async (projectId: string, storylineId: string) => {
  const { data: res } = await supabase
    .from('storyline')
    .insert({ project_id: projectId, id: storylineId })
    .select()
    .single();

  if (res) return res;
};

export const deleteStorylineAPI = async (projectId: string, storylineId: string) => {
  const { data: res } = await supabase
    .from('storyline')
    .delete()
    .eq('project_id', projectId)
    .eq('id', storylineId);

  return res;
};
