import { useAppDispatch } from '../store/reducers/hook';
import {
  enableCollaboration,
  followUser,
  removeOnlineUsers,
  setOnlineUsers,
  updateFloatingUser,
  updateOnlineUser,
} from '../store/reducers/collaboration';
import { stringToColor } from '../utils/colors';
import store from 'src/store/store';

type UserPresence = {
  id: string;
  joinedAt: number;
};

const getCollaborator = (info: UserPresence) => ({
  ...info,
  cursor: {
    position: null,
    timestamp: null,
  },
  active: true,
  color: stringToColor(info.id),
});

export const useCollaboration = () => {
  const dispatch = useAppDispatch();

  const addCollaborator = (info: UserPresence | UserPresence[]) => {
    const collaborator = Array.isArray(info)
      ? info.reduce((acc, user) => {
          acc[user.id] = getCollaborator(user);
          return acc;
        }, {} as any)
      : { [info.id]: getCollaborator(info) };

    dispatch(setOnlineUsers(collaborator));
  };

  const removeCollaborator = (userIds: string | string[]) => {
    const collaborator = Array.isArray(userIds) ? userIds : [userIds];
    dispatch(removeOnlineUsers(collaborator));
  };

  const updateCollaborator = (userId: string, data: Record<string, any>) => {
    if (!userId) return;

    dispatch(updateOnlineUser({ userId, data }));
  };

  const updateFloatingUserDetails = (userId: string, data: any) => {
    dispatch(updateFloatingUser({ userId, data }));
  };

  const enableCollab = (enable: boolean) => {
    dispatch(enableCollaboration(enable));
  };

  const followCollaborator = (userId: string | null) => {
    dispatch(followUser(userId));
  };

  const isCollaborator = (userId: string) => {
    return !!store.getState().collaboration.storyboard[userId];
  };

  return {
    addCollaborator,
    removeCollaborator,
    updateCollaborator,
    enableCollab,
    isCollaborator,
    followCollaborator,
    updateFloatingUserDetails,
  };
};
