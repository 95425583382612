import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useState } from 'react';
import type { RoleType } from 'src/types';

type RoleMenuProps = ButtonProps & {
  role?: RoleType;
  onChangeRole: (role: RoleType) => Promise<void> | void;
  onRevokeAccess?: () => Promise<void> | void;
};

const ROLE_OPTIONS = ['edit', 'view'] as Array<RoleType>;

const RoleMenu = ({ role = 'edit', onChangeRole, onRevokeAccess, ...props }: RoleMenuProps) => {
  const [selectedRole, setSelectedRole] = useState<RoleType>(role);

  return (
    <Menu isLazy>
      <MenuButton
        {...props}
        fontWeight="600"
        color="white"
        colorScheme="whiteAlpha"
        variant="ghost"
        as={Button}
        rightIcon={<ChevronDownIcon />}
      >
        {selectedRole}
      </MenuButton>
      <MenuList zIndex={10}>
        {ROLE_OPTIONS.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              onChangeRole(option);
              setSelectedRole(option);
            }}
          >
            {option}
          </MenuItem>
        ))}
        {onRevokeAccess && <MenuItem onClick={onRevokeAccess}>Revoke Access</MenuItem>}
      </MenuList>
    </Menu>
  );
};

export default RoleMenu;
