import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    dialog: {
      borderRadius: 'md',
      bg: 'rgba(49,49,49,255)',
      color: 'white',
      padding: '5px 10px',

      fontSize: '12px',
      _dark: {
        bg: `rgba(49,49,49,255)`,
        color: 'white',
      },
    },
  };
});

const xl = defineStyle({
  fontSize: '12px',
  width: '700px',
});

const sm = defineStyle({
  fontSize: 'sm',
  py: '2',
  pt: '8',
});

const sizes = {
  xl: definePartsStyle({ dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    colorScheme: 'rgba(49,49,49,255)', //set the default color scheme to purple
    size: 'xl',
  },
});
