import { createSlice } from '@reduxjs/toolkit';
import { Edge, Node, Viewport, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import { getHelperLines } from 'src/features/StoryBoard/helper';
import { ModalAction, Mode, ModeState, ToolType } from 'src/types';

const initialState = {
  nodes: [] as Node[],
  edges: [] as Edge[],
  mode: ModeState.move as Mode,
  tool: undefined as ToolType | undefined,
  modal: undefined as ModalAction | undefined,
  viewport: undefined as Viewport | undefined,
  connectingEdge: undefined,
  helperLines: {
    horizontal: undefined as any,
    vertical: undefined as any,
  },
};

const storyboardSlice = createSlice({
  name: 'storyboard',
  initialState,
  reducers: {
    onNodesChange(state, { payload: changes }) {
      // reset the helper lines (clear existing lines, if any)
      state.helperLines.horizontal = undefined;
      state.helperLines.vertical = undefined;

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], state.nodes);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        state.helperLines.horizontal = helperLines.horizontal;
        state.helperLines.vertical = helperLines.vertical;
      }

      state.nodes = applyNodeChanges(changes, state.nodes);
    },

    onEdgesChange(state, { payload: changes }) {
      state.edges = applyEdgeChanges(changes, state.edges);
    },

    setNodes: (state, { payload: nodes }) => {
      state.nodes = nodes;
    },

    removeNode: (state, { payload: nodeId }) => {
      state.nodes = state.nodes.filter((node) => node.id !== nodeId);
    },

    setEdges: (state, { payload: edges }) => {
      state.edges = edges;
    },

    addNode: (state, { payload: node }) => {
      if (state.nodes.find((n) => n.id === node.id)) return;
      state.nodes = [...state.nodes, node];
    },

    changeNodeId: (state, { payload: { oldId, newId } }) => {
      state.nodes = state.nodes.map((n) => (n.id === oldId ? { ...n, id: newId } : n));
    },

    updateNode: (state, { payload: { id, data = {}, ...restData } }) => {
      state.nodes.forEach((n, i) => {
        if (n.id === id) {
          state.nodes[i] = { ...n, data: { ...n.data, ...data }, ...restData };
        }
      });
    },

    changeMode: (state, { payload: mode }) => {
      state.mode = mode;
    },

    setConnectingEdge: (state, { payload }) => {
      state.connectingEdge = payload;
    },

    addEdge(state, { payload: edge }) {
      const isArray = Array.isArray(edge);

      if (isArray) {
        edge.forEach((e) => {
          if (state.edges.find((ed) => ed.id === e.id)) return;
          state.edges.push(e);
        });
      } else {
        if (state.edges.find((e) => e.id === edge.id)) return;
        state.edges.push(edge);
      }
    },

    updateEdge(state, { payload: edges }: { payload: Edge[] }) {
      state.edges.forEach((e, i) => {
        const edgeToUpdate = edges.find((edge) => edge.id === e.id);
        if (edgeToUpdate) {
          state.edges[i] = edgeToUpdate;
        }
      });
    },

    removeEdge(state, { payload: edgeId }) {
      state.edges = state.edges.filter((edge) => edge.id !== edgeId);
    },

    selectTool: (state, { payload: tool }) => {
      const currentTool = state.tool;
      if (currentTool === tool || tool === undefined) {
        state.tool = undefined;
        state.mode = ModeState.move;
      } else {
        state.tool = tool;
        state.mode = ModeState.insert;
      }
    },
    changeViewport: (state, { payload: viewport }) => {
      state.viewport = viewport;
    },
    clearStoryboard: () => {
      return initialState;
    },
  },
});

export const {
  onNodesChange,
  onEdgesChange,
  addEdge,
  setEdges,
  setNodes,
  addNode,
  changeNodeId,
  setConnectingEdge,
  removeNode,
  removeEdge,
  updateEdge,
  updateNode,
  changeMode,
  selectTool,
  changeViewport,
  clearStoryboard,
} = storyboardSlice.actions;

export default storyboardSlice.reducer;
