import { createSlice } from '@reduxjs/toolkit';
import type { IFloatingUser, SupportedSceneObjectTypes } from 'src/types';

type UserID = string;

type UserPresenceInfo = {
  id: UserID;
  cursor: {
    position: { x: number; y: number };
    timestamp: number;
  };
  selection: { id: string; type: SupportedSceneObjectTypes } | null;
  joinedAt: number;
  active: boolean;
  color: string;
};

const initialState = {
  enabled: false,
  storyboard: {} as Record<UserID, UserPresenceInfo>,
  floating: {} as Record<UserID, IFloatingUser>,
  following: null as UserID | null,
};

const collaborationSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateOnlineUser(state, { payload: { userId, data } }) {
      const onlineUser = state.storyboard[userId] || {};

      state.storyboard[userId] = { ...onlineUser, ...data };
    },
    setOnlineUsers(state, { payload: users }) {
      state.storyboard = { ...state.storyboard, ...users };
      const enabled = Object.keys(state.storyboard).length > 1;
      state.enabled = enabled;
    },
    removeOnlineUsers(state, { payload: users }) {
      users.forEach((user: string) => {
        delete state.storyboard[user];
      });

      const enabled = Object.keys(state.storyboard).length > 1;
      state.enabled = enabled;
    },
    enableCollaboration(state, { payload }) {
      state.enabled = payload;
    },
    updateFloatingUser(state, { payload: { userId, data } }) {
      state.floating[userId] = { ...state.floating[userId], ...data };
    },
    followUser(state, { payload }) {
      state.following = payload;
    },
    clearCollaboration(state) {
      const following = state.following;

      if (following && state.floating[following]) {
        return {
          ...initialState,
          following: state.following,
        };
      }
      return initialState;
    },
  },
});

export const {
  updateOnlineUser,
  clearCollaboration,
  setOnlineUsers,
  removeOnlineUsers,
  enableCollaboration,
  followUser,
  updateFloatingUser,
} = collaborationSlice.actions;

export default collaborationSlice.reducer;
