import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

import { IS_DEV } from 'src/utils/constants';

const MIXPANEL_TOKEN = String(process.env.REACT_APP_MIXPANEL_TOKEN);
const POSTHOG_KEY = String(process.env.REACT_APP_PUBLIC_POSTHOG_KEY);
const POSTHOG_HOST = String(process.env.REACT_APP_PUBLIC_POSTHOG_HOST);

const initAnalytics = () => {
  if (IS_DEV) return;

  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
  });
  mixpanel.init(MIXPANEL_TOKEN, {
    track_pageview: true,
    ignore_dnt: true,
    persistence: 'localStorage',
  });
};

initAnalytics();

export const trackEvent = (event, properties) => {
  const prefixedProperties = Object.keys(properties ?? {}).reduce((acc, key) => {
    acc[`unproject_${key}`] = properties[key];
    return acc;
  }, {});

  if (IS_DEV) return;

  posthog.capture(event, prefixedProperties);
  mixpanel.track(event, properties);
};

export const identifyUser = (id, properties = {}) => {
  if (IS_DEV) return;

  posthog.identify(id, properties);
  mixpanel.identify(id);
};

export const resetUser = () => {
  if (IS_DEV) return;

  posthog.reset();
  mixpanel.reset();
};

export const trackPage = () => {
  if (IS_DEV) return;

  posthog.capture('$pageview');
  mixpanel.track_pageview();
};

export const setUserAlias = (id) => {
  if (IS_DEV) return;

  mixpanel.alias(id);
};

export const resetAnalytics = () => {
  if (IS_DEV) return;

  posthog.reset();
  mixpanel.reset();
};
