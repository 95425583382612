import { supabase } from 'src/SupabaseClient';
import { Tables } from 'src/types';
import { TABLES } from 'src/utils/constants';

export const addIntegrationAPI = async (
  auth: Omit<Tables<'integrations_auth'>, 'id' | 'created_at'>
) => {
  const { data, error } = await supabase.from(TABLES.integrations_auth).insert(auth).single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const deleteIntegrationAPI = async (id: string) => {
  await supabase.from(TABLES.integrations_auth).delete().eq('id', id);
};

export const getAllIntegrationsAPI = async (userId: string) => {
  const { data, error } = await supabase
    .from(TABLES.integrations_auth)
    .select('*')
    .eq('user_id', userId);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
