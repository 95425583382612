import { Grid, GridItem } from '@chakra-ui/react';
import AssetCard from './AssetCard';

const arr = new Array(4).fill(0);

const PromptGeneration = () => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(2, 1fr)" gap={4} mt={8} mb={4}>
      {arr.map((_, i) => (
        <GridItem
          h={200}
          key={i}
          bg="#232323"
          rounded="md"
          transition="background-color 0.6s"
          _hover={{ bg: '#2d2d2d', cursor: 'pointer' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AssetCard cardIndex={i} />
        </GridItem>
      ))}
    </Grid>
  );
};

export default PromptGeneration;
