import { Box } from '@chakra-ui/react';
import { EdgeLabelRenderer, useViewport } from 'reactflow';
import store from 'src/store/store';

function Cursors({ cursors }: { cursors: Array<any> }) {
  const viewport = useViewport();

  return (
    <EdgeLabelRenderer>
      {cursors
        .filter(({ cursor }) => cursor.position)
        .map(({ id, name, color, cursor }) => {
          const translate = `translate(${cursor.position.x}px, ${cursor.position.y}px)`;
          const scale = `scale(${1 / viewport.zoom})`;
          const user = name ?? store.getState().users.entities[id]?.fullname;

          return (
            <div className="cursor" style={{ transform: `${translate} ${scale}` }}>
              <svg height={28} key={id + color}>
                <g>
                  <path d={cursorPath} fill={color} fillRule="evenodd" clipRule="evenodd" />
                </g>
              </svg>
              {user && (
                <Box
                  mt={1}
                  borderRadius="full"
                  px={2}
                  py={1}
                  fontSize="xs"
                  backdropFilter="auto"
                  backdropBlur="10px"
                  lineHeight="140%"
                  w="fit-content"
                  color={color}
                  outline="none"
                  border={`1px solid ${color}`}
                >
                  {user}
                </Box>
              )}
            </div>
          );
        })}
    </EdgeLabelRenderer>
  );
}

const cursorPath = `
M2.717 2.22918L15.9831 15.8743C16.5994 16.5083 16.1503 17.5714 15.2661 17.5714H9.35976C8.59988 17.5714 7.86831 17.8598 7.3128 18.3783L2.68232 22.7C2.0431 23.2966 1 22.8434 1 21.969V2.92626C1 2.02855 2.09122 1.58553 2.717 2.22918Z
`;

export default Cursors;
