import { ToolType } from 'src/types';
import { KeyBindingMap, tinykeys } from 'tinykeys';
import { isOnEditableElement } from './inputs';

export enum ShortcutCategory {
  Navigation = 'Navigation',
  Editing = 'Editing',
  View = 'View',
  File = 'File',
  Help = 'Help',
}

export interface ShorcutDetails {
  title: string;
  keystroke: string | string[];
  type?: ShortcutType;
  category: ShortcutCategory;
  hidden?: boolean;
  style?: any;
  data?: any;
}

export enum ShortcutType {
  storyboard = 'storyboard',
  scene = 'scene',
}

export const SHORTCUTS = {
  addScene: {
    title: 'Scene',
    keystroke: 'S',
    type: ShortcutType.storyboard,
    category: ShortcutCategory.Editing,
    data: { type: ToolType.scene },
  },
  addComment: {
    title: 'Comments',
    keystroke: 'C',
    category: ShortcutCategory.Editing,
    type: ShortcutType.storyboard,
    data: { type: ToolType.comment },
  },
  addText: {
    title: 'Text',
    keystroke: 'T',
    type: ShortcutType.storyboard,
    category: ShortcutCategory.Editing,
    data: { type: ToolType.text },
  },
  toggleTool: {
    title: 'Toggle Tool',
    keystroke: 'Escape',
    type: ShortcutType.storyboard,
    category: ShortcutCategory.Editing,
    data: { type: undefined },
  },
  delete: {
    title: 'Delete',
    keystroke: ['Delete', 'Backspace'],
    type: ShortcutType.scene,
    hidden: true,
    category: ShortcutCategory.Editing,
  },
  undo: {
    title: 'Undo',
    keystroke: '$mod+Z',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  paste: {
    title: 'Paste',
    keystroke: '$mod+V',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  redo: {
    title: 'Redo',
    keystroke: '$mod+Shift+Z',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  pointerLock: {
    title: 'pointerLock Controls',
    keystroke: 'Space',
    type: ShortcutType.scene,
    category: ShortcutCategory.Navigation,
  },
  clear: {
    title: 'Clear',
    keystroke: 'Escape',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  copy: {
    title: 'Copy',
    keystroke: '$mod+C',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  group: {
    title: 'Group',
    keystroke: 'G',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  setAsBackground: {
    title: 'Set as Background',
    keystroke: 'B',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  duplicate: {
    title: 'Duplicate',
    keystroke: '$mod+D',
    type: ShortcutType.scene,
    hidden: true,
    category: ShortcutCategory.Editing,
  },
  pastOnSelection: {
    title: 'Paste on Selection',
    keystroke: '$mod+Shift+V',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  ungroup: {
    title: 'Ungroup',
    keystroke: 'U',
    type: ShortcutType.scene,
    category: ShortcutCategory.Editing,
  },
  enterViewport: {
    title: 'Enter Viewport',
    keystroke: 'Enter',
    type: ShortcutType.scene,
    category: ShortcutCategory.Navigation,
  },
  rename: {
    title: 'Rename',
    keystroke: 'R',
    type: ShortcutType.storyboard,
    hidden: true,
    category: ShortcutCategory.Editing,
  },
} as Record<string, ShorcutDetails>;

export type ShortcutKey = keyof typeof SHORTCUTS;

export const createShorcutKeybindings = (
  config: Partial<{
    [shortcut in ShortcutKey]: (event?: KeyboardEvent) => void;
  }>
) => {
  const keybindings = Object.keys(config).reduce((prev, current) => {
    const key = current as ShortcutKey;
    return {
      ...prev,
      ...createShortcut(SHORTCUTS[key].keystroke, config[key]),
    };
  }, {} as KeyBindingMap);

  return tinykeys(window, keybindings);
};

export const createShortcut = (
  shorcut: string | string[],
  callback: (event?: KeyboardEvent) => void = () => {},
  avoidEditableElements = true
) => {
  // * If shorcut is an array, we will listen to all the keys in the array and execute the callback

  function handleShorcut(event: KeyboardEvent) {
    // * Avoids triggering the shortcut when the user is typing in an input field
    if (avoidEditableElements && isOnEditableElement(event)) return;

    event.stopImmediatePropagation();
    event.preventDefault();

    callback(event);
  }

  if (Array.isArray(shorcut)) {
    return shorcut.reduce((prev, current) => {
      return {
        ...prev,
        [current]: handleShorcut,
      };
    }, {});
  } else {
    return {
      [shorcut]: handleShorcut,
    };
  }
};
