import { createSlice } from '@reduxjs/toolkit';
import { InstanceToolType } from 'src/types';
import { SupportedSceneObjectTypes } from 'src/types';

export type SharedMemory = {
  objectType: SupportedSceneObjectTypes;
  data: any;
  id?: string;
};

const initialState = {
  instances: [],
  current_sceneId: '1',
  background_sceneId: null as string | null,
  activeTool: undefined as InstanceToolType | undefined,
  sharedMemory: [] as SharedMemory[],
};

const InstanceSlice = createSlice({
  name: 'instance',
  initialState,
  reducers: {
    getInstances: (state: any, { payload: instances }) => {
      state.instances = instances;
    },
    setSceneId: (state: any, { payload }) => {
      state.current_sceneId = payload;
    },
    setSharedMemory: (state: any, { payload: sharedMemory }) => {
      state.sharedMemory = sharedMemory;
    },
    setActiveTool: (state: any, { payload: tool }) => {
      const currentTool = state.activeTool;
      if (currentTool === tool || tool === undefined) {
        state.activeTool = undefined;
      } else {
        state.activeTool = tool;
      }
    },
    setBackgroundScene: (state: any, { payload }) => {
      state.background_sceneId = payload;
    },
    clearInstances: () => {
      return initialState;
    },
  },
});

export const {
  getInstances,
  setSceneId,
  setSharedMemory,
  setActiveTool,
  setBackgroundScene,
  clearInstances,
} = InstanceSlice.actions;
export default InstanceSlice.reducer;
