import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import './AssetDrawer.css';
import { useEffect, useState } from 'react';

import { supabase } from 'src/SupabaseClient';
import store from 'src/store/store';
import Cards from './Cards';
import { copyFileInS3 } from 'src/utils/aws';
import { SupportedSceneObjectTypes, SceneObjectActionTypes } from 'src/types';
import { useSceneViewer } from './sceneViewer/hooks/useSceneViewer';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';

function AssetDrawer(props: any) {
  const [primitiveData, setPrimitiveData] = useState([]);
  const [handData, setHandData] = useState([]);
  const [aiGeneratedData, setAiGeneratedData] = useState([]);
  const getAssets = async () => {
    const { data, error } = await supabase.from('asset_library').select();

    const primitiveData: any = data?.filter((el) => el.category === 'primitives');
    const handData: any = data?.filter((el) => el.category === 'hand');
    const aiGeneratedData: any = data?.filter((el) => el.category === 'aigenerated3d');
    setHandData(handData);
    setPrimitiveData(primitiveData);
    setAiGeneratedData(aiGeneratedData);
  };

  useEffect(() => {
    getAssets();
  }, []);

  const { handleSceneObjectAction } = useSceneViewer();
  const { getS3AssetPath } = useAssetUpload();

  const clickedAsset = async (asset: any) => {
    // console.log(asset);
    onClose();
    // Copy the asset to project folder.
    const srcBucket = String(process.env.REACT_APP_ASSET_LIBRARY_S3_BUCKET);
    const srcKey = 'assets3DObjects/' + asset.category + '/' + asset.file;
    const projectId = store.getState().app.projectId;
    const scene_id = store.getState().instance.current_sceneId;

    const { path, key } = getS3AssetPath(asset.file);

    await copyFileInS3(srcBucket, srcKey, path);
    // console.log(response);

    handleSceneObjectAction(SceneObjectActionTypes.insert, [
      {
        id: null,
        type: SupportedSceneObjectTypes.asset,
        localProperties: {},
        backendProperties: {
          project_id: projectId,
          scene_id: scene_id,
          metadata: {
            file: key,
          },
        },
      },
    ]);
  };

  const { onClose, isOpen } = props;

  const onSelectIndex = (event: any) => {
    setSelectedIndex(event);
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <>
      <Modal size="4xl" isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent
          rounded="2xl"
          bg="rgba(61, 61, 61, 0.173) !important"
          backdropFilter="blur(10px)"
          maxH="60vh"
          h="60vh"
          overflow="hidden"
          w="100%"
        >
          <Flex p={3} paddingTop={10}>
            <Tabs
              onChange={onSelectIndex}
              isManual={true}
              display="flex"
              orientation="vertical"
              variant="unstyled"
              defaultIndex={selectedIndex}
              w="100%"
              h="100%"
            >
              <TabList className="tablist">
                <Tab
                  h={10}
                  w="full"
                  value={0}
                  className={
                    selectedIndex == 0
                      ? 'projects_page_button project_page_seclectedIndex'
                      : 'projects_page_button'
                  }
                  style={{ fontSize: '12px' }}
                  alignItems="center"
                >
                  <Flex>
                    <span>Primitive Assets</span>
                  </Flex>
                </Tab>
                <Tab
                  h={10}
                  w="full"
                  value={1}
                  className={
                    selectedIndex == 1
                      ? 'projects_page_button project_page_seclectedIndex'
                      : 'projects_page_button'
                  }
                  style={{ fontSize: '12px' }}
                  alignItems="center"
                >
                  <Flex>
                    <span>Hand</span>
                  </Flex>
                </Tab>
                <Tab
                  h={10}
                  w="full"
                  value={2}
                  className={
                    selectedIndex == 2
                      ? 'projects_page_button project_page_seclectedIndex'
                      : 'projects_page_button'
                  }
                  style={{ fontSize: '12px' }}
                  alignItems="center"
                >
                  <Flex>
                    <span>Generated 3D</span>
                  </Flex>
                </Tab>
              </TabList>
              <TabPanels ml={4} maxH="50vh" overflow="auto">
                <TabPanel>
                  <Cards clickedAsset={clickedAsset} data={primitiveData} />
                </TabPanel>
                <TabPanel>
                  <Cards clickedAsset={clickedAsset} data={handData} />
                </TabPanel>
                <TabPanel>
                  <Cards clickedAsset={clickedAsset} data={aiGeneratedData} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AssetDrawer;
