import { addItem, removeItem, resetCache } from 'src/store/reducers/cache';
import { useAppDispatch } from 'src/store/reducers/hook';
import store from 'src/store/store';

export const useCache = () => {
  const dispatch = useAppDispatch();

  const cacheItem = (key: string, value: any) => {
    dispatch(addItem({ key, value }));
  };

  const getCacheItem = (key: string) => {
    const result = store.getState().cache.items[key]?.value;
    return result;
  };

  const removeCacheItem = (key: string) => {
    dispatch(removeItem(key));
  };

  const clearCache = () => {
    dispatch(resetCache());
  };

  return {
    cacheItem,
    clearCache,
    getCacheItem,
    removeCacheItem,
  };
};
