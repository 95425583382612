import { useEffect } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import { bboxInfoInterface, gizmoInfoInterface, refTransformsInterface } from 'src/types';
import { getTransformationMatrix } from 'src/utils/helper';
import * as THREE from 'three';
import { getSceneObject, getWorldTransform } from '../helpers';
import { useSceneInteractions } from './useSceneInteractions';
import { useSceneViewer } from './useSceneViewer';

export const useSceneObjectSelection = () => {
  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);
  const { getSelectedObjects } = useSceneViewer();
  const { onBBoxUpdate, onGizmoUpdate, onRefTransformUpdate } = useSceneInteractions();

  useEffect(() => {
    if (selectedObjects.length === 0) {
      onGizmoUpdate({
        show: [false, false, false],
      } as Partial<gizmoInfoInterface>);
    } else if (selectedObjects.length === 1) {
      let sceneObject = getSceneObject(selectedObjects[0].type, selectedObjects[0].id);
      // assetObejct, viewportObeject, UIObject, or GroupObject
      if (sceneObject) {
        const worldTransform = getWorldTransform(sceneObject.id);
        onGizmoUpdate({
          position: worldTransform.position,
          rotation: [0.0, 0.0, 0.0],
          scale: [1.0, 1.0, 1.0],
        } as Partial<gizmoInfoInterface>);

        onBBoxUpdate({
          position: worldTransform.position,
          rotation: worldTransform.rotation,
          scale: worldTransform.scale,
          refBBox: sceneObject.localProperties.originalBBox,
        } as Partial<bboxInfoInterface>);

        onRefTransformUpdate({
          bboxRef: getTransformationMatrix(
            worldTransform.position,
            worldTransform.rotation,
            worldTransform.scale
          ),
        } as Partial<refTransformsInterface>);
      } else {
        // if (selectedObjects[0].type === SupportedSceneObjectTypes.interactions) {
        //   const id_and_subtype = selectedObjects[0].id.split('$');
        //   const viewportObject = getSceneObject(
        //     SupportedSceneObjectTypes.viewport,
        //     id_and_subtype[0]
        //   ) as ViewportObject;
        //   if (
        //     viewportObject &&
        //     viewportObject.backendProperties.metadata.controller_properties !== null
        //   ) {
        //     let pRelative =
        //       viewportObject.backendProperties.metadata.controller_properties.transform[
        //         id_and_subtype[1]
        //       ].position;
        //     let rRelative =
        //       viewportObject.backendProperties.metadata.controller_properties.transform[
        //         id_and_subtype[1]
        //       ].rotation;
        //     let sRelative =
        //       viewportObject.backendProperties.metadata.controller_properties.transform[
        //         id_and_subtype[1]
        //       ].scale;
        //     const finalPosition = new THREE.Vector3(pRelative[0], pRelative[1], pRelative[2]);
        //     const viewportMatrix = getTransformationMatrix(
        //       viewportObject.backendProperties.position,
        //       viewportObject.backendProperties.rotation,
        //       [1.0, 1.0, 1.0] as [number, number, number]
        //     ).clone();
        //     finalPosition.copy(finalPosition.applyMatrix4(viewportMatrix));
        //     let p = [finalPosition.x, finalPosition.y, finalPosition.z];
        //     let r = rRelative;
        //     let s = sRelative;
        //     onGizmoUpdate({
        //       position: p,
        //       rotation: [0.0, 0.0, 0.0],
        //       scale: [1, 1, 1],
        //     } as Partial<gizmoInfoInterface>);
        //     const controllerBBox = getControllerBBox(
        //       viewportObject.backendProperties.metadata.controller_properties,
        //       id_and_subtype[1] as any
        //     );
        //     if (controllerBBox === undefined) return;
        //     const bbox = new THREE.Box3(
        //       new THREE.Vector3(
        //         controllerBBox.min[0],
        //         controllerBBox.min[1],
        //         controllerBBox.min[2]
        //       ),
        //       new THREE.Vector3(controllerBBox.max[0], controllerBBox.max[1], controllerBBox.max[2])
        //     );
        //     onBBoxUpdate({
        //       position: p,
        //       rotation: r,
        //       scale: s,
        //       refBBox: bbox,
        //     } as Partial<bboxInfoInterface>);
        //     onRefTransformUpdate({
        //       bboxRef: getTransformationMatrix(
        //         p as [number, number, number],
        //         r as [number, number, number],
        //         s as [number, number, number]
        //       ),
        //     } as Partial<refTransformsInterface>);
        //   }
        // }
      }
    }
    // More than one object is selected_
    else if (selectedObjects.length > 1) {
      const bbox = unionSelectedObjectsBBox();
      const bboxCenter = new THREE.Vector3();
      bbox.getCenter(bboxCenter);
      bbox.translate(new THREE.Vector3(-bboxCenter.x, -bboxCenter.y, -bboxCenter.z));

      onGizmoUpdate({
        position: [bboxCenter.x, bboxCenter.y, bboxCenter.z],
        rotation: [0.0, 0.0, 0.0],
        scale: [1, 1, 1],
      } as Partial<gizmoInfoInterface>);

      onBBoxUpdate({
        position: [bboxCenter.x, bboxCenter.y, bboxCenter.z],
        rotation: [0, 0, 0],
        scale: [1, 1, 1],
        refBBox: bbox,
      } as Partial<bboxInfoInterface>);

      onRefTransformUpdate({
        bboxRef: getTransformationMatrix(
          [bboxCenter.x, bboxCenter.y, bboxCenter.z],
          [0, 0, 0],
          [1, 1, 1]
        ),
      } as Partial<refTransformsInterface>);
    }
  }, [selectedObjects]);

  const unionSelectedObjectsBBox = () => {
    let bbox = undefined as any;
    const selectedObjects = getSelectedObjects();
    selectedObjects.forEach((selectedObject) => {
      let objectPosition = [0, 0, 0];
      let objectRotation = [0, 0, 0];
      let objectScale = [1, 1, 1];
      let objectBBox = undefined;

      let sceneObject = getSceneObject(selectedObject.type, selectedObject.id);
      if (sceneObject) {
        const worldTransform = getWorldTransform(selectedObject.id);
        objectPosition = worldTransform.position; // sceneObject.backendProperties.position;
        objectRotation = worldTransform.rotation; // sceneObject.backendProperties.rotation;
        objectBBox = sceneObject.localProperties.originalBBox?.clone();
        objectScale = worldTransform.scale;
        // if (sceneObject.type !== SupportedSceneObjectTypes.viewport) {
        //   objectScale = sceneObject.backendProperties.scale;
        // }
      } else {
        // if (selectedObject.type === SupportedSceneObjectTypes.interactions) {
        //   const id_and_subtype = selectedObject.id.split('$');
        //   const viewportObject = getSceneObject(
        //     SupportedSceneObjectTypes.viewport,
        //     id_and_subtype[0]
        //   ) as ViewportObject;
        //   if (
        //     viewportObject &&
        //     viewportObject.backendProperties.metadata.controller_properties !== null
        //   ) {
        //     const controllerBBox = getControllerBBox(
        //       viewportObject.backendProperties.metadata.controller_properties,
        //       id_and_subtype[1] as any
        //     );
        //     if (controllerBBox === undefined) return;
        // const bbox = new THREE.Box3(
        //   new THREE.Vector3(
        //     controllerBBox.min[0],
        //     controllerBBox.min[1],
        //     controllerBBox.min[2]
        //   ),
        //   new THREE.Vector3(controllerBBox.max[0], controllerBBox.max[1], controllerBBox.max[2])
        // );
        //     let pRelative =
        //       viewportObject.backendProperties.metadata.controller_properties.transform[
        //         id_and_subtype[1]
        //       ].position;
        //     let rRelative =
        //       viewportObject.backendProperties.metadata.controller_properties.transform[
        //         id_and_subtype[1]
        //       ].rotation;
        //     let sRelative =
        //       viewportObject.backendProperties.metadata.controller_properties.transform[
        //         id_and_subtype[1]
        //       ].scale;
        //     const finalPosition = new THREE.Vector3(pRelative[0], pRelative[1], pRelative[2]);
        //     const viewportMatrix = getTransformationMatrix(
        //       viewportObject.backendProperties.position,
        //       viewportObject.backendProperties.rotation,
        //       [1.0, 1.0, 1.0] as [number, number, number]
        //     ).clone();
        //     finalPosition.copy(finalPosition.applyMatrix4(viewportMatrix));
        //     let p = [finalPosition.x, finalPosition.y, finalPosition.z];
        //     let r = rRelative;
        //     let s = sRelative;
        //     objectPosition = p;
        //     objectRotation = r;
        //     objectScale = s;
        //     objectBBox = bbox;
        //   }
        // }
      }

      if (objectBBox) {
        objectBBox.applyMatrix4(
          new THREE.Matrix4().compose(
            new THREE.Vector3(objectPosition[0], objectPosition[1], objectPosition[2]),
            new THREE.Quaternion().setFromEuler(
              new THREE.Euler(objectRotation[0], objectRotation[1], objectRotation[2])
            ),
            new THREE.Vector3(objectScale[0], objectScale[1], objectScale[2])
          )
        );
      }

      if (bbox === undefined) {
        bbox = objectBBox;
      } else {
        bbox.union(objectBBox);
      }
    });

    if (bbox === undefined) {
      bbox = new THREE.Box3();
    }
    return bbox;
  };
};
