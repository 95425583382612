import { initWasm, Resvg } from '@resvg/resvg-wasm';

export const initWasmHandler = async () => {
  try {
    await initWasm('https://unpkg.com/@resvg/resvg-wasm/index_bg.wasm');
  } catch (err) {
    console.error(err);
  }
};

export const svgToPng = async (svg: Uint8Array): Promise<Blob> => {
  const resvgJS = new Resvg(svg);
  const pngData = resvgJS.render();
  const pngBuffer = pngData.asPng();

  return new Blob([pngBuffer], { type: 'image/png' });
};
