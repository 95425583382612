import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { modalTheme } from './components/modal';

const dashy = defineStyle({
  border: 0,
});

export const kbdTheme = defineStyleConfig({
  variants: { dashy },
});

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  components: {
    Kbd: kbdTheme,
    Menu: {
      baseStyle: {
        list: {
          border: 'none',
          bg: 'rgba(28, 28, 28, 1)',
        },
        item: {
          color: 'gray.200',
          fontSize: 'md',
          _hover: {
            bg: 'rgba(51, 51, 51, 0.531)',
          },
          bg: 'rgba(28, 28, 28, 1)',
        },
      },
    },
    Modal: modalTheme,
  },
});
