import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from '../store';
import { clearComments } from './comments';
import { resetHistory } from './history';
import { clearInstances } from './InstanceReducer';
import { clearStoryboard } from './storyboard';
import { clearUsers } from './users';
import { clearCollaboration } from './collaboration';
import store from '../store';
import { clearIntegrations } from './integrations';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppStore = () => {
  const dispatch = useAppDispatch();

  const clearStores = () => {
    dispatch(clearStoryboard());
    dispatch(clearComments());
    dispatch(resetHistory());
    dispatch(clearInstances());
    dispatch(clearUsers());
    dispatch(clearCollaboration());
    dispatch(clearIntegrations());
  };

  const getCurrentUser = () => {
    return store.getState().app.currentUser;
  };

  return {
    clearStores,
    getCurrentUser,
  };
};
