import { Line } from '@react-three/drei';
import * as THREE from 'three';

const BoundingBox = (props: { bbox: THREE.Box3; color: THREE.ColorRepresentation }) => {
  const min = props.bbox.min;
  const max = props.bbox.max;

  const width = max.x - min.x;
  const height = max.y - min.y;
  const depth = max.z - min.z;

  const position = new THREE.Vector3(min.x + width / 2, min.y + height / 2, min.z + depth / 2);

  const vertices = [
    [min.x, min.y, min.z],
    [min.x, min.y, max.z],
    [min.x, max.y, min.z],
    [min.x, max.y, max.z],
    [max.x, min.y, min.z],
    [max.x, min.y, max.z],
    [max.x, max.y, min.z],
    [max.x, max.y, max.z],
  ];

  const edges = [
    [0, 1],
    [0, 2],
    [0, 4],
    [1, 3],
    [1, 5],
    [2, 3],
    [2, 6],
    [3, 7],
    [4, 5],
    [4, 6],
    [5, 7],
    [6, 7],
  ];

  let lineGeometries = [];
  for (let l = 0; l < 12; l++) {
    lineGeometries.push([
      new THREE.Vector3(...vertices[edges[l][0]]),
      new THREE.Vector3(...vertices[edges[l][1]]),
    ]);
  }

  return (
    <group>
      <mesh position={position}>
        <boxGeometry args={[width, height, depth]} />
        <meshBasicMaterial wireframe={true} transparent opacity={0.0} />
      </mesh>
      {lineGeometries.map((item, i) => {
        return <Line key={i} points={item} color={props.color} />;
      })}
    </group>
  );
};

export default BoundingBox;
