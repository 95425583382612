import { useState } from 'react';
import './Annotation.css';
export default function AnnotationContainer({ data, index }: { data: string; index: number }) {
  const [textVisible, setTextVisible] = useState(false);
  return (
    <>
      <div className="annotationCont">
        <div onClick={() => setTextVisible(!textVisible)} className="annotation">
          {index}
        </div>
        {textVisible && <div className="annotationText">{data}</div>}
      </div>
    </>
  );
}
