import { Flex, Skeleton, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store/reducers/hook';
import { openModal } from 'src/store/reducers/modals';
import store from 'src/store/store';
import { ModalType } from 'src/types';
import { getRandomNumber } from 'src/utils/helper';
import { exampleJSON } from 'src/utils/sceneAI';

function AssetCard(props: { cardIndex: number }) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  let imageURL = '';
  const promptData = store.getState().modal.active[ModalType.sceneAI]?.data;
  const options = Object.keys((exampleJSON as any)[promptData.prompt]);
  imageURL = (exampleJSON as any)[promptData.prompt][options[props.cardIndex]]['thumbnail'];

  useEffect(() => {
    setIsLoading(true);
    setTimeout(
      () => {
        setIsLoading(false);
      },
      1000 * getRandomNumber(3, 7)
    );
  }, []);

  const handlePreview = () => {
    const promptData = store.getState().modal.active[ModalType.sceneAI];
    dispatch(
      openModal({
        type: ModalType.assetPreview,
        data: {
          prompt: promptData?.data?.prompt,
          optionIndex: props.cardIndex,
        },
      })
    );
  };

  return (
    <Skeleton
      startColor="#222"
      endColor="#333"
      h={200}
      w="full"
      fadeDuration={1}
      isLoaded={!isLoading}
    >
      <Flex
        align="center"
        justify="center"
        h="full"
        w="full"
        bg="#232323"
        rounded="md"
        onClick={handlePreview}
      >
        <Image h={200} w="full" src={imageURL} />
      </Flex>
    </Skeleton>
  );
}

export default AssetCard;
