import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Button,
  Input,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaFigma } from 'react-icons/fa6';
import { getFigmaAssetImage, getFigmaAuthCodeUrl } from 'src/services/figma';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { addIntegrationAuth, setIntegrations } from 'src/store/reducers/integrations';
import { getShortId } from 'src/utils/ids';
import { z } from 'zod';
import store from 'src/store/store';
import { ModalType, SceneObjectActionTypes } from 'src/types';
import { getAllIntegrationsAPI } from 'src/apis/integrations';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { closeModal, openModal } from 'src/store/reducers/modals';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import timeHelper from 'src/utils/time';

const FigmaFileImportScheme = z.object({
  url: z.string().url(),
});

type FigmaFileImportSchemeType = z.infer<typeof FigmaFileImportScheme>;

const FigmaImporter = (props: any) => {
  const { isImporting, setIsImporting } = props;
  const dispatch = useAppDispatch();
  const { uploadImageFromUrl } = useAssetUpload();

  const { register, handleSubmit } = useForm<FigmaFileImportSchemeType>({
    resolver: zodResolver(FigmaFileImportScheme),
  });

  const handleImport = async (data: any) => {
    const figmaAuth = store.getState().integrations.auth['figma']?.auth_data?.access_token;

    if (!figmaAuth) return;

    setIsImporting(true);
    const assetData = await getFigmaAssetImage(figmaAuth, data.url);

    if (!assetData) return;

    try {
      const imageUrl = Object.values(assetData.images).at(0) as string;
      const sceneId = store.getState().instance.current_sceneId;
      if (!imageUrl || !sceneId) return;

      await uploadImageFromUrl(imageUrl, {
        operation: {
          type: SceneObjectActionTypes.insert,
          data: {
            backendProperties: {
              scene_id: sceneId,
              metadata: {
                meta: {
                  type: 'figma',
                  node_url: data.url,
                  lastUpdated: timeHelper().utc().format(),
                },
              },
            },
          },
        },
        showToast: false,
      });

      setIsImporting(false);
      dispatch(closeModal(ModalType.figmaImport));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {' '}
      <Input
        placeholder="Paste Figma URL"
        variant="unstyled"
        fontSize={16}
        size="sm"
        autoFocus
        bgColor="none"
        colorScheme="blue"
        color="white"
        _placeholder={{ color: 'gray' }}
        {...register('url')}
      />
      <Button size="sm" isLoading={isImporting} onClick={handleSubmit(handleImport)}>
        Import
      </Button>
    </>
  );
};

const FigmaImport = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const figmaAuth = useAppSelector((store) => store.integrations.auth['figma']);
  const modal = useAppSelector((store) => store.modal.active[ModalType.figmaImport]);

  const onClose = () => {
    if (importing) return;
    dispatch(closeModal(ModalType.figmaImport));
  };

  const handleConnect = () => {
    const sessionId = getShortId();
    const url = getFigmaAuthCodeUrl(sessionId);
    window.open(url, '__blank');
    setIsLoading(true);
  };

  useEffect(() => {
    const userId = store.getState().app.currentUser?.id;
    if (userId)
      getAllIntegrationsAPI(userId)
        .then((res) => dispatch(setIntegrations(res)))
        .catch((err) => {
          console.error(err);
        });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const checkAuthState = localStorage.getItem('unproject-figma');

      if (checkAuthState && isLoading) {
        setIsLoading(false);
        dispatch(addIntegrationAuth(JSON.parse(checkAuthState)));
        localStorage.removeItem('unproject-figma');
      }
    }, 500);

    return () => clearInterval(interval);
  }, [isLoading]);

  const openFigmaImportSettings = () => {
    dispatch(
      openModal({
        type: ModalType.figmaImportSettings,
        data: {},
      })
    );
  };

  if (!modal) return <></>;

  return (
    <Modal onClose={onClose} isOpen={!!modal}>
      <ModalOverlay bg="transparent" />
      <ModalContent rounded="2xl" bg="rgba(18, 17, 23, 0.6) !important" backdropFilter="blur(10px)">
        <ModalBody p={4}>
          <Flex alignItems="center" flexDirection="row" gap={4}>
            <FaFigma size={32} color="gray" />
            {!figmaAuth ? (
              <>
                <Text fontSize="md" color="gray" m={0} flex={1}>
                  Import from Figma
                </Text>
                <Button size="sm" isLoading={isLoading} onClick={handleConnect}>
                  Connect
                </Button>
              </>
            ) : (
              <>
                <FigmaImporter isImporting={importing} setIsImporting={setImporting} />
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    aria-label="Settings"
                    icon={<BsThreeDotsVertical size={20} />}
                  />
                  <MenuList>
                    <MenuItem onClick={openFigmaImportSettings}>Settings</MenuItem>
                  </MenuList>
                </Menu>
              </>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FigmaImport;
