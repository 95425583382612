import { Login, SignUp } from './Pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Editor from './Pages/Editor';
import { ROUTES } from './utils/constants';
import { Authenticated, Unauthenticated } from './layouts/Page';
import { useInit } from './hooks';
import Integrations from './Pages/Integrations';
import RightClickMenu from './components/rightClickMenu/RightClickMenu';

const App = () => {
  useInit();

  return (
    <div className="App" onContextMenu={(e) => e.preventDefault()}>
      <ToastContainer position="bottom-center" />
      <RightClickMenu />
      <Routes>
        <Route path="/" element={<Unauthenticated />}>
          <Route path={ROUTES.register} element={<SignUp />} />
          <Route path={ROUTES.login} element={<Login />} />
        </Route>
        <Route element={<Authenticated />}>
          <Route path={ROUTES.projects} element={<Projects />} />
          <Route path={ROUTES.editor} element={<Editor />} />
          <Route path={ROUTES.home} element={<Home />} />
          <Route path={ROUTES.integrations} element={<Integrations />} />
        </Route>
        <Route path="*" element={<Navigate to={ROUTES.login} />} />
      </Routes>
    </div>
  );
};

export default App;
