import { createSlice } from '@reduxjs/toolkit';
import type { User } from 'src/types';
import { getCurrentUser } from 'src/utils/requests';

type AppType = {
  isAuthenticated: boolean;
  currentUser: User | undefined;
  isOnline: boolean;
  projectId: string;
  project: {
    id: string;
    name: string;
  };
  lastLoggedIn: string | undefined;
};

const initialState = {
  isAuthenticated: false,
  projectId: '',
} as AppType;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setProjectId(state, { payload: projectId }) {
      state.projectId = projectId;
    },
    setProject(state, { payload: project }) {
      state.project = project;
      state.projectId = project.id;
    },
    setCurrentUser(state, { payload: currentUser }) {
      if (!currentUser) {
        state.isAuthenticated = false;
        state.projectId = '';
        state.lastLoggedIn = state.currentUser?.id;
        state.currentUser = undefined;
      } else {
        state.isAuthenticated = true;
        state.currentUser = getCurrentUser(currentUser) as User;
      }
    },
    setIsOnline(state, { payload: isOnline }) {
      state.isOnline = isOnline;
    },
  },
});

export const { setProjectId, setProject, setCurrentUser, setIsOnline } = appSlice.actions;

export default appSlice.reducer;
