import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { context, ContextType } from 'src/components/sceneViewer/context';
import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { radToDeg, degToRad, applyProportionalScaling } from 'src/utils/helper';
import { ButtonGroup, Flex, Select, Switch } from '@chakra-ui/react';
import * as THREE from 'three';
import { Button } from '@chakra-ui/react';
import {
  AssetObject,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
  gizmoInfoInterface,
} from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { useSceneInteractions } from '../hooks/useSceneInteractions';
import { getWorldTransform, toLocalTransform } from '../helpers';

export default function AssetProperties(props: { id: string }) {
  // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
  const scene_scale = 1.0;
  const { setSnapToGround } = useContext<ContextType>(context);

  const { handleSceneObjectAction } = useSceneViewer();
  const { onGizmoUpdate } = useSceneInteractions();
  const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);
  const assetSceneObject = useAppSelector((store) =>
    store.sceneViewer.sceneObjectList.find(
      (item) => item.type === SupportedSceneObjectTypes.asset && item.id === props.id
    )
  ) as AssetObject;

  const [animationState, setAnimationState] = useState<any[]>(
    assetSceneObject.localProperties.animationState as any[]
  );
  const [annotationShow, setAnnotationShow] = useState(false);
  const [selectedAnimation, setSelectedAnimation] = useState(0);
  const handleAnnotation = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: assetSceneObject.id,
          type: assetSceneObject.type,
          localProperties: {
            annotationShow: true,
          },
          backendProperties: assetSceneObject.backendProperties,
        },
      ]);
    } else {
      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: assetSceneObject.id,
          type: assetSceneObject.type,
          localProperties: {
            annotationShow: false,
          },
          backendProperties: assetSceneObject.backendProperties,
        },
      ]);
    }
  };
  const handleAssetPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const objectCopy = structuredClone(assetSceneObject.backendProperties);

    const worldTransform = getWorldTransform(objectCopy.id);
    if (field === 'position') {
      const newPosition = [...objectCopy.position];
      newPosition[propIndex] = parseFloat(event.target.value) / scene_scale;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: newPosition as [number, number, number],
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
      });

      // objectCopy.position = newPosition as any;
      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'rotation') {
      const newRotation = [...objectCopy.rotation];
      newRotation[propIndex] = degToRad(parseFloat(event.target.value));
      // objectCopy.rotation = newRotation as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: newRotation as [number, number, number],
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'scale') {
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value);

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.rotation as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'bbox') {
      const size = new THREE.Vector3();
      assetSceneObject.localProperties.originalBBox?.getSize(size);
      const oldDim = propIndex === 0 ? size.x : propIndex === 1 ? size.y : size.z;
      let newScale = [...objectCopy.scale];
      newScale[propIndex] = parseFloat(event.target.value) / scene_scale / oldDim;

      if (objectCopy.constrain_proportions) {
        newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
      }

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: worldTransform.scale as [number, number, number],
        scale: newScale as [number, number, number],
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    }

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {},
        backendProperties: {
          ...objectCopy,
        },
      },
    ]);
  };

  const handleConstrainProportionsChange = () => {
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {},
        backendProperties: {
          constrain_proportions: !assetSceneObject.backendProperties.constrain_proportions,
        },
      },
    ]);
  };

  const handleSnapToGPlane = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSnapToGround(true);
    } else {
      // setGizmoInfo({
      //   ...gizmoInfo,
      //   show: [true, true, true]
      // })
      onGizmoUpdate({
        show: [true, true, true],
      } as Partial<gizmoInfoInterface>);
    }
  };

  const handleSelectAnimation = (event: ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value;

    let newanimationState: any[] = [];
    animationState.map((state, index) => {
      if (index === parseInt(value)) {
        newanimationState.push({
          ...state,
          isPlaying: !state.isPlaying,
        });
      } else if (index === selectedAnimation) {
        newanimationState.push({
          ...state,
          isPlaying: false,
        });
      } else {
        newanimationState.push(state);
      }
    });
    setSelectedAnimation(parseInt(value));
    setAnimationState(newanimationState);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {
          animationState: newanimationState,
        },
        backendProperties: assetSceneObject.backendProperties,
      },
    ]);
  };

  const handlePlayPause = () => {
    let newanimationState: any[] = [];
    animationState.map((state, index) => {
      if (index === selectedAnimation) {
        newanimationState.push({
          ...state,
          isPlaying: !state.isPlaying,
        });
      } else {
        newanimationState.push(state);
      }
    });
    setAnimationState(newanimationState);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: assetSceneObject.id,
        type: assetSceneObject.type,
        localProperties: {
          animationState: newanimationState,
        },
        backendProperties: assetSceneObject.backendProperties,
      },
    ]);
  };

  useEffect(() => {
    const currentAnimationState = assetSceneObject.localProperties.animationState;
    setAnimationState(currentAnimationState as any[]);

    if (currentAnimationState !== undefined && currentAnimationState?.length > 0) {
      currentAnimationState?.forEach((state, index) => {
        if (state.isPlaying) {
          setSelectedAnimation(index);
          return;
        }
      });
    }
    const annotationslength = assetSceneObject.localProperties.annotationslength;
    if (annotationslength !== undefined) {
      if (annotationslength > 0) {
        setAnnotationShow(true);
      }
    }
  }, [assetSceneObject.id]);

  let assetProperties = null;

  if (assetSceneObject) {
    const worldTransform = getWorldTransform(assetSceneObject.id);

    let boundingBoxProperties = null;
    if (assetSceneObject.localProperties.originalBBox !== undefined) {
      const size = new THREE.Vector3();
      assetSceneObject.localProperties.originalBBox.getSize(size);
      const width = size.x * worldTransform.scale[0] * scene_scale;
      const height = size.y * worldTransform.scale[1] * scene_scale;
      const depth = size.z * worldTransform.scale[2] * scene_scale;
      boundingBoxProperties = (
        <>
          <br />
          <PropertyHeading>Bounding Box</PropertyHeading>
          <label className="label">Dimension</label>
          <PropertyField
            value={(width * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={0}
            handlePropertyChange={handleAssetPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[0]}
          />
          <PropertyField
            value={(height * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={1}
            handlePropertyChange={handleAssetPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[1]}
          />
          <PropertyField
            value={(depth * scene_scale).toFixed(2)}
            field="bbox"
            instanceIndex={-1}
            propIndex={2}
            handlePropertyChange={handleAssetPropertyChange}
            type="number"
            disabled={!gizmoInfo.show[2]}
          />
        </>
      );
    }

    assetProperties = (
      <div className="assetProperties">
        <PropertyHeading>Transform</PropertyHeading>
        <label className="label">Position</label>
        <PropertyField
          value={(worldTransform.position[0] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={(worldTransform.position[1] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={(worldTransform.position[2] * scene_scale).toFixed(2)}
          field="position"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        <br />
        <label className="label">Rotation</label>
        <PropertyField
          value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[1] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[2])}
        />
        <PropertyField
          value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
          field="rotation"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!(gizmoInfo.show[0] && gizmoInfo.show[1])}
        />
        <br />
        <label className="label_scale">Scale</label>
        {/* <Tooltip label="Constrain Proportions" zIndex={1001} bg="grey">
          <Button
            className={`prop-btn ${
              assetSceneObject.backendProperties.constrain_proportions ? 'prop-btn-active' : ''
            }`}
            size="sm"
            onClick={handleConstrainProportionsChange}
          >
            <Icon
              color="white"
              as={
                assetSceneObject.backendProperties.constrain_proportions
                  ? BiCollapseVertical
                  : BiExpandVertical
              }
            ></Icon>
          </Button>
        </Tooltip> */}
        <PropertyField
          value={worldTransform.scale[0].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={0}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[0]}
        />
        <PropertyField
          value={worldTransform.scale[1].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={1}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[1]}
        />
        <PropertyField
          value={worldTransform.scale[2].toFixed(2)}
          field="scale"
          instanceIndex={-1}
          propIndex={2}
          handlePropertyChange={handleAssetPropertyChange}
          type="number"
          disabled={!gizmoInfo.show[2]}
        />

        {boundingBoxProperties}

        <br />
        <PropertyHeading>Snapping</PropertyHeading>
        <label className="label">Snap To Ground Plane</label>
        <Switch onChange={(event) => handleSnapToGPlane(event)} />
        {animationState.length > 0 && <PropertyHeading>Animations</PropertyHeading>}

        {animationState.length > 0 && (
          <Flex minWidth="auto" p={'2'} alignItems="center" gap="2">
            <Select
              value={selectedAnimation}
              onChange={handleSelectAnimation}
              variant="unstyled"
              height={'30px'}
            >
              {animationState?.map((state, index) => (
                <option key={state.name} value={index}>
                  {state.name}
                </option>
              ))}
            </Select>
            <ButtonGroup gap="2">
              <Button onClick={handlePlayPause} size="xs">
                {animationState[selectedAnimation].isPlaying ? 'Pause' : 'Play'}
              </Button>
            </ButtonGroup>
          </Flex>
        )}
        {annotationShow && (
          <>
            <PropertyHeading>Annotations</PropertyHeading>
            <label className="label">Show Annotations</label>
            <Switch
              defaultChecked={assetSceneObject.localProperties.annotationShow}
              onChange={(event) => handleAnnotation(event)}
            />
          </>
        )}
      </div>
    );
  }

  return <>{assetProperties}</>;
}
