import { useMemo } from 'react';
import { Avatars } from 'src/components/Avatar';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';

type CollaboratorsProps = { onClick?: (userId: string) => void };

const Collaborators = ({ onClick }: CollaboratorsProps) => {
  const users = useAppSelector((store) => store.collaboration.storyboard);
  const usersArray = useMemo(() => {
    const userIds = Object.keys(users ?? {});
    return userIds.map((id) => store.getState().users.entities[id]);
  }, [users]);

  if (usersArray.length === 1) return null;

  return <Avatars users={usersArray} onClick={onClick} />;
};

export default Collaborators;
