import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { getProjectMembers, inviteUser } from 'src/apis/users';
import store from 'src/store/store';
import InviteList from './InviteList';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { Invitee, ProjectMember } from 'src/types';
import RoleMenu from './RoleMenu';
import { useAppDispatch } from 'src/store/reducers/hook';
import { getInvitedUsers } from 'src/apis';
import { setUsers } from 'src/store/reducers/users';
import { runBatch } from 'src/utils/requests';
import { trackEvent } from 'src/services/analytics';
import { EVENTS, PROPERTIES } from 'src/services/analytics/events';

const InviteSchema = z.object({
  email: z.string().email({
    message: 'Must be a valid email',
  }),
  role: z.string().optional(),
});

type InviteSchemaType = z.infer<typeof InviteSchema>;

const InviteModal = ({ isOpen, onClose }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [projectMembers, setProjectMembers] = useState<ProjectMember[]>([]);
  const [invitees, setInvitees] = useState<Invitee[]>([]);

  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<InviteSchemaType>({ resolver: zodResolver(InviteSchema) });

  const finalRef = useRef(null);
  const dispatch = useAppDispatch();
  const initialRef = useRef<HTMLInputElement>(null);

  const handleInvite = async ({ email, role = 'edit' }: any) => {
    const projectId = store.getState().app.projectId;
    const currentUserEmail = store.getState().app.currentUser?.email;

    try {
      setIsLoading(true);
      await inviteUser({
        project_id: projectId,
        email_id: email,
        role,
      });

      trackEvent(EVENTS.INVITE_USER, {
        [PROPERTIES.INVITEE]: email,
        [PROPERTIES.INVITER]: currentUserEmail,
      });

      setInvitees((prev) => [...prev, { email, username: email, fullname: '', role }]);
      setIsLoading(false);
      setValue('email', '');
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const projectId = store.getState().app.projectId;
    runBatch([
      getProjectMembers(projectId).then(async (res) => {
        if (res.data) {
          setProjectMembers(res.data);
          dispatch(setUsers(res.data));
        }
      }),

      getInvitedUsers(projectId).then(async (res: any) => {
        if (!res?.error) setInvitees(res);
      }),
    ]);
  }, []);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share your project</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form onSubmit={handleSubmit(handleInvite)}>
            <FormControl isInvalid={!!errors?.email?.message}>
              <InputGroup size="lg">
                <Input
                  key={isOpen}
                  autoFocus
                  {...register('email')}
                  placeholder="Enter email id"
                  pr="9.5rem"
                />
                <InputRightElement width="9rem">
                  <Flex gap={0} alignItems="center">
                    <RoleMenu
                      h="3rem"
                      pr={1}
                      size="md"
                      onChangeRole={(newRole) => setValue('role', newRole)}
                    />
                    <Button
                      h="3rem"
                      size="md"
                      borderLeftRadius={0}
                      borderRightRadius={5}
                      type="submit"
                      isLoading={isLoading}
                      colorScheme={!isLoading ? 'blue' : ''}
                    >
                      Invite
                    </Button>
                  </Flex>
                </InputRightElement>
              </InputGroup>
              {errors?.email?.message && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
          </form>
          <Stack mt={8} gap={1}>
            <Text fontSize="medium" fontWeight="semibold">
              Members
            </Text>
            <InviteList
              invitees={invitees}
              projectMembers={projectMembers}
              setProjectMembers={setProjectMembers}
              setInvitees={setInvitees}
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InviteModal;
