import { supabase } from 'src/SupabaseClient';
import { Comment, CommentsType } from 'src/types';
import { TABLES } from 'src/utils/constants';

const getTableFromType = (type: CommentsType) => {
  switch (type) {
    case CommentsType.scene:
      return TABLES.scene_comments;
    case CommentsType.storyboard:
    default:
      return TABLES.comments;
  }
};

export const getAllComments = async (projectId: string) => {
  const { data, error } = await supabase
    .from(TABLES.comments)
    .select('*')
    .eq('project_id', projectId);

  if (error) {
    throw error;
  }

  return data;
};

export const getSceneComments = async (projectId: string, sceneId: string) => {
  const { data, error } = await supabase
    .from(TABLES.scene_comments)
    .select('*')
    .eq('project_id', projectId)
    .eq('scene_id', sceneId);

  if (error) {
    throw error;
  }

  return data;
};

export const addCommentAPI = async (
  commentData: Partial<Comment>,
  type: CommentsType = CommentsType.storyboard
) => {
  const table = getTableFromType(type);

  const { data, error } = await supabase.from(table).insert(commentData).select('*').single();

  if (error) {
    throw error;
  }

  return data;
};

export const deleteCommentAPI = async (
  commentId: string,
  type: CommentsType = CommentsType.storyboard
) => {
  const table = getTableFromType(type);
  const { error } = await supabase.from(table).delete().eq('id', commentId);

  if (error) {
    throw error;
  }
};
