import * as THREE from 'three';
import { Clone, Html, useAnimations } from '@react-three/drei';
import { useEffect, useContext, useState, useRef, useMemo } from 'react';
import { ThreeEvent, useLoader } from '@react-three/fiber';
import {
  AssetObject,
  SceneObjectActionTypes,
  SceneObjectFileTypes,
  SupportedSceneObjectTypes,
  gizmoInfoInterface,
} from 'src/types';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { useSceneInteractions } from '../hooks/useSceneInteractions';
import { context, ContextType } from 'src/components/sceneViewer/context';
import store from 'src/store/store';
import { S3_BUCKET_URL } from 'src/utils/aws';
import { ErrorBoundary } from 'react-error-boundary';

import { DRACOLoader, GLTFLoader, FBXLoader } from 'three/examples/jsm/Addons.js';
import { SkeletonUtils } from 'three-stdlib';
import AnnotationContainer from './AnnotationContainer';
type SceneAssetProps = {
  config: AssetObject;
  onClick?: Function;
  onPointerOut?: () => void;
  onPointerOver?: () => void;
  handleDrag?: Function;
  disablePivot?: boolean;
  onRightClick?: Function;
  onDoubleClick?: Function;
};

const defaultSceneAssetProps = {
  onClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  handleDrag: (l: THREE.Matrix4, dl: THREE.Matrix4, w: THREE.Matrix4, dw: THREE.Matrix4) => {},
  onRightClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  onDoubleClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  disablePivot: false,
};

function SceneAsset(props: SceneAssetProps & typeof defaultSceneAssetProps) {
  const { snapToGround, setSnapToGround } = useContext<ContextType>(context);

  const { handleSceneObjectAction, getSelectedObjects } = useSceneViewer();
  const { onGizmoUpdate } = useSceneInteractions();
  const projectId = store.getState().app.projectId;
  const modelUrl = `${S3_BUCKET_URL}/${projectId}/assets/${props.config.backendProperties.metadata.file}`;

  const scaleMatrix = new THREE.Matrix4();
  scaleMatrix.scale(
    new THREE.Vector3(
      props.config.backendProperties.scale[0],
      props.config.backendProperties.scale[1],
      props.config.backendProperties.scale[2]
    )
  );

  let asset = (
    <AssetElement
      url={modelUrl}
      config={props.config}
      onClick={props.onClick}
      onRightClick={props.onRightClick}
      onDoubleClick={props.onDoubleClick}
    />
  );
  if (props.config.backendProperties.metadata.filetype === SceneObjectFileTypes.fbx) {
    asset = (
      <FBXAssetElement
        url={modelUrl}
        config={props.config}
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        onDoubleClick={props.onDoubleClick}
      />
    );
  }
  if (props.config.localProperties.localThreejsObjectJSON !== undefined) {
    if (props.config.backendProperties.metadata.filetype === SceneObjectFileTypes.fbx) {
      asset = (
        <FBXAssetElement
          url={props.config.localProperties.localThreejsObjectJSON as string}
          config={props.config}
          onClick={props.onClick}
          onRightClick={props.onRightClick}
          onDoubleClick={props.onDoubleClick}
        />
      );
    } else {
      asset = (
        <LocalAssetElementLoader
          url={modelUrl}
          config={props.config}
          onClick={props.onClick}
          onRightClick={props.onRightClick}
          onDoubleClick={props.onDoubleClick}
        />
      );
    }
  }

  useEffect(() => {
    if (snapToGround) {
      const selectedObjects = getSelectedObjects();
      if (selectedObjects.length === 1) {
        if (selectedObjects[0].id === props.config.backendProperties.id) {
          snapAssetToGround();
          setSnapToGround(false);
        }
      }
    }
  }, [snapToGround]);

  const snapAssetToGround = () => {
    if (props.config.localProperties.originalBBox !== undefined) {
      const eulerRotation = new THREE.Euler(
        props.config.backendProperties.rotation[0],
        props.config.backendProperties.rotation[1],
        props.config.backendProperties.rotation[2]
      );

      const globalYAxis = new THREE.Vector3(0, 1, 0);
      const globalNegYAxis = new THREE.Vector3(0, -1, 0);
      const localXAxis = new THREE.Vector3(1, 0, 0).applyEuler(eulerRotation);
      const localYAxis = new THREE.Vector3(0, 1, 0).applyEuler(eulerRotation);
      const localZAxis = new THREE.Vector3(0, 0, 1).applyEuler(eulerRotation);

      const angleX = Math.abs(localXAxis.angleTo(globalYAxis));
      const angleY = Math.abs(localYAxis.angleTo(globalYAxis));
      const angleZ = Math.abs(localZAxis.angleTo(globalYAxis));
      const negAngleX = Math.abs(localXAxis.angleTo(globalNegYAxis));
      const negAngleY = Math.abs(localYAxis.angleTo(globalNegYAxis));
      const negAngleZ = Math.abs(localZAxis.angleTo(globalNegYAxis));

      let closestAxis;
      let globalAlignAxis;
      let showGizmo = [true, true, true];
      if (
        angleX < angleY &&
        angleX < angleZ &&
        angleX < negAngleX &&
        angleX < negAngleY &&
        angleX < negAngleZ
      ) {
        closestAxis = localXAxis;
        globalAlignAxis = globalYAxis;
        showGizmo[0] = false;
      } else if (
        angleY < angleX &&
        angleY < angleZ &&
        angleY < negAngleX &&
        angleY < negAngleY &&
        angleY < negAngleZ
      ) {
        closestAxis = localYAxis;
        globalAlignAxis = globalYAxis;
        showGizmo[1] = false;
      } else if (
        angleZ < angleX &&
        angleZ < angleY &&
        angleZ < negAngleX &&
        angleZ < negAngleY &&
        angleZ < negAngleZ
      ) {
        closestAxis = localZAxis;
        globalAlignAxis = globalYAxis;
        showGizmo[2] = false;
      } else if (
        negAngleX < negAngleY &&
        negAngleX < negAngleZ &&
        negAngleX < angleX &&
        negAngleX < angleY &&
        negAngleX < angleZ
      ) {
        closestAxis = localXAxis;
        globalAlignAxis = globalNegYAxis;
        showGizmo[0] = false;
      } else if (
        negAngleY < negAngleX &&
        negAngleY < negAngleZ &&
        negAngleY < angleX &&
        negAngleY < angleY &&
        negAngleY < angleZ
      ) {
        closestAxis = localYAxis;
        globalAlignAxis = globalNegYAxis;
        showGizmo[1] = false;
      } else {
        closestAxis = localZAxis;
        globalAlignAxis = globalNegYAxis;
        showGizmo[2] = false;
      }

      // setGizmoInfo({
      //   ...gizmoInfo,
      //   show: showGizmo,
      // });
      onGizmoUpdate({
        show: showGizmo,
      } as Partial<gizmoInfoInterface>);

      const alignmentQuaternion = new THREE.Quaternion()
        .setFromUnitVectors(closestAxis, globalAlignAxis)
        .normalize();
      const quaternion = new THREE.Quaternion().setFromEuler(eulerRotation).normalize();
      const resultQuaternion = alignmentQuaternion.multiply(quaternion).normalize();
      const resultEuler = new THREE.Euler().setFromQuaternion(
        resultQuaternion,
        eulerRotation.order
      );

      const bBox = props.config.localProperties.originalBBox.clone();
      bBox?.applyMatrix4(
        new THREE.Matrix4().compose(
          new THREE.Vector3(
            props.config.backendProperties.position[0],
            props.config.backendProperties.position[1],
            props.config.backendProperties.position[2]
          ),
          resultQuaternion,
          new THREE.Vector3(
            props.config.backendProperties.scale[0],
            props.config.backendProperties.scale[1],
            props.config.backendProperties.scale[2]
          )
        )
      );

      const center = new THREE.Vector3();
      const size = new THREE.Vector3();
      bBox?.getCenter(center);
      bBox?.getSize(size);

      let offset = props.config.backendProperties.position[1] - center.y;
      const sign = center.y >= 0.01 ? 1.0 : -1.0;

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: {},
          backendProperties: {
            rotation: [resultEuler.x, resultEuler.y, resultEuler.z],
            position: [
              props.config.backendProperties.position[0],
              +0.01 + (size.y / 2.0) * sign + offset,
              props.config.backendProperties.position[2],
            ],
          },
        },
      ]);
    }
  };

  return (
    <group
      onPointerOver={props.onPointerOver}
      onPointerOut={props.onPointerOut}
      position={props.config.backendProperties.position}
      rotation={props.config.backendProperties.rotation}
      scale={props.config.backendProperties.scale}
    >
      <ErrorBoundary fallback={<></>}>{asset}</ErrorBoundary>
    </group>
  );
}

const LocalAssetElementLoader = (props: {
  url: string;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const objectLoader = new GLTFLoader();
  const [object, setObject] = useState<any>(undefined);
  const [gotObject, setGotObject] = useState<boolean>(false);

  if (!gotObject) {
    setGotObject(true);
    objectLoader.parse(
      props.config.localProperties.localThreejsObjectJSON as any,
      '',
      (gltf) => {
        setObject(gltf);
      },
      (err) => {
        console.log(err);
        setGotObject(false);
      }
    );
  }

  if (object === undefined) {
    return <></>;
  } else {
    return (
      <LocalAssetElement
        object={object}
        config={props.config}
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        onDoubleClick={props.onDoubleClick}
      />
    );
  }
};
const LocalAssetElement = (props: {
  object: any;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const { handleSceneObjectAction } = useSceneViewer();
  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(props.object.scene), [props.object.scene]);
  const { actions, names } = useAnimations(props.object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const getannotations = [] as JSX.Element[];

    props.object.scene.traverse((o: any) => {
      if (o.userData.prop) {
        getannotations.push(
          <Html
            key={o.uuid}
            position={[o.position.x, o.position.y, o.position.z]}
            distanceFactor={0.5}
          >
            <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
          </Html>
        );
      }
    });
    setAnnotations(getannotations);
    if (props.config.localProperties.originalBBox === undefined) {
      let bbox: THREE.Box3 | undefined;
      if (names.length > 0) {
        // @ts-ignore
        bbox = new THREE.Box3().setFromObject(primitiveRef.current, true);
      } else {
        bbox = new THREE.Box3().setFromObject(props.object.scene, true);
      }
      let animationState: {
        name: string;
        isPlaying: boolean | undefined;
        time: number | undefined;
        loop: THREE.AnimationActionLoopStyles | undefined;
      }[];
      if (names.length > 0) {
        animationState = names.map((name) => {
          let action = actions[name];
          return {
            name: name,
            isPlaying: action?.isRunning(),
            time: action?.getClip().duration,
            loop: action?.loop,
          };
        });
      } else {
        animationState = [];
      }

      let updatedLocalProperties = {
        originalBBox: bbox,
        animationState: animationState,
        annotationslength: getannotations.length,
        annotationShow: false,
      };
      let updatedBackendProperties = {} as any;

      if (props.config.localProperties.insertedThisSession) {
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();
        bbox?.getCenter(center);
        bbox?.getSize(size);
        let offset = props.config.backendProperties.position[1] - center.y;
        updatedBackendProperties['position'] = [
          props.config.backendProperties.position[0],
          0.01 + size.y / 2.0 + offset,
          props.config.backendProperties.position[2],
        ];
      }

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: updatedLocalProperties,
          backendProperties: updatedBackendProperties,
        },
      ]);
    }
    let currentAnimationState = props.config.localProperties.animationState;
    if (currentAnimationState !== undefined && currentAnimationState?.length > 0) {
      props.config.localProperties.animationState?.forEach((state) => {
        if (state.isPlaying) {
          actions[state.name]?.reset().fadeIn(0.5).play();
        } else {
          actions[state.name]?.fadeOut(0.5);
        }
      });
    }
  }, [props.config]);

  return (
    <group
      ref={primitiveRef}
      onClick={(event) => props.onClick(event, props.config.id, SupportedSceneObjectTypes.asset)}
      onContextMenu={(event) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      onDoubleClick={(event) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      renderOrder={1}
    >
      {names.length > 0 ? (
        <primitive object={newclone}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <primitive object={props.object.scene}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      )}
    </group>
  );
};
const AssetElement = (props: {
  url: string;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const { handleSceneObjectAction } = useSceneViewer();
  let object = useLoader(GLTFLoader, props.url as string, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);
  });
  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(object.scene), [object.scene]);
  const { actions, names } = useAnimations(object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);
  useEffect(() => {
    Object.values(object.materials).forEach((material) => {
      if (material) {
        material.depthWrite = true;
      }
    });
    const getannotations = [] as JSX.Element[];

    object.scene.traverse((o: any) => {
      if (o.userData.prop) {
        getannotations.push(
          <Html
            key={o.uuid}
            position={[o.position.x, o.position.y, o.position.z]}
            distanceFactor={0.5}
          >
            <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
          </Html>
        );
      }
    });
    setAnnotations(getannotations);
    if (props.config.localProperties.originalBBox === undefined) {
      let bbox: THREE.Box3 | undefined;
      if (names.length > 0) {
        // @ts-ignore
        bbox = new THREE.Box3().setFromObject(primitiveRef.current, true);
      } else {
        bbox = new THREE.Box3().setFromObject(object.scene, true);
      }
      let animationState: {
        name: string;
        isPlaying: boolean | undefined;
        time: number | undefined;
        loop: THREE.AnimationActionLoopStyles | undefined;
      }[];
      if (names.length > 0) {
        animationState = names.map((name) => {
          let action = actions[name];
          return {
            name: name,
            isPlaying: action?.isRunning(),
            time: action?.getClip().duration,
            loop: action?.loop,
          };
        });
      } else {
        animationState = [];
      }

      let updatedLocalProperties = {
        originalBBox: bbox,
        animationState: animationState,
        annotationslength: getannotations.length,
        annotationShow: false,
      };
      let updatedBackendProperties = {} as any;

      if (props.config.localProperties.insertedThisSession) {
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();
        bbox?.getCenter(center);
        bbox?.getSize(size);
        let offset = props.config.backendProperties.position[1] - center.y;
        updatedBackendProperties['position'] = [
          props.config.backendProperties.position[0],
          0.01 + size.y / 2.0 + offset,
          props.config.backendProperties.position[2],
        ];
      }

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: updatedLocalProperties,
          backendProperties: updatedBackendProperties,
        },
      ]);
    }
    let currentAnimationState = props.config.localProperties.animationState;
    if (currentAnimationState !== undefined && currentAnimationState?.length > 0) {
      props.config.localProperties.animationState?.forEach((state) => {
        if (state.isPlaying) {
          actions[state.name]?.reset().fadeIn(0.5).play();
        } else {
          actions[state.name]?.fadeOut(0.5);
        }
      });
    }
  }, [props.config]);

  return (
    <group
      ref={primitiveRef}
      onClick={(event) => props.onClick(event, props.config.id, SupportedSceneObjectTypes.asset)}
      onContextMenu={(event) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      onDoubleClick={(event) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      renderOrder={1}
    >
      {names.length > 0 ? (
        <primitive object={newclone}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <Clone object={object.scene}>
          {props.config.localProperties.annotationShow ? annotations : null}
        </Clone>
      )}
    </group>
  );
};

const FBXAssetElement = (props: {
  url: string;
  config: AssetObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const { handleSceneObjectAction } = useSceneViewer();
  let object = useLoader(FBXLoader, props.url as string);
  const primitiveRef = useRef<THREE.Group>(null);
  const newclone = useMemo(() => SkeletonUtils.clone(object), [object]);
  const { actions, names } = useAnimations(object.animations, primitiveRef);
  const [annotations, setAnnotations] = useState<JSX.Element[]>([]);
  useEffect(() => {
    // Object.values(object.materials).forEach((material) => {
    //   if (material) {
    //     material.depthWrite = true;
    //   }
    // });
    const getannotations = [] as JSX.Element[];

    object.traverse((o: any) => {
      if (o.userData.prop) {
        getannotations.push(
          <Html
            key={o.uuid}
            position={[o.position.x, o.position.y, o.position.z]}
            distanceFactor={0.5}
          >
            <AnnotationContainer data={o.userData.prop} index={getannotations.length + 1} />
          </Html>
        );
      }
    });
    setAnnotations(getannotations);
    if (props.config.localProperties.originalBBox === undefined) {
      let bbox: THREE.Box3 | undefined;
      if (names.length > 0) {
        // @ts-ignore
        bbox = new THREE.Box3().setFromObject(primitiveRef.current, true);
      } else {
        bbox = new THREE.Box3().setFromObject(object, true);
      }
      let animationState: {
        name: string;
        isPlaying: boolean | undefined;
        time: number | undefined;
        loop: THREE.AnimationActionLoopStyles | undefined;
      }[];
      if (names.length > 0) {
        animationState = names.map((name) => {
          let action = actions[name];
          return {
            name: name,
            isPlaying: action?.isRunning(),
            time: action?.getClip().duration,
            loop: action?.loop,
          };
        });
      } else {
        animationState = [];
      }

      let updatedLocalProperties = {
        originalBBox: bbox,
        animationState: animationState,
        annotationslength: getannotations.length,
        annotationShow: false,
      };
      let updatedBackendProperties = {} as any;

      if (props.config.localProperties.insertedThisSession) {
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();
        bbox?.getCenter(center);
        bbox?.getSize(size);
        let offset = props.config.backendProperties.position[1] - center.y;
        updatedBackendProperties['position'] = [
          props.config.backendProperties.position[0],
          0.01 + size.y / 2.0 + offset,
          props.config.backendProperties.position[2],
        ];
      }

      handleSceneObjectAction(SceneObjectActionTypes.update, [
        {
          id: props.config.id,
          type: props.config.type,
          localProperties: updatedLocalProperties,
          backendProperties: updatedBackendProperties,
        },
      ]);
    }
    let currentAnimationState = props.config.localProperties.animationState;
    if (currentAnimationState !== undefined && currentAnimationState?.length > 0) {
      props.config.localProperties.animationState?.forEach((state) => {
        if (state.isPlaying) {
          actions[state.name]?.reset().fadeIn(0.5).play();
        } else {
          actions[state.name]?.fadeOut(0.5);
        }
      });
    }
  }, [props.config]);

  return (
    <group
      ref={primitiveRef}
      onClick={(event) => props.onClick(event, props.config.id, SupportedSceneObjectTypes.asset)}
      onContextMenu={(event) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      onDoubleClick={(event) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.asset)
      }
      renderOrder={1}
    >
      {names.length > 0 ? (
        <primitive object={newclone}>
          {' '}
          {props.config.localProperties.annotationShow ? annotations : null}
        </primitive>
      ) : (
        <Clone object={object}>
          {' '}
          {props.config.localProperties.annotationShow ? annotations : null}
        </Clone>
      )}
    </group>
  );
};

SceneAsset.defaultProps = defaultSceneAssetProps;
export default SceneAsset;
