import { Box, Text, Flex, IconButton } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getFileFromS3 } from 'src/utils/aws';
import { useWavesurfer } from '@wavesurfer/react';
import { FaPause, FaPlay } from 'react-icons/fa';

type VoiceNoteProps = {
  voiceNote: any;
};

const formatTime = (seconds: number) =>
  [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':');

const VoiceVisual = ({ url }: { url: string }) => {
  const voiceNoteRef = useRef<HTMLDivElement>(null);

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: voiceNoteRef,
    height: 25,
    barWidth: 3,
    barRadius: 3,
    barGap: 2,
    dragToSeek: true,
    cursorWidth: 2,
    url,
    waveColor: '#C4C4C4',
    progressColor: '#6d8fff',
  });

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause();
  }, [wavesurfer]);

  return (
    <Flex gap={2} align="center">
      <IconButton
        variant="ghost"
        aria-label="play-pause"
        icon={!isPlaying ? <FaPlay /> : <FaPause />}
        onClick={onPlayPause}
        size="xs"
      />
      <Box w="full" ref={voiceNoteRef} />
      <Flex width="2rem" flexShrink={0}>
        <Text m={0} fontSize="xs" color="gray">
          {!isPlaying && wavesurfer
            ? formatTime(wavesurfer?.getDuration())
            : formatTime(currentTime)}
        </Text>
      </Flex>
    </Flex>
  );
};

const VoiceNote: React.FC<VoiceNoteProps> = (props) => {
  const { voiceNote } = props;
  const [data, setData] = useState<any>(null);

  const getVoiceNoteFromS3 = (filename: string) => {
    const projectId = voiceNote.project_id;
    const sceneId = voiceNote.scene_id;

    if (projectId && sceneId) {
      const url = `${projectId}/scenes/${sceneId}/voiceNotes/${filename}`;
      return url;
    }
  };

  useEffect(() => {
    const fileUrl = getVoiceNoteFromS3(voiceNote.filename);
    if (fileUrl) {
      getFileFromS3(fileUrl)
        .then((res: any) => {
          if (res) {
            const blob = new Blob([res], { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(blob);
            setData(audioUrl);
          }
        })
        .catch((err) => console.error(err));
    }
  }, []);

  return <VoiceVisual url={data} />;
};

export default VoiceNote;
