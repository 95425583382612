import { createSlice, current } from '@reduxjs/toolkit';

import {
  SceneObject,
  bboxInfoInterface,
  gizmoInfoInterface,
  refTransformsInterface,
  sceneObjectRefInterface,
  cameraConfigInterface,
  SceneObjectMetadata,
  SupportedSceneObjectTypes,
} from 'src/types';
import { excludeKeys } from 'src/utils/helper';
import * as THREE from 'three';

type sceneObjectListType = SceneObject<SceneObjectMetadata>[];

type SelectedObject = {
  id: string;
  type: SupportedSceneObjectTypes;
};

const initialState = {
  sceneObjectList: [] as sceneObjectListType,
  selectedObjects: [] as SelectedObject[],
  bboxInfo: {
    position: [0.0, 0.0, 0.0],
    rotation: [0.0, 0.0, 0.0],
    scale: [1.0, 1.0, 1.0],
    refBBox: undefined,
  } as bboxInfoInterface,
  gizmoInfo: {
    position: [0.0, 0.0, 0.0],
    rotation: [0.0, 0.0, 0.0],
    scale: [1.0, 1.0, 1.0],
    show: [false, false, false],
  } as gizmoInfoInterface,
  refTransforms: {
    gizmoRef: new THREE.Matrix4(),
    bboxRef: new THREE.Matrix4(),
    sceneObjectsRef: [] as sceneObjectRefInterface[],
  } as refTransformsInterface,
  showLoading: true as boolean,
  currentViewport: null as null | string,
  firstFetchDone: {} as Record<string, number>,
  cameraConfig: {
    position: [-5.0, 10.0, 5.0],
    target: [0.0, 0.0, 0.0],
    upVec: [0.0, 1.0, 0.0],
  } as cameraConfigInterface,
};

export type SceneViewerKeys = Array<keyof typeof initialState>;

const sceneViewerSlice = createSlice({
  name: 'sceneViewer',
  initialState,
  reducers: {
    addSceneObject: (state, { payload: { sceneObject } }) => {
      state.sceneObjectList.push(sceneObject);
    },

    setSceneObjectList: (state, { payload }) => {
      state.sceneObjectList = payload;
    },

    updateSceneObject: (state, { payload: { id, type, sceneObjectChanges } }) => {
      const index = state.sceneObjectList.findIndex((item) => item.id === id && item.type === type);
      if (index === -1) return;
      state.sceneObjectList[index].localProperties = {
        ...state.sceneObjectList[index].localProperties,
        ...sceneObjectChanges.localProperties,
      };
      state.sceneObjectList[index].backendProperties = {
        ...state.sceneObjectList[index].backendProperties,
        ...sceneObjectChanges.backendProperties,
      };
    },

    removeSceneObject: (state, { payload: { id, type } }) => {
      const index = state.sceneObjectList.findIndex((item) => item.id === id && item.type === type);
      if (index === -1) return;
      state.sceneObjectList.splice(index, 1);
    },

    cleanSceneViewer: (
      state,
      { payload: keysToExclude = [] }: { payload: Array<keyof typeof initialState> }
    ) => {
      const initState = excludeKeys(initialState, keysToExclude);
      return { ...state, ...initState };
    },

    updateBBoxInfo: (state, { payload: { changes } }) => {
      state.bboxInfo = {
        ...state.bboxInfo,
        ...changes,
      };
    },

    updateGizmoInfo: (state, { payload: { changes } }) => {
      state.gizmoInfo = {
        ...state.gizmoInfo,
        ...changes,
      };
    },

    updateRefTrasforms: (state, { payload: { changes } }) => {
      state.refTransforms = {
        ...state.refTransforms,
        ...changes,
      };
    },

    updateShowLoading: (state, { payload: loading }) => {
      state.showLoading = loading;
    },

    updateCurrentViewport: (state, { payload: newViewport }) => {
      state.currentViewport = newViewport;
    },

    updateFirstFetchDone: (state, { payload: sceneFetched }) => {
      state.firstFetchDone[sceneFetched] = Date.now();
    },

    updateCameraConfig: (state, { payload: { position, target, upVec } }) => {
      state.cameraConfig = {
        position: position,
        target: target,
        upVec: upVec,
      };
    },

    debugPrintState: (state) => {
      console.log(current(state));
    },

    setSelectedObjects: (state, { payload: selectedObjects }) => {
      state.selectedObjects = selectedObjects;
    },
  },
});

export const {
  addSceneObject,
  updateSceneObject,
  removeSceneObject,
  cleanSceneViewer,
  updateBBoxInfo,
  updateGizmoInfo,
  updateRefTrasforms,
  updateShowLoading,
  updateCurrentViewport,
  updateFirstFetchDone,
  updateCameraConfig,
  debugPrintState,
  setSceneObjectList,
  setSelectedObjects,
} = sceneViewerSlice.actions;

export default sceneViewerSlice.reducer;
