import { Grid, GridItem } from '@chakra-ui/react';
import BoardTools from './components/BoardTools';
import StoryBoardCanvas from './components/Canvas';
import CanvasContainer from './components/nodes/CanvasContainer';
import Comments from '../Comments';
import CursorAnchors from './components/CursorAnchors';
import Collaboration from '../Collaboration';
import { ReactFlowProvider } from 'reactflow';

type StoryboardProps = {
  readOnly?: boolean;
};

const StoryBoard: React.FC<StoryboardProps> = () => {
  return (
    <Grid
      templateAreas={`"header"
                  "main"
                  `}
      gridTemplateRows={'auto 1fr'}
      h="100%"
      gap="1"
      bg="#191B23"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem
        py="2"
        px="4"
        h="100%"
        area={'header'}
        borderBottomColor="#1B1E26"
        borderBottomWidth={3}
      >
        <BoardTools />
        <CursorAnchors />
      </GridItem>
      <CanvasContainer />
      <GridItem p="2" area={'main'} position="relative">
        <ReactFlowProvider>
          <StoryBoardCanvas />
        </ReactFlowProvider>
        <Comments />
        {/*<Debugger />*/}
      </GridItem>
      <Collaboration />
    </Grid>
  );
};

export default StoryBoard;
