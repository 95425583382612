export enum BROADCAST_EVENTS {
  cursor = 'cursor',
  selection = 'selection',
  head = 'head',
  controllers = 'controllers',
  scene_change = 'scene',
}

export enum Channels {
  heads = 'heads',
  users = 'users',
  sync = 'sync',
}
