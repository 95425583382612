import { customAlphabet } from 'nanoid';
import { generateUUID } from 'three/src/math/MathUtils';

const nolookalikes = '12346789ABCDEFGHJKLMNPQRTUVWXYabcdefghijkmnpqrtwxyz';
const shortId = customAlphabet(nolookalikes, 5);

export const getUUID = () => generateUUID();
export const getNodeId = () => `node-${shortId()}`;
export const getEdgeId = () => `edge-${shortId()}`;
export const getShortId = () => shortId();
export const getCommandId = (prefix = '') => `${prefix + '-'}cmd-${shortId()}`;
