import { ChangeEvent } from 'react';
import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { radToDeg, degToRad, applyProportionalScaling } from 'src/utils/helper';
import * as THREE from 'three';
import { Button, Icon, Tooltip } from '@chakra-ui/react';
import { BiExpandVertical, BiCollapseVertical } from "react-icons/bi";
import { GroupObject, SceneObjectActionTypes, SupportedSceneObjectTypes } from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { getWorldTransform, toLocalTransform } from '../helpers';

export default function GroupProperties(props: {
    id: string
}) {
    // scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
    const scene_scale = 1.0;

    const { handleSceneObjectAction } = useSceneViewer();

    const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);
    const sceneObject = useAppSelector((store) => store.sceneViewer.sceneObjectList.find(
        (item) => (item.type === SupportedSceneObjectTypes.group) && (item.id === props.id)
    )) as GroupObject;

    const handleGroupPropertyChange = (
        event: ChangeEvent<HTMLInputElement>,
        field: string,
        propIndex: number) => {

        const objectCopy = structuredClone(sceneObject.backendProperties);

        const worldTransform = getWorldTransform(objectCopy.id);
        if (field === 'position') {
            const newPosition = [...objectCopy.position];
            newPosition[propIndex] = parseFloat(event.target.value) / scene_scale;

            const localTransform = toLocalTransform(objectCopy.id, {
                position: newPosition as [number, number, number],
                rotation: worldTransform.rotation,
                scale: worldTransform.scale,
            });

            // objectCopy.position = newPosition as any;
            objectCopy.position = localTransform.position as [number, number, number];
            objectCopy.rotation = localTransform.rotation as [number, number, number];
            objectCopy.scale = localTransform.scale as [number, number, number];
        } else if (field === 'rotation') {
            const newRotation = [...objectCopy.rotation];
            newRotation[propIndex] = degToRad(parseFloat(event.target.value));
            // objectCopy.rotation = newRotation as any;

            const localTransform = toLocalTransform(objectCopy.id, {
                position: worldTransform.position,
                rotation: newRotation as [number, number, number],
                scale: worldTransform.scale,
            });

            objectCopy.position = localTransform.position as [number, number, number];
            objectCopy.rotation = localTransform.rotation as [number, number, number];
            objectCopy.scale = localTransform.scale as [number, number, number];

        } else if (field === 'scale') {
            let newScale = [...objectCopy.scale];
            newScale[propIndex] = parseFloat(event.target.value);

            if (objectCopy.constrain_proportions) {
                newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
            }

            // objectCopy.scale = newScale as any;

            const localTransform = toLocalTransform(objectCopy.id, {
                position: worldTransform.position,
                rotation: worldTransform.scale as [number, number, number],
                scale: newScale as [number, number, number],
            });

            objectCopy.position = localTransform.position as [number, number, number];
            objectCopy.rotation = localTransform.rotation as [number, number, number];
            objectCopy.scale = localTransform.scale as [number, number, number];

        } else if (field === 'bbox') {
            const size = new THREE.Vector3();
            sceneObject.localProperties.originalBBox?.getSize(size);
            const oldDim = propIndex === 0 ? size.x : propIndex === 1 ? size.y : size.z;
            let newScale = [...objectCopy.scale];
            newScale[propIndex] = (parseFloat(event.target.value) / scene_scale) / oldDim;

            if (objectCopy.constrain_proportions) {
                newScale = applyProportionalScaling([...objectCopy.scale] as any, [...newScale] as any);
            }

            const localTransform = toLocalTransform(objectCopy.id, {
                position: worldTransform.position,
                rotation: worldTransform.scale as [number, number, number],
                scale: newScale as [number, number, number],
            });

            objectCopy.position = localTransform.position as [number, number, number];
            objectCopy.rotation = localTransform.rotation as [number, number, number];
            objectCopy.scale = localTransform.scale as [number, number, number];
        }

        handleSceneObjectAction(
            SceneObjectActionTypes.update,
            [
                {
                    id: sceneObject.id,
                    type: sceneObject.type,
                    localProperties: {},
                    backendProperties: {
                        ...objectCopy
                    }
                }
            ]
        );
    }

    const handleConstrainProportionsChange = () => {
        handleSceneObjectAction(
            SceneObjectActionTypes.update,
            [
                {
                    id: sceneObject.id,
                    type: sceneObject.type,
                    localProperties: {},
                    backendProperties: {
                        constrain_proportions: !sceneObject.backendProperties.constrain_proportions
                    }
                }
            ]
        );
    }


    let groupProperties = null;
    if (sceneObject) {

        const worldTransform = getWorldTransform(sceneObject.id);

        let boundingBoxProperties = null;
        if (sceneObject.localProperties.originalBBox !== undefined) {
            const size = new THREE.Vector3();
            sceneObject.localProperties.originalBBox.getSize(size);
            const width = size.x * worldTransform.scale[0] * scene_scale;
            const height = size.y * worldTransform.scale[1] * scene_scale;
            const depth = size.z * worldTransform.scale[2] * scene_scale;
            boundingBoxProperties =
                <>
                    <br />
                    <PropertyHeading>Bounding Box</PropertyHeading>
                    <label className="label">Dimension</label>
                    <PropertyField
                        value={(width * scene_scale).toFixed(2)}
                        field="bbox"
                        instanceIndex={-1}
                        propIndex={0}
                        handlePropertyChange={handleGroupPropertyChange}
                        type="number"
                        disabled={!gizmoInfo.show[0]}
                    />
                    <PropertyField
                        value={(height * scene_scale).toFixed(2)}
                        field="bbox"
                        instanceIndex={-1}
                        propIndex={1}
                        handlePropertyChange={handleGroupPropertyChange}
                        type="number"
                        disabled={!gizmoInfo.show[1]}
                    />
                    <PropertyField
                        value={(depth * scene_scale).toFixed(2)}
                        field="bbox"
                        instanceIndex={-1}
                        propIndex={2}
                        handlePropertyChange={handleGroupPropertyChange}
                        type="number"
                        disabled={!gizmoInfo.show[2]}
                    />
                </>
        }

        groupProperties = <div className="groupProperties">
            <PropertyHeading>Transform</PropertyHeading>
            <label className="label">Position</label>
            <PropertyField
                value={(worldTransform.position[0] * scene_scale).toFixed(2)}
                field="position"
                instanceIndex={-1}
                propIndex={0}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!gizmoInfo.show[0]}
            />
            <PropertyField
                value={(worldTransform.position[1] * scene_scale).toFixed(2)}
                field="position"
                instanceIndex={-1}
                propIndex={1}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!gizmoInfo.show[1]}
            />
            <PropertyField
                value={(worldTransform.position[2] * scene_scale).toFixed(2)}
                field="position"
                instanceIndex={-1}
                propIndex={2}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!gizmoInfo.show[2]}
            />

            <br />
            <label className="label">Rotation</label>
            <PropertyField
                value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
                field="rotation"
                instanceIndex={-1}
                propIndex={0}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!(gizmoInfo.show[1] && gizmoInfo.show[2])}
            />
            <PropertyField
                value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
                field="rotation"
                instanceIndex={-1}
                propIndex={1}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!(gizmoInfo.show[0] && gizmoInfo.show[2])}
            />
            <PropertyField
                value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
                field="rotation"
                instanceIndex={-1}
                propIndex={2}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!(gizmoInfo.show[0] && gizmoInfo.show[1])}
            />
            <br />
            <label className="label_scale">Scale</label>
            <Tooltip label='Constrain Proportions' zIndex={1001} bg="grey">
                <Button
                    className={`prop-btn ${sceneObject.backendProperties.constrain_proportions ? 'prop-btn-active' : ''}`}
                    size="sm"
                    onClick={handleConstrainProportionsChange}
                >
                    <Icon color="white" as={sceneObject.backendProperties.constrain_proportions ? BiCollapseVertical : BiExpandVertical}>
                    </Icon>
                </Button>
            </Tooltip>
            <PropertyField
                value={worldTransform.scale[0].toFixed(2)}
                field="scale"
                instanceIndex={-1}
                propIndex={0}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!gizmoInfo.show[0]}
            />
            <PropertyField
                value={worldTransform.scale[1].toFixed(2)}
                field="scale"
                instanceIndex={-1}
                propIndex={1}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!gizmoInfo.show[1]}
            />
            <PropertyField
                value={worldTransform.scale[2].toFixed(2)}
                field="scale"
                instanceIndex={-1}
                propIndex={2}
                handlePropertyChange={handleGroupPropertyChange}
                type="number"
                disabled={!gizmoInfo.show[2]}
            />

            {boundingBoxProperties}
        </div>
    }

    return (
        <>
            {groupProperties}
        </>
    );
}
