import { Box } from '@chakra-ui/react';
import CameraViewer from './CameraViewer';
import { useSceneViewer } from './hooks/useSceneViewer';
import LayersPanel from './LayersPanel';

interface LeftSidePanelProps {}

const LeftSidePanel: React.FC<LeftSidePanelProps> = () => {
  const { setCameraFromCurrentViewport } = useSceneViewer();

  return (
    <Box className="sidePanel leftSidePanel">
      <CameraViewer onClick={setCameraFromCurrentViewport} />
      <LayersPanel />
    </Box>
  );
};

export default LeftSidePanel;
