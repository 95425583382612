import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { closeModal } from 'src/store/reducers/modals';
import { ModalType } from 'src/types';
import SceneAI from './SceneAI';

export const SceneAICompanion = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.modal.active[ModalType.sceneAI]);

  const isOpen = modal?.type === ModalType.sceneAI;

  const onClose = () => {
    dispatch(closeModal(ModalType.sceneAI));
  };

  if (!isOpen) return null;

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay bg="#23232354" backdropFilter="blur(5px)" />
      <ModalContent
        transition="height 1s ease-in !important"
        my="20"
        p={4}
        bg="rgba(18, 17, 23, 0.8) !important"
        backdropFilter="auto"
        backdropBlur="10px"
      >
        <SceneAI onClose={onClose} />
      </ModalContent>
    </Modal>
  );
};
