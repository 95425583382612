import type { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { useCallback } from 'react';
import { Edge, Node, NodeChange, Viewport } from 'reactflow';
import {
  deleteEdgeAPI,
  deleteNodeAPI,
  getNodeTypeFromTable,
  updateStorylineInNodeAPI,
} from 'src/apis';
import { createStorylineAPI } from 'src/apis/storyline';
import { setComments } from 'src/store/reducers/comments';
import { useAppDispatch } from 'src/store/reducers/hook';
import {
  addEdge,
  addNode,
  changeMode,
  onEdgesChange,
  onNodesChange,
  removeNode,
  setConnectingEdge,
  setEdges,
  setNodes,
  removeEdge,
  updateNode,
  changeViewport,
  updateEdge,
} from 'src/store/reducers/storyboard';
import store from 'src/store/store';
import { ModalType, Mode } from 'src/types';
import { getUUID } from 'src/utils/ids';
import { runBatch } from 'src/utils/requests';
import { findRightwardPath } from 'src/utils/storyboard';
import { useDebouncedCallback } from 'use-debounce';

export const useCanvas = () => {
  const dispatch = useAppDispatch();

  const onAddNode = useCallback((node: Omit<Node, 'data'> & { data?: any }) => {
    dispatch(addNode(node));
  }, []);

  const onDeleteNode = useCallback((nodeId: string) => {
    dispatch(removeNode(nodeId));
  }, []);

  const onEdgeDelete = useCallback((edgeId: string) => {
    dispatch(removeEdge(edgeId));
  }, []);

  const onNodesUpdate = (changes: NodeChange[]) => {
    // const restChanges = changes.filter((change: any) => change.type !== 'remove');
    // const removeChanges = changes.filter((change: any) => change.type === 'remove');

    // if (removeChanges?.length)
    //   dispatch(
    //     openModal({
    //       type: ModalType.delete,
    //       data: {
    //         ids: removeChanges.map((change: any) => change.id),
    //       },
    //     })
    //   );

    dispatch(onNodesChange(changes));
  };

  const onNodeUpdate = (id: string, payload: Record<string, any>) => {
    const newNode = { id, ...payload };
    dispatch(updateNode(newNode));
  };

  const onAddEdge = useCallback((edge: Edge | Edge[]) => {
    dispatch(addEdge(edge));
  }, []);

  const onEdgeUpdate = (edge: Edge[]) => {
    dispatch(updateEdge(edge));
  };

  const onEdgeMoveStart = (event: any, node: Node) => {
    dispatch(setConnectingEdge(node.id));
  };

  const updateChanges = useDebouncedCallback((changes: any) => {
    const isModalOpen = store.getState().modal.active[ModalType.delete];
    const newChanges = isModalOpen
      ? changes.filter((change: any) => {
          return change.type !== 'remove';
        })
      : changes;
    dispatch(onEdgesChange(newChanges));
  }, 100);

  const onEdgesUpdate = (changes: any) => {
    // if (changes.find((change: any) => change.type === 'remove')) {
    //   if (updateChanges.isPending()) updateChanges.cancel();
    //   updateChanges(changes);
    // } else {
    //   dispatch(onEdgesChange(changes));
    // }
    dispatch(onEdgesChange(changes));
  };

  const setViewPort = (viewport: Viewport) => {
    dispatch(changeViewport(viewport));
  };

  const onNodesDelete = async (nodes: Node[], callback: () => void) => {
    return runBatch(
      nodes.map((node) =>
        deleteNodeAPI(node.id, node.type as any).finally(() => {
          callback();
        })
      )
    );
  };

  const onChangeMode = (mode: Mode) => {
    dispatch(changeMode(mode));
  };

  const updateStorylineOfChildren = (node: any, children?: any[]) => {
    if (!node?.id) return;

    const childrenToUpdate = children ?? findRightwardPath({ id: node.id } as any, []);

    if (!childrenToUpdate?.length) return;

    const source = { ...node };

    if (!children && !source.storyline) {
      const newStoryline = getUUID();
      const projectId = store.getState().app.projectId;

      source.storyline = newStoryline;
      createStorylineAPI(projectId, newStoryline).then(() => {
        onUpdateStoryline(childrenToUpdate, source.storyline);
      });
    } else {
      onUpdateStoryline(childrenToUpdate, !children ? source.storyline : null);
    }

    return childrenToUpdate;
  };

  const onUpdateStoryline = (nodes: any[], storyline?: string) => {
    nodes.forEach((node) => {
      const updatedNode = { storyline: storyline ?? node.storyline };

      onNodeUpdate(node.id, updatedNode);
      updateStorylineInNodeAPI(node.id, updatedNode.storyline);
    });
  };

  const onEdgesDelete = async (edges: Edge[]) => {
    return runBatch(
      edges.map((edge) => {
        dispatch(removeEdge(edge.id));
        return deleteEdgeAPI(edge.id);
      })
    );
  };

  const setBoardNodes = (nodes: Node[]) => dispatch(setNodes(nodes));
  const setBoardEdges = (edges: Edge[]) => dispatch(setEdges(edges));
  const setBoardComments = (edges: Edge[]) => dispatch(setComments(edges));

  const nodesEventHandler = (event: RealtimePostgresChangesPayload<any>) => {
    const userId = store.getState().app.currentUser?.id;

    if (!event || event.new.updated_by === userId) return;

    const payload = event.new;
    const type = getNodeTypeFromTable(event.table);
    const node = { ...payload, type };

    switch (event.eventType) {
      case 'UPDATE':
        onNodeUpdate(node.id, node);
        break;
      case 'INSERT':
        onAddNode(node);
        break;
      case 'DELETE':
        onDeleteNode(event.old.id);
        break;
      default:
        console.error('Unhandled realtime event', event);
    }
  };

  const edgesEventHandler = (event: RealtimePostgresChangesPayload<any>) => {
    const userId = store.getState().app.currentUser?.id;
    if (!event || event.new.updated_by === userId) return;

    const payload = event.new;

    switch (event.eventType) {
      case 'INSERT':
        onAddEdge(payload);
        break;
      case 'DELETE':
        onEdgeDelete(event.old.id);
        break;
      default:
        console.error('Unhandled realtime event', event);
    }
  };

  return {
    onAddNode,
    onDeleteNode,
    onNodesUpdate,
    onEdgesUpdate,
    onEdgeDelete,
    onNodeUpdate,
    onNodesDelete,
    setBoardEdges,
    onEdgeMoveStart,
    setBoardNodes,
    onAddEdge,
    setViewPort,
    updateStorylineOfChildren,
    onEdgesDelete,
    onChangeMode,
    setBoardComments,
    onEdgeUpdate,
    nodesEventHandler,
    edgesEventHandler,
  };
};
