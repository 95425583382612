import { Node, getIncomers, getOutgoers } from 'reactflow';
import store from 'src/store/store';

export const getConnectedNodes = (id: string) => {
  const leftwardPath = findLeftwardPath({ id } as any, []);
  const rightwardPath = findRightwardPath({ id } as any, []);

  return {
    left: leftwardPath,
    right: rightwardPath,
  };
};

const getIncomer = (id: string) => {
  const { nodes, edges } = store.getState().storyboard;
  return getIncomers({ id, position: { x: 0, y: 0 }, data: null }, nodes, edges).at(0);
};

const getOutgoer = (id: string) => {
  const { nodes, edges } = store.getState().storyboard;
  return getOutgoers({ id, position: { x: 0, y: 0 }, data: null }, nodes, edges).at(0);
};

export function findLeftwardPath(node: Node, path: Node[]): Node[] {
  const incomer = getIncomer(node.id);

  if (!incomer) {
    return path;
  }

  path.push(incomer);

  if (!getIncomer(incomer.id)) {
    return path;
  }

  return findLeftwardPath(incomer, path);
}

export function findRightwardPath(node: Node, path: Node[]): Node[] {
  const outgoer = getOutgoer(node.id);

  if (!outgoer) {
    return path;
  }

  path.push(outgoer);

  if (!getOutgoer(outgoer.id)) {
    return path;
  }

  return findRightwardPath(outgoer, path);
}
