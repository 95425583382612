import { useMemo, useState } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { flattenTree, removeItem, removeChildrenOf, setProperty, sortItems } from 'src/utils/tree';
import type { SupportedSceneObjectTypes, TreeItems } from 'src/types';
import { SortableTreeItem } from './TreeItem';

interface Props {
  collapsible?: boolean;
  defaultItems: TreeItems;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
  disableInteraction?: boolean;
  activeId?: string;
  onClick?: (id: UniqueIdentifier, type: SupportedSceneObjectTypes) => void;
}

export const SortableTree: React.FC<Props> = ({
  collapsible,
  defaultItems,
  indicator = false,
  indentationWidth = 20,
  removable,
  activeId,
  disableInteraction = false,
  onClick,
}) => {
  const [items, setItems] = useState(() => defaultItems);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(sortItems(items));
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        (collapsed && children?.length ? [...acc, id] : acc) as any,
      []
    );

    return removeChildrenOf(flattenedTree, collapsedItems as any);
  }, [items]);

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);

  return (
    <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
      {flattenedItems.map(({ id, children, collapsed, depth, data }) => (
        <SortableTreeItem
          key={id}
          id={id}
          value={data?.name ?? data?.type}
          iconType={data?.type}
          depth={depth}
          indentationWidth={indentationWidth}
          disableInteraction={disableInteraction}
          indicator={indicator}
          active={activeId === id}
          onClick={() => handleActive(id, data?.type)}
          collapsed={Boolean(collapsed && children.length)}
          onCollapse={collapsible && children?.length ? () => handleCollapse(id) : undefined}
          onRemove={removable ? () => handleRemove(id) : undefined}
        />
      ))}
    </SortableContext>
  );

  function handleRemove(id: UniqueIdentifier) {
    setItems((items) => removeItem(items, id));
  }

  function handleActive(id: UniqueIdentifier, type: SupportedSceneObjectTypes) {
    if (disableInteraction) return;

    if (onClick) {
      onClick(id, type);
    }
  }

  function handleCollapse(id: UniqueIdentifier) {
    setItems((items) =>
      setProperty(items, id, 'collapsed', (value) => {
        return !value;
      })
    );
  }
};
