import { useFrame, useThree } from '@react-three/fiber';
import { ReactNode, forwardRef, useEffect, useRef } from 'react';
import { PointerLockControls } from '@react-three/drei';
import { Vector3 } from 'three';

type WASDcontrolesProps = {
  children?: ReactNode;
  controlsEnabled: boolean;
  orbitControlsRef: any;
};

const velocity = new Vector3();
const vec = new Vector3();
const direction = new Vector3();

const WASDcontroles = forwardRef<WASDcontrolesProps, any>((props, ref) => {
  const pointerRef = useRef<any>(null);
  const { camera } = useThree();

  const movement = useRef({
    forward: false,
    backward: false,
    left: false,
    right: false,
    speed: 0.1,
  });

  // Event listeners for keydown and keyup to update movement state
  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'w':
        movement.current.forward = true;
        break;
      case 's':
        movement.current.backward = true;
        break;
      case 'a':
        movement.current.left = true;
        break;
      case 'd':
        movement.current.right = true;
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'w':
        movement.current.forward = false;
        console.log('w');
        break;
      case 's':
        movement.current.backward = false;
        break;
      case 'a':
        movement.current.left = false;
        break;
      case 'd':
        movement.current.right = false;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useFrame((state, delta) => {
    if (props.controlsEnabled) {
      velocity.x -= velocity.x * 1.0 * delta;
      velocity.z -= velocity.z * 1.0 * delta;
      const { forward, backward, left, right } = movement.current;
      direction.z = Number(forward) - Number(backward);
      direction.x = Number(right) - Number(left);
      direction.normalize();
      if (forward || backward) {
        velocity.z -= direction.z * 4.0 * delta;
        vec.setFromMatrixColumn(camera.matrix, 0);
        vec.crossVectors(camera.up, vec);
        camera.position.addScaledVector(vec, -velocity.z * delta);
        // pointerRef.current.moveForward(-velocity.z * delta);
        props.orbitControlsRef.current.target.addScaledVector(vec, -velocity.z * delta); // Adjust target position
      }
      if (left || right) {
        velocity.x -= direction.x * 4.0 * delta;
        vec.setFromMatrixColumn(camera.matrix, 0);
        camera.position.addScaledVector(vec, -velocity.x * delta);
        // pointerRef.current.moveRight(-velocity.x * delta);
        props.orbitControlsRef.current.target.addScaledVector(vec, -velocity.x * delta); // Adjust target position
      }

      //@ts-ignore
    }
  });

  return props.controlsEnabled ? (
    <PointerLockControls ref={pointerRef} makeDefault enabled={props.controlsEnabled} />
  ) : (
    <></>
  );
});

export default WASDcontroles;
