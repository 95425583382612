import {
  executeCommand,
  Command,
  handleRedo,
  handleUndo,
  enableHistory,
  updateCommand,
  resetHistory,
  changeTag,
} from 'src/store/reducers/history';
import { useAppDispatch } from 'src/store/reducers/hook';
import store from 'src/store/store';

const useUndoRedo = () => {
  const dispatch = useAppDispatch();

  const execute = (cmd: Command) => {
    dispatch(executeCommand(cmd));
  };

  const undo = () => {
    const state = store.getState().history;
    const activeHistory = state.history[state.currentTag];

    if (activeHistory.currentIndex <= 0 || !state.enabled) return;

    const lastCmd = activeHistory.stack[activeHistory.currentIndex - 1];
    lastCmd?.undoFn?.();

    dispatch(handleUndo());
  };

  const redo = () => {
    const state = store.getState().history;
    const activeHistory = state.history[state.currentTag];

    if (activeHistory.currentIndex > activeHistory.stack.length - 1 || !state.enabled) return;

    const nextCmd = activeHistory.stack[activeHistory.currentIndex];
    nextCmd.executeFn();
    dispatch(handleRedo());
  };

  const update = (cmd: Partial<Command>) => {
    dispatch(updateCommand(cmd));
  };

  const pause = () => {
    dispatch(enableHistory(false));
  };

  const resume = () => {
    dispatch(enableHistory(true));
  };

  const reset = () => {
    dispatch(resetHistory());
  };

  const change = (tag: string = 'storyboard') => {
    dispatch(changeTag(tag));
  };

  return { execute, undo, redo, update, pause, resume, reset, change };
};

export default useUndoRedo;
