import { useCanvas } from 'src/features/StoryBoard/hooks/useCanvas';

export const useEventHandler = () => {
  const { nodesEventHandler, edgesEventHandler } = useCanvas();

  return {
    nodes: nodesEventHandler,
    edges: edgesEventHandler,
  };
};
