import React, { forwardRef, HTMLAttributes, CSSProperties } from 'react';
import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { BiCaretDown, BiCaretRight } from 'react-icons/bi';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BsArrowsMove, BsBox, BsCameraVideo, BsPerson, BsImage, BsSquare } from 'react-icons/bs';
import { SupportedSceneObjectTypes } from 'src/types';

const getSupportedIcon = (type: SupportedSceneObjectTypes) => {
  switch (type) {
    case SupportedSceneObjectTypes.asset:
      return BsBox;
    case SupportedSceneObjectTypes.group:
      return BsSquare;
    case SupportedSceneObjectTypes.interactions:
      return BsArrowsMove;
    case SupportedSceneObjectTypes.controller:
    case SupportedSceneObjectTypes.head:
      return BsPerson;
    case SupportedSceneObjectTypes.ui:
      return BsImage;
    case SupportedSceneObjectTypes.viewport:
      return BsCameraVideo;
    default:
      return BsCameraVideo;
  }
};

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  iconType?: string;
  indentationWidth: number;
  value: string;
  active?: boolean;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      iconType,
      onCollapse,
      onRemove,
      style,
      value,
      active,
      wrapperRef,
      ...props
    },
    ref
  ) => {
    return (
      <li
        ref={wrapperRef}
        className={!disableInteraction ? `tree-item ${active ? 'active-tree-item' : ''}` : ''}
        style={
          {
            listStyle: 'none',
            paddingLeft: `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        {...props}
      >
        <Flex
          px={2}
          h={8}
          rounded="md"
          ref={ref}
          align="center"
          gap={2}
          style={style}
          userSelect="none"
        >
          <Icon as={getSupportedIcon(iconType as any)} />
          <Text
            fontSize="small"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            flexGrow="1"
            lineHeight={8}
            m={0}
          >
            {value}
          </Text>
          {onCollapse && (
            <IconButton
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                onCollapse();
              }}
              aria-label="collapse"
              icon={collapsed ? <BiCaretRight /> : <BiCaretDown />}
              size="xs"
            />
          )}
        </Flex>
      </li>
    );
  }
);

type TProps = {
  id: UniqueIdentifier;
} & any;

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true;

export const SortableTreeItem: React.FC<TProps> = ({ id, depth, ...props }) => {
  const { attributes, isSorting, listeners, transform, transition } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      style={style}
      depth={depth}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
};
