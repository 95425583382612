import { supabase } from 'src/SupabaseClient';
import { RoleType } from 'src/types';
import { TABLES } from 'src/utils/constants';

export const getUser = async (userId: string) => {
  const { data: user, error } = await supabase
    .from(TABLES.users)
    .select('*')
    .eq('id', userId)
    .single();
  if (error) throw error;
  return user;
};

export const updateUser = async (updates: any) => {
  return await supabase.from(TABLES.users).update(updates).eq('id', updates.id);
};

export const getProjectMembers = async (projectId: string) => {
  const { data, error } = await supabase.rpc('handle_get_project_users', { id: projectId });

  if (data) {
    return {
      data: data.map((user: any) => ({
        id: user.ref_id,
        email: user.email_id,
        fullname: user.metadata.full_name ?? '',
        username: user.metadata.username ?? user.email_id,
        role: user.role,
      })),
    };
  }

  return {
    error,
  };
};

export const inviteUser = async (data: any) => {
  const res = await supabase.rpc('handle_invite', data);
  return res;
};

export const updateInvitee = async (projectId: string, id: string, role: RoleType) => {
  const res = await supabase
    .from(TABLES.invites)
    .update({ role })
    .eq('email', id)
    .eq('project_id', projectId);
};
