import { Html } from '@react-three/drei';
import './SceneComment.css';
import {
  Box,
  ChakraProvider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { useMemo, useRef, useState } from 'react';
import { CommentsType, DUMMY_NODE } from 'src/types';
import AvatarsGroup from 'src/features/Comments/AvatarGroup';
import { CommentBox } from 'src/features/StoryBoard/components/nodes/CommentNode';
import { deleteComment, setSelectedComment } from 'src/store/reducers/comments';
import store from 'src/store/store';
import { useAppSelector } from 'src/store/reducers/hook';
import { useDispatch } from 'react-redux';
import { setActiveTool } from 'src/store/reducers/InstanceReducer';

type SceneCommentProps = {
  node: any;
};

const Container = (props: any) => {
  const { id, isSelected, onRemove, commentIds, position } = props;

  const dispatch = store.dispatch;
  const firstFieldRef = useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOpen = () => {
    onOpen();
    dispatch(setSelectedComment(id));
  };

  const handleClose = () => {
    onRemove();
    dispatch(setSelectedComment(undefined));
    onClose();
  };

  return (
    <Box
      bg="#4f4f4f"
      zIndex={2}
      borderRadius="full"
      className="nowheel"
      style={{
        border: 'none',
      }}
      _hover={
        !isSelected
          ? {
              bg: '#5a5a5a',
              cursor: 'pointer',
            }
          : {}
      }
      transition="all 0.2s"
      transformOrigin="bottom left"
      shadow="lg"
      borderBottomLeftRadius={5}
    >
      <Popover
        placement="right-start"
        isOpen={isOpen || isSelected}
        initialFocusRef={firstFieldRef}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <PopoverTrigger>
          <Box p={2} userSelect="none">
            {isLoading ? (
              <Spinner p={0} thickness="3px" speed="0.65s" color="blue.500" size="lg" />
            ) : props.id !== DUMMY_NODE ? (
              <AvatarsGroup id={props.id} ids={commentIds} />
            ) : (
              <Box p={1} />
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent w="fit-content" p={0}>
          {(isOpen || isSelected) && (
            <CommentBox
              id={props.id}
              commentIds={commentIds}
              type={CommentsType.scene}
              ref={firstFieldRef}
              isLoading={isLoading}
              setLoading={setIsLoading}
              position={position}
            />
          )}
        </PopoverContent>
      </Popover>
    </Box>
  );
};

function SceneComment({ node }: SceneCommentProps) {
  const dispatch = useDispatch();
  const commentIds = useAppSelector((store) => store.comments.ids);
  const isSelected = useAppSelector((store) => store.comments.selected === node.id);

  const onRemove = () => {
    if (node.id === DUMMY_NODE) {
      dispatch(deleteComment(node.id));
      dispatch(setActiveTool(undefined));
    }
  };

  const ids = useMemo(() => {
    return commentIds.filter((id) => {
      const comment = store.getState().comments.entities[id];

      return (comment.id !== DUMMY_NODE && comment.id === node.id) || comment.parent_id === node.id;
    });
  }, [commentIds]);

  return (
    <Html position={node.position} zIndexRange={[100, 0]}>
      <ChakraProvider>
        <Container
          id={node.id}
          position={node.position}
          commentIds={ids}
          isSelected={isSelected}
          onRemove={onRemove}
        />
      </ChakraProvider>
    </Html>
  );
}

export default SceneComment;
