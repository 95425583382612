import { DeleteIcon } from '@chakra-ui/icons';
import { Flex, Box, Text, Heading, IconButton } from '@chakra-ui/react';
import { forwardRef, useMemo } from 'react';
import store from 'src/store/store';
import type { Comment } from 'src/types';
import timeHelper from 'src/utils/time';
import { UserAvatar } from '../../../components/Avatar';

type NodeCommentProps = {
  id: string;
  commentIds: string[];
  onDelete: (id: string) => void;
};

const NodeComment = forwardRef<HTMLDivElement, NodeCommentProps>(
  ({ id, commentIds, onDelete }, ref) => {
    const comments = useMemo(() => {
      const f = commentIds.filter((comment: string) => {
        const c = store.getState().comments.entities[comment];
        return c.parent_id === id || c.id === id;
      });

      return f;
    }, [commentIds]);

    if (!comments.length) return <></>;

    return (
      <Box mb={5} maxH={320} overflowY="scroll">
        {comments.map((id) => {
          const comment = store.getState().comments.entities[id] as Comment;
          const user = store.getState().users.entities[comment.user_id];

          return (
            <Box
              mb={5}
              minWidth="340"
              borderRadius="lg"
              color="#e4e8f7"
              {...(comment.parent_id === null &&
                comments.length > 1 && {
                  position: 'sticky',
                  zIndex: 1,
                  top: 0,
                  bg: '#3d3d3d',
                  borderBottom: '2px solid #4c4c4c',
                  borderRadius: 0,
                  pb: 4,
                })}
              transition="background-color 0.3s"
            >
              <Flex gap="4">
                <Flex
                  flex="1"
                  gap="4"
                  alignItems="flex-start"
                  flexWrap="wrap"
                  mb={2}
                  userSelect="none"
                >
                  <UserAvatar
                    id={user?.id}
                    color="white"
                    name={user?.fullname || user?.username}
                    size="sm"
                  />
                  <Box>
                    <Heading size="sm" m={0}>
                      {user?.fullname || user?.username}
                    </Heading>
                    <Text fontSize="xs" m={0} color="gray.400">
                      {timeHelper(comment.created_at).fromNow()}
                    </Text>
                  </Box>
                </Flex>
                {comment.user_id === store.getState().app.currentUser?.id &&
                  comment.parent_id !== null && (
                    <IconButton
                      size="sm"
                      variant="ghost"
                      onClick={() => onDelete(comment.id)}
                      colorScheme="gray"
                      aria-label="Options"
                      icon={<DeleteIcon color="gray" />}
                    />
                  )}
              </Flex>
              <Text
                mb={0}
                whiteSpace="wrap"
                fontWeight="normal"
                fontSize="medium"
                lineHeight="140%"
              >
                {comment.comment}
              </Text>
            </Box>
          );
        })}
        <div ref={ref} />
      </Box>
    );
  }
);

export default NodeComment;
