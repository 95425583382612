import { createSlice } from '@reduxjs/toolkit';
import type { ModalAction, ModalType } from 'src/types';
import { isObjEmpty } from 'src/utils/merge';

const initialState = {
  active: {} as Record<ModalType, ModalAction | undefined>,
  contextMenu: {
    items: {} as Record<string, any>,
    position: { x: 0, y: 0 },
    isOpen: false,
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, { payload }: { payload: ModalAction }) {
      state.active[payload?.type] = payload;
    },
    updateModal(state, { payload: { type, ...restPayload } }: { payload: ModalAction }) {
      if (state.active[type]?.type === type) {
        state.active[type] = { type, ...restPayload };
      }
    },
    closeModal(state, { payload: type }: { payload: ModalType }) {
      state.active[type] = undefined;
    },
    openContextMenu(
      state,
      {
        payload: { items, position },
      }: {
        payload: { items: any; position?: { x: number; y: number } };
      }
    ) {
      if (isObjEmpty(items)) {
        state.contextMenu.items = {};
        state.contextMenu.isOpen = false;
      } else {
        state.contextMenu.position = position ?? { x: 0, y: 0 };
        state.contextMenu.items = items;
        state.contextMenu.isOpen = true;
      }
    },
  },
});

export const { openModal, updateModal, closeModal, openContextMenu } = modalSlice.actions;

export default modalSlice.reducer;
