import Modals from 'src/components/Modals';
import FigmaImport from 'src/components/sceneViewer/FigmaImport';
import LeftSidePanel from 'src/components/sceneViewer/LeftSidePanel';
import SceneViewer from 'src/components/sceneViewer/SceneViewer';
import SidePanel from 'src/components/sceneViewer/SidePanel';
import StoryLineDrawer from 'src/components/sceneViewer/StoryLineDrawer';
import TopPanel from 'src/components/sceneViewer/TopPanel';
import TopRightPanel from 'src/components/sceneViewer/TopRightPanel';
import useSceneViewerInit from 'src/features/SceneViewer/hooks/useInit';

const Editor = () => {
  useSceneViewerInit();

  return (
    <>
      <SceneViewer>
        <SidePanel />
        <TopPanel />
        <TopRightPanel />
        <FigmaImport />
        <LeftSidePanel />
        <canvas id="sceneViewerCanvas" />
      </SceneViewer>
      <StoryLineDrawer />
      <Modals />
    </>
  );
};

export default Editor;
