import { supabase } from 'src/SupabaseClient';
import { Tables } from 'src/types';
import { TABLES } from 'src/utils/constants';
import { eulerToQuaternion } from 'src/utils/helper';

export const getAllInstancesAPI = async (projectId: string) => {
  const { data: instances } = await supabase
    .from(TABLES.instances)
    .select('*')
    .eq('project_id', projectId);

  return instances;
};

export const updateInstanceAPI = async (id: string, instance: any) => {
  if (instance['rotation'] !== undefined) {
    if (instance['rotation'].length === 3) {
      instance['rotation'] = eulerToQuaternion(instance['rotation']);
    }
  }

  const { data: updatedData, error } = await supabase
    .from(TABLES.instances)
    .update(instance)
    .eq('id', id);
};

export const duplicateSceneInstancesAPI = async (sceneId: string, newSceneId: string) => {
  const { data: info, error } = await supabase.functions.invoke('copy-group-by-scene', {
    body: {
      scene_id: sceneId,
      new_scene_id: newSceneId,
    },
  });

  return info;
};

export const getAllViewportInstancesAPI = async (projectId: string) => {
  const { data: viewportInstances } = await supabase
    .from(TABLES.camera_instances)
    .select('*')
    .eq('project_id', projectId);

  return viewportInstances;
};

export const deleteInstanceAPI = async (id: string) => {
  await supabase.from(TABLES.instances).delete().eq('id', id);
};

export const insertInstanceAPI = async (instance: any) => {
  if (instance['rotation'] !== undefined) {
    if (instance['rotation'].length === 3) {
      instance['rotation'] = eulerToQuaternion(instance['rotation']);
    }
  }
  const { data: insertedData, error } = await supabase
    .from(TABLES.instances)
    .insert([instance])
    .select();
  return insertedData;
};

export const findInstanceAPI = async (id: string) => {
  const { data: assetInstances } = await supabase.from(TABLES.instances).select('*').eq('id', id);
  return assetInstances;
};

export const ungroupAPI = async (groupId: string) => {
  await supabase.functions.invoke('ungroup', {
    body: {
      group_id: groupId,
    },
  });
};

export const updateViewportInstanceAPI = async (id: string, viewportInstance: any) => {
  if (viewportInstance['rotation'] !== undefined) {
    if (viewportInstance['rotation'].length === 3) {
      viewportInstance['rotation'] = eulerToQuaternion(viewportInstance['rotation']);
    }
  }
  const { data: updatedData, error } = await supabase
    .from(TABLES.camera_instances)
    .update(viewportInstance)
    .eq('id', id);
};

export const insertViewportInstanceAPI = async (viewportInstance: any) => {
  if (viewportInstance['rotation'] !== undefined) {
    if (viewportInstance['rotation'].length === 3) {
      viewportInstance['rotation'] = eulerToQuaternion(viewportInstance['rotation']);
    }
  }
  const { data: insertedData, error } = await supabase
    .from(TABLES.camera_instances)
    .insert([viewportInstance])
    .select();
  return insertedData;
};

export const deleteViewportInstanceAPI = async (id: string) => {
  await supabase.from(TABLES.camera_instances).delete().eq('id', id);
};

export const findViewportInstanceAPI = async (id: string) => {
  const { data: viewportInstances } = await supabase
    .from(TABLES.camera_instances)
    .select('*')
    .eq('id', id);
  return viewportInstances;
};

// UI Instances API
export const getAllUiInstancesAPI = async (projectId: string) => {
  const { data: uiInstances } = await supabase
    .from(TABLES.ui_instances)
    .select('*')
    .eq('project_id', projectId);

  return uiInstances;
};

export const updateUiInstanceAPI = async (id: string, uiInstance: any) => {
  if (uiInstance['rotation'] !== undefined) {
    if (uiInstance['rotation'].length === 3) {
      uiInstance['rotation'] = eulerToQuaternion(uiInstance['rotation']);
    }
  }
  const { data: updatedData, error } = await supabase
    .from(TABLES.ui_instances)
    .update(uiInstance)
    .eq('id', id);
};

export const insertUiInstanceAPI = async (uiInstance: any) => {
  if (uiInstance['rotation'] !== undefined) {
    if (uiInstance['rotation'].length === 3) {
      uiInstance['rotation'] = eulerToQuaternion(uiInstance['rotation']);
    }
  }
  const { data: insertedData, error } = await supabase
    .from(TABLES.ui_instances)
    .insert([uiInstance])
    .select();
  return insertedData;
};

export const deleteUiInstanceAPI = async (id: string) => {
  await supabase.from(TABLES.ui_instances).delete().eq('id', id);
};

export const findUiInstanceAPI = async (id: string) => {
  const { data: uiInstances } = await supabase.from(TABLES.ui_instances).select('*').eq('id', id);
  return uiInstances;
};
// Voice Instance APIs

export const getAllVoiceInstancesAPI = async (projectId: string, sceneId: string) => {
  const { data: voiceInstances } = await supabase
    .from(TABLES.voicenote_instances)
    .select('*')
    .eq('project_id', projectId);
  //    .eq('scene_id', sceneId);

  return voiceInstances;
};

// Group Instance API's
export const getSceneGroupInstancesAPI = async (projectId: string, sceneId: string) => {
  if (!sceneId || !projectId) return [];

  const { data: instances } = await supabase
    .from(TABLES.group_instances)
    .select('*')
    .eq('project_id', projectId)
    .eq('scene_id', sceneId);

  return instances;
};

export const getBackgroundGroupInstancesAPI = async (sceneIds: string[]) => {
  if (!sceneIds?.length) return [];

  const { data: backgroundInstances } = await supabase
    .from(TABLES.group_instances)
    .select('*')
    .in('scene_id', sceneIds)
    .eq('background', true);

  return backgroundInstances;
};

export const updateGroupInstanceAPI = async (id: string, instance: any) => {
  if (instance['rotation'] !== undefined) {
    if (instance['rotation'].length === 3) {
      instance['rotation'] = eulerToQuaternion(instance['rotation']);
    }
  }

  const { data: updatedData, error } = await supabase
    .from(TABLES.group_instances)
    .update(instance)
    .eq('id', id);
};

// * Move these APIs to separate API library
export const insertGroupInstanceAPI = async (instance: any) => {
  const instances = [] as Tables<'group_instances'>[];

  if (Array.isArray(instance)) {
    instance.forEach((inst) => {
      if (inst['rotation'] !== undefined && inst['rotation'].length === 3) {
        inst['rotation'] = eulerToQuaternion(inst['rotation']);
      }

      instances.push(inst);
    });
  } else {
    if (instance['rotation'] !== undefined && instance['rotation'].length === 3) {
      instance['rotation'] = eulerToQuaternion(instance['rotation']);
    }

    instances.push(instance);
  }

  const { data: insertedData, error } = await supabase
    .from(TABLES.group_instances)
    .insert(instances)
    .select();

  return insertedData;
};

export const deleteGroupEdgeAPI = async (groupId: string) => {
  await supabase.functions.invoke('delete-group', {
    body: {
      group_id: groupId,
    },
  });
};

export const deleteGroupInstanceAPI = async (groupId: string) => {
  await supabase.from(TABLES.group_instances).delete().eq('id', groupId);
};

export const findGroupInstanceAPI = async (id: string) => {
  const { data: instances } = await supabase.from(TABLES.group_instances).select('*').eq('id', id);
  return instances;
};

export const copyGroupInstanceAPI = async (groupId: string, sceneId: string) => {
  const { data: info, error } = await supabase.functions.invoke('copy-group', {
    body: {
      group_id: groupId,
      scene_id: sceneId,
    },
  });

  return info;
};
