import {
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Center,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaArrowRotateRight } from 'react-icons/fa6';
import { z } from 'zod';
import ResizeTextarea from 'react-textarea-autosize';
import PromptGeneration from './PromptProcessing';
import { updateModal } from 'src/store/reducers/modals';
import { useAppDispatch } from 'src/store/reducers/hook';
import { ModalType } from 'src/types';
import { exampleJSON } from 'src/utils/sceneAI';

type SceneAIProps = {
  onClose: () => void;
};

const PromptSchema = z.object({
  prompt: z.string().min(1),
});

// const promptExamples = [
//   't-pose, cozy sweater with stylish flower pattern, that is in 3D that is hollow inside, in t-pose',
//   'Golden dragon head, jade sculpture, super detail, jellycat style, 4k',
// ];

const promptExamples = Object.keys(exampleJSON);

type PromptSchemaType = z.infer<typeof PromptSchema>;

const SceneAI: React.FC<SceneAIProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [isGenerating, setIsGenerating] = useState(false);

  const toast = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid },
    setFocus,
  } = useForm<PromptSchemaType>({ resolver: zodResolver(PromptSchema) });

  const onTryExample = () => {
    const index = (currentIndex + 1) % promptExamples.length;
    setCurrentIndex(index);

    const prompt = promptExamples[index];
    setValue('prompt', prompt, { shouldValidate: true });

    setFocus('prompt');
  };

  const handleGenerate = async () => {
    const inputValue = getValues('prompt')?.trim();
    const currentPrompt = promptExamples[currentIndex];

    if (inputValue !== currentPrompt) {
      toast({
        description: 'This feature is only available to paid users.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      dispatch(
        updateModal({
          type: ModalType.sceneAI,
          data: {
            prompt: inputValue,
          },
        })
      );
      setIsGenerating(true);
    }
  };

  return (
    <>
      {isGenerating && <ModalCloseButton />}
      <ModalBody px={2} py={0}>
        {isGenerating ? (
          <PromptGeneration />
        ) : (
          <Textarea
            placeholder="Describe your dream 3D asset or explore our examples"
            minRows={4}
            rows={4}
            minH="unset"
            overflow="hidden"
            height="fit-content"
            as={ResizeTextarea}
            size="xs"
            variant="unstyled"
            bgColor="none"
            fontSize={16}
            resize="none"
            colorScheme="blue"
            {...register('prompt')}
          />
        )}
      </ModalBody>
      <ModalFooter gap={4} mt={2} p={0} pt={2}>
        {isGenerating ? (
          <Center w="full">
            <Button size="sm" rounded="xl" px={3} variant="outline">
              Retry
            </Button>
          </Center>
        ) : (
          <>
            <Button
              variant="ghost"
              leftIcon={<FaArrowRotateRight />}
              colorScheme="yellow"
              size="xs"
              onClick={onTryExample}
            >
              Try an example
            </Button>
            <Button
              colorScheme="blue"
              size="xs"
              rounded="xl"
              px={3}
              onClick={handleSubmit(handleGenerate)}
              isDisabled={!isValid}
              loadingText="Generating..."
            >
              Generate
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default SceneAI;
