import { Box, Stack, Text, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { deleteCommentAPI, getAllVoiceInstancesAPI } from 'src/apis';
import store from 'src/store/store';
import Comment from '../Comments/Comment';
import { CommentType, CommentsType, DUMMY_NODE } from 'src/types';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { appendComments, deleteComment, setSelectedComment } from 'src/store/reducers/comments';
import { sortByTimestamp } from 'src/utils/helper';

const VoiceNotes = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const comments = useAppSelector((store) => store.comments.ids).filter(
    (id) => id !== DUMMY_NODE && !store.getState().comments.entities[id].parent_id
  );

  useEffect(() => {
    const projectId = store.getState().app.projectId;
    const sceneId = store.getState().instance.current_sceneId;

    if (sceneId && projectId) {
      getAllVoiceInstancesAPI(projectId, sceneId).then((res: any) => {
        dispatch(appendComments(res));
      });
    }
  }, []);

  const handleOnClick = (id: string) => {
    dispatch(setSelectedComment(id));
  };

  const handleOnDelete = async (id: string) => {
    try {
      dispatch(deleteComment(id));
      await deleteCommentAPI(id, CommentsType.scene);
    } catch (error) {
      toast({
        description: 'Failed to delete comment',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack>
      <Box
        userSelect="none"
        py={2}
        alignSelf="center"
        fontSize="sm"
        color="gray.600"
        borderRadius="md"
      >
        Comments
      </Box>
      <Stack gap={4} flexDirection="column" px={1}>
        {comments
          .sort((a, b) => {
            const aTime = store.getState().comments.entities[a].created_at;
            const bTime = store.getState().comments.entities[b].created_at;

            return sortByTimestamp(aTime, bTime);
          })
          .map((id: string) => {
            const type = store.getState().comments.entities[id].comment
              ? CommentType.text
              : CommentType.voice;
            return (
              <Comment
                key={id}
                id={id}
                type={type}
                onClick={handleOnClick}
                onDelete={handleOnDelete}
              />
            );
          })}
        {!comments?.length && (
          <Text userSelect="none" color="gray.400" textAlign="center" fontSize="sm" mt={2}>
            No comments yet
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export default VoiceNotes;
