import { supabase } from 'src/SupabaseClient';
import { Tables } from 'src/types';
import { TABLES } from 'src/utils/constants';

export const getAllProjectsAPI = async (): Promise<any> => {
  const { data } = await supabase
    .from(TABLES.projects)
    .select('*')
    .order('created_at', { ascending: false });
  return data;
};

export const getProjectAPI = async (projectId: string) => {
  const { data } = await supabase.from(TABLES.projects).select('*').eq('id', projectId);
  return data;
};

export const createProjectAPI = async (name: any) => {
  const { data, error } = await supabase
    .rpc('create_new_project', {
      project_name: name,
    })
    .single();

  return { data, error };
};

export const deleteProjectAPI = async (projectId: string) => {
  await supabase.from(TABLES.projects).delete().eq('id', projectId);
};

export const updateProjectAPI = async (projectId: string, data: Partial<Tables<'projects'>>) => {
  await supabase.from(TABLES.projects).update(data).eq('id', projectId);
};

export const updateRoleOfUser = async (projectId: string, userId: string, role: string) => {
  const { data, error } = await supabase
    .from(TABLES.project_users)
    .update({ role })
    .eq('project_id', projectId)
    .eq('user_id', userId);

  return { data, error };
};

export const getInvitedUsers = async (projectId: string) => {
  const { data, error } = await supabase
    .from(TABLES.invites)
    .select('*')
    .eq('project_id', projectId)
    .neq('invite_status', 'accepted');

  if (data) {
    const invitedUsers = data.map((invite: any) => {
      return {
        email: invite.email,
        role: invite.role,
        inviteBy: invite.invited_by,
      };
    });

    return invitedUsers;
  }

  return { error };
};
