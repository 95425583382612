import { useCallback, useEffect, useMemo } from 'react';
import useScreenToFlowPositionWithoutSnapping from 'src/features/StoryBoard/hooks/useScreenPositionFlow';
import store from 'src/store/store';
import { useAppDispatch, useAppSelector } from '../../../store/reducers/hook';
import { BROADCAST_EVENTS, Channels } from '../../../types';
import { throttle } from 'src/utils/throttle';
import { updateOnlineUser } from 'src/store/reducers/collaboration';
import { ChannelManager } from 'src/utils/Channel';

const MAX_IDLE_TIME = 10000;

export type Cursor = {
  id: string;
  color: string;
  x: number;
  y: number;
  timestamp: number;
};

const useCursors = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector((store) => store.collaboration.storyboard);

  const cursorsWithoutSelf = useMemo(
    () => Object.values(users).filter(({ id }) => id !== store.getState().app.currentUser?.id),
    [users]
  );

  const sendCursorPosition = (position: { x: number; y: number }) => {
    const projectId = store.getState().app.projectId;
    const currentUser = store.getState().app.currentUser;
    const enabled = store.getState().collaboration.enabled;

    if (enabled && currentUser && projectId) {
      ChannelManager.sendToChannel(Channels.sync, BROADCAST_EVENTS.cursor, {
        id: currentUser.id,
        name: currentUser.fullname,
        cursor: {
          position,
          timestamp: Date.now(),
        },
      });
    }
  };

  // Flush any cursors that have gone stale.
  const flush = useCallback(() => {
    const now = Date.now();
    const usersOnStoryboard = Object.values(store.getState().collaboration.storyboard);

    for (const { id, cursor } of usersOnStoryboard) {
      if (now - cursor.timestamp > MAX_IDLE_TIME) {
        dispatch(
          updateOnlineUser({ userId: id, data: { cursor: { position: null, timestamp: null } } })
        );
      }
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(flush, MAX_IDLE_TIME);

    flush();
    return () => {
      clearInterval(timer);
    };
  }, [flush]);

  return { cursorsWithoutSelf, sendCursorPosition };
};

export function useStoryboardCursor() {
  const { cursorsWithoutSelf, sendCursorPosition } = useCursors();
  const screenToFlowPosition = useScreenToFlowPositionWithoutSnapping();

  const onMouseMove = useCallback(
    throttle((event: MouseEvent) => {
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      sendCursorPosition(position);
    }, 100),
    [screenToFlowPosition]
  );

  return [cursorsWithoutSelf, onMouseMove] as const;
}

export default useStoryboardCursor;
