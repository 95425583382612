import { useAppDispatch } from 'src/store/reducers/hook';
import {
  updateBBoxInfo,
  updateGizmoInfo,
  updateRefTrasforms,
} from 'src/store/reducers/sceneViewer';
import * as TYPES from 'src/types';

export const useSceneInteractions = () => {
  const dispatch = useAppDispatch();

  const onGizmoUpdate = (changes: Partial<TYPES.gizmoInfoInterface>) => {
    dispatch(
      updateGizmoInfo({
        changes: { ...changes },
      })
    );
  };

  const onBBoxUpdate = (changes: Partial<TYPES.bboxInfoInterface>) => {
    dispatch(
      updateBBoxInfo({
        changes: { ...changes },
      })
    );
  };

  const onRefTransformUpdate = (changes: Partial<TYPES.refTransformsInterface>) => {
    dispatch(
      updateRefTrasforms({
        changes: { ...changes },
      })
    );
  };

  return {
    onGizmoUpdate,
    onBBoxUpdate,
    onRefTransformUpdate,
  };
};
