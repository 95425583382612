import { Box } from '@chakra-ui/react';
import { RiText } from 'react-icons/ri';
import { useMouseAnchorHandler } from '../../hooks/useKeyboardListener';

type TextAnchorProps = {};

const TextAnchor = (props: TextAnchorProps) => {
  const { ref } = useMouseAnchorHandler();

  return (
    <Box ref={ref} p={4} borderRadius={20} color="white" className="cursor-anchor">
      <RiText size={24} />
    </Box>
  );
};

export default TextAnchor;
