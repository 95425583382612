import { createSlice } from '@reduxjs/toolkit';

type CachedItem<T> = {
  value: T;
  timestamp: number;
};

type LruCacheState<T> = { items: Record<string, CachedItem<T>>; flushedAt?: number };

const createTimestamp = (): number => new Date().getTime();

const CAPACITY = 4;

const removeLRUItem = <T>(state: LruCacheState<T>): void => {
  let leastRecentKey: string | undefined;
  let leastRecentTimestamp = Number.MAX_VALUE;

  Object.keys(state).forEach((key) => {
    const timestamp = state.items[key].timestamp;
    if (timestamp < leastRecentTimestamp) {
      leastRecentKey = key;
      leastRecentTimestamp = timestamp;
    }
  });

  if (leastRecentKey) {
    delete state.items[leastRecentKey];
  }
};

const initialState: LruCacheState<any> = {
  items: {},
  flushedAt: undefined,
};

// * LRU Cache Slice
const cache = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { key, value } = action.payload;

      if (state.items[key]) {
        state.items[key].value = value;
        state.items[key].timestamp = createTimestamp();
      } else {
        state.items[key] = { value, timestamp: createTimestamp() };

        // Remove LRU item if capacity is reached
        if (Object.keys(state).length > CAPACITY) {
          removeLRUItem(state);
        }
      }
    },
    removeItem: (state, { payload: key }) => {
      delete state.items[key];
    },
    resetCache: (state) => {
      return initialState;
    },
  },
});

export const { addItem, removeItem, resetCache } = cache.actions;

export default cache.reducer;
