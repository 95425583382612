import { ViewportObject } from 'src/types';
import * as THREE from 'three';

export const quaternionToEuler = (quternion: [number, number, number, number]) => {
  const eulerRotation = new THREE.Euler();
  eulerRotation.setFromQuaternion(
    new THREE.Quaternion(quternion[0], quternion[1], quternion[2], quternion[3])
  );

  return [eulerRotation.x, eulerRotation.y, eulerRotation.z];
};

export const eulerToQuaternion = (euler: [number, number, number]) => {
  const quaternionRotation = new THREE.Quaternion();
  quaternionRotation.setFromEuler(new THREE.Euler(euler[0], euler[1], euler[2]));

  return [quaternionRotation.x, quaternionRotation.y, quaternionRotation.z, quaternionRotation.w];
};

export const radToDeg = (rad: number) => {
  let deg = (rad * 180) / Math.PI;
  deg = ((((deg + 180) % 360) + 360) % 360) - 180;
  return deg;
};

export const radToDeg_twoPI = (rad: number) => {
  let deg = rad * (180 / Math.PI);
  return deg;
};

export const degToRad = (deg: number) => {
  return (deg * Math.PI) / 180;
};

export const sortByTimestamp = (aTime: string, bTime: string) => {
  return new Date(bTime).getTime() - new Date(aTime).getTime();
};

export const getRandomNumber = (min: number, max: number): number => {
  if (max < min) {
    throw new Error('Maximum value cannot be less than minimum value.');
  }

  const range = max - min + 1;

  const randomValue = Math.random() * range;

  return Math.floor(randomValue) + min;
};

export const generateNewViewportLabel = (viewportInstances: ViewportObject[]) => {
  let viewportNum = viewportInstances.length === 0 ? 'primary' : viewportInstances.length;

  if (viewportNum !== 'primary') {
    while (
      viewportInstances.findIndex(
        (item) => item.backendProperties.metadata.label === 'viewport-' + viewportNum.toString()
      ) !== -1
    ) {
      (viewportNum as number) = (viewportNum as number) + 1;
    }
    viewportNum = viewportNum.toString();
  }
  const label = 'viewport-' + viewportNum;
  return label;
};

export const applyProportionalScaling = (
  oldScale: [number, number, number],
  newScale: [number, number, number]
) => {
  const scaleChange = [
    newScale[0] / oldScale[0],
    newScale[1] / oldScale[1],
    newScale[2] / oldScale[2],
  ];
  const scaleDiffX = Math.abs(scaleChange[0] - 1.0);
  const scaleDiffY = Math.abs(scaleChange[1] - 1.0);
  const scaleDiffZ = Math.abs(scaleChange[2] - 1.0);
  if (scaleDiffX > scaleDiffY && scaleDiffX > scaleDiffZ) {
    newScale[1] = newScale[1] * scaleChange[0];
    newScale[2] = newScale[2] * scaleChange[0];
  } else if (scaleDiffY > scaleDiffX && scaleDiffY > scaleDiffZ) {
    newScale[0] = newScale[0] * scaleChange[1];
    newScale[2] = newScale[2] * scaleChange[1];
  } else {
    newScale[0] = newScale[0] * scaleChange[2];
    newScale[1] = newScale[1] * scaleChange[2];
  }

  return newScale;
};

export const getTransformationMatrix = (
  position: [number, number, number],
  rotation: [number, number, number],
  scale: [number, number, number]
) => {
  return new THREE.Matrix4()
    .compose(
      new THREE.Vector3(position[0], position[1], position[2]),
      new THREE.Quaternion().setFromEuler(new THREE.Euler(rotation[0], rotation[1], rotation[2])),
      new THREE.Vector3(scale[0], scale[1], scale[2])
    )
    .clone();
};

export const getPRS = (mat: THREE.Matrix4) => {
  const p = new THREE.Vector3();
  const r = new THREE.Quaternion();
  const s = new THREE.Vector3();

  mat.decompose(p, r, s);
  return {
    position: [p.x, p.y, p.z],
    rotation: quaternionToEuler([r.x, r.y, r.z, r.w]),
    scale: [s.x, s.y, s.z],
  };
};

export function excludeKeys(obj: Record<string, any>, keysToExclude: string[]) {
  const newObj = {} as Record<string, any>;

  Object.keys(obj).forEach((key) => {
    if (!keysToExclude.includes(key)) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
}
