import { Box } from '@chakra-ui/react';
import { RiChat3Line } from 'react-icons/ri';
import { useMouseAnchorHandler } from '../../hooks/useKeyboardListener';

const CommentAnchor = () => {
  const { ref } = useMouseAnchorHandler();

  return (
    <Box ref={ref} p={4} borderRadius={20} color="white" className="cursor-anchor">
      <RiChat3Line size={24} />
    </Box>
  );
};

export default CommentAnchor;
