import { Transform, XYPosition, useStoreApi } from 'reactflow';

function useScreenToFlowPositionWithoutSnapping() {
  const store = useStoreApi();

  return (position: XYPosition) => {
    const { transform, domNode } = store.getState();

    if (!domNode) {
      return position;
    }

    const { x: domX, y: domY } = domNode.getBoundingClientRect();

    const correctedPosition = {
      x: position.x - domX,
      y: position.y - domY,
    };

    const pointToRendererPoint = (
      { x, y }: XYPosition,
      [tx, ty, tScale]: Transform
    ): XYPosition => {
      const position: XYPosition = {
        x: (x - tx) / tScale,
        y: (y - ty) / tScale,
      };

      return position;
    };

    return pointToRendererPoint(correctedPosition, transform);
  };
}

export default useScreenToFlowPositionWithoutSnapping;
