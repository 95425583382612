import { getControllerModel } from 'src/utils/controllers';
import './HandPropertyList.css';
import { Select } from '@chakra-ui/react';
import { SupportedControllerModels } from 'src/types';

export const HandPropertyList = (props: {
  parentController: 'left' | 'right';
  action: string;
  type: SupportedControllerModels;
  handleHandStateChange: Function;
}) => {
  const handModel = getControllerModel(props.type);
  const optionsList = Array.from(Object.keys(handModel[props.parentController].info));

  return (
    <div className="handProperties">
      <div className="handProperties-heading">{props.parentController}</div>
      <div className="handProperties-controls">
        <Select
          backdropBlur="8px"
          color="white"
          bg="rgba(18, 17, 23, 0.6)"
          w="calc(100% - 20px)"
          h="25px"
          display="inline-block"
          fontSize="12px"
          margin="10px 10px"
          variant="filled"
          onChange={(event) =>
            props.handleHandStateChange(props.parentController, event.target.value)
          }
          value={props.action}
          sx={{
            '> option': {
              background: 'black',
              color: 'white',
              backdropBlur: '8px',
            },
            ':hover': {
              backdropBlur: '8px',
              color: 'white',
              bg: 'rgba(18, 17, 23, 0.6)',
            },
          }}
        >
          {optionsList.map((action) => {
            return <option value={action}>{action}</option>;
          })}
        </Select>
      </div>
    </div>
  );
};
