import { Edge, EdgeChange, Node, NodeChange } from 'reactflow';
import { useCanvas } from './useCanvas';
import { useDebouncedCallback } from 'use-debounce';
import { canvasEventHander } from '../events';
import {
  createEdgeAPI,
  createEdgesAPI,
  createNodeAPI,
  deleteEdgeAPI,
  deleteNodeAPI,
  duplicateSceneInstancesAPI,
  updateNodeAPI,
} from 'src/apis';
import store from 'src/store/store';
import useUndoRedo from 'src/hooks/useUndoRedo';
import { findRightwardPath } from 'src/utils/storyboard';
import { NodeCreationType, BROADCAST_EVENTS, Channels, SupportedNodeTypes } from 'src/types';
import { getEdgeId, getUUID } from 'src/utils/ids';
import { copyFileInS3, getS3SceneImageKey } from 'src/utils/aws';
import { useCache } from 'src/hooks/useCache';
import { setSceneId } from 'src/store/reducers/InstanceReducer';
import { ROUTES } from 'src/utils/constants';
import { useSceneViewer } from 'src/components/sceneViewer/hooks/useSceneViewer';
import { deleteComment } from 'src/store/reducers/comments';
import { ChannelManager } from 'src/utils/Channel';
import { createStorylineAPI, deleteStorylineAPI } from 'src/apis/storyline';

export const useStoryboard = () => {
  const { cacheItem } = useCache();
  const { execute, pause, resume, update } = useUndoRedo();
  const {
    onNodesUpdate,
    onAddNode,
    onDeleteNode,
    onEdgesUpdate,
    onNodeUpdate,
    onAddEdge,
    onEdgeDelete,
    onEdgesDelete,
    updateStorylineOfChildren,
    onNodesDelete,
  } = useCanvas();

  const { onCreateViewport } = useSceneViewer();

  const debouncedEventHandler = useDebouncedCallback((change, callback) => {
    const data = canvasEventHander(change);
    if (data) {
      callback(data.node);
    }
  }, 600);

  // * Creates a new node on the server
  const createNode = async (data: Partial<any>, creationType?: NodeCreationType) => {
    const projectId = store.getState().app.projectId;
    const { id: nodeId, ...nodeData } = data;

    const node = await createNodeAPI({
      ...nodeData,
      ...(!creationType && nodeId && { id: nodeId }),
      project_id: projectId,
    });

    if (node) {
      switch (creationType) {
        case NodeCreationType.duplicate:
          if (!nodeId) throw new Error('Node id is required for duplicate operation');

          if (nodeData.type === SupportedNodeTypes.scene) {
            const { key: sourceKey, url: sourceUrl } = getS3SceneImageKey({
              projectId,
              sceneId: nodeId,
            });

            cacheItem(node.id, sourceUrl);

            const { key: destKey } = getS3SceneImageKey({
              projectId,
              sceneId: node.id,
            });

            try {
              copyFileInS3(String(process.env.REACT_APP_S3_BUCKET), sourceKey, destKey);
            } catch (err) {
              console.error('Unable to copy scene image', err);
            }
            duplicateSceneInstancesAPI(nodeId, node.id);
          }
          break;
        case NodeCreationType.new:
        default:
          if (nodeData.type === SupportedNodeTypes.scene) {
            onCreateViewport({
              scene_id: node.id,
              metadata: {
                label: 'viewport-primary',
              },
            });
          }
          break;
      }
    }

    return {
      ...node,
      type: nodeData.type,
    };
  };

  // * After new node is created, add it to the storyboard state
  const onCreateNode = (node: Node, historyEnabled = true) => {
    onAddNode({
      ...node,
      selected: true,
    });

    if (!historyEnabled) return;

    execute({
      type: 'create-node',
      executeFn: () => {
        const newNode = { ...node, type: node.type };
        onCreateNode(newNode, false);
        createNode(newNode);
      },
      undoFn: () => {
        onDeleteNode(node.id);
        deleteNodeAPI(node.id, node.type as SupportedNodeTypes);
      },
    });
  };

  const handleCreateNode = async (nodeData: Partial<Node & { storyline?: string }>) => {
    if (nodeData.type === SupportedNodeTypes.scene) {
      const storyline = await createStoryline();
      nodeData.storyline = storyline;
    }

    const node = await createNode(nodeData, NodeCreationType.new);

    if (node) {
      onNodeUpdate(node.id, { selected: false });
      onCreateNode(node);
    }

    return node;
  };

  const handleUpdateNode = (changes: NodeChange[]) => {
    onNodesUpdate(changes);

    if (store.getState().history.enabled) {
      const event = canvasEventHander(changes);
      if (event) {
        const data = event.node as any;

        execute({
          type: event.type,
          executeFn: () => {},
          undoFn: () => {
            onNodeUpdate(data.id, data);
            updateNodeAPI(data);
          },
        });

        pause();

        debouncedEventHandler(changes, (node: any) => {
          updateNodeAPI(node).then(() => {
            const id = store.getState().history.pausedAt;

            if (id) {
              resume();
              update({
                id,
                executeFn: () => {
                  onNodeUpdate(node.id, node);
                  updateNodeAPI(node);
                },
              });
            }
          });
        });
      }
    }
  };

  const handleNodesDelete = async (nodes: Node[]) => {
    if (nodes?.length) {
      nodes.forEach((node: any) => {
        onDeleteNode(node.id);

        if (node.type === SupportedNodeTypes.scene) {
          const storylineExists = store
            .getState()
            .storyboard.nodes.some((n: any) => n.storyline === node.storyline);
          if (!storylineExists) deleteStorylineAPI(node.project_id, node.storyline);
        }

        if (node.type === SupportedNodeTypes.comment) store.dispatch(deleteComment(node.id));
      });

      onNodesDelete(nodes, () => {});
    }
  };

  // STORYLINE: If no target node,
  const handleCreateScene = async (id: string, creationType = NodeCreationType.new) => {
    const projectId = store.getState().app.projectId;
    const sourceNode = store.getState().storyboard.nodes.find((node) => node.id === id) as any;

    if (!sourceNode) return;

    const position = {
      x: sourceNode.position.x + 240,
      y: sourceNode.position.y,
    };

    const nodeData =
      creationType === NodeCreationType.new
        ? { data: { title: '' } }
        : { data: sourceNode.data, style: sourceNode.style };

    const newNode = await createNode(
      {
        id,
        ...nodeData,
        type: sourceNode.type,
        position,
        storyline: sourceNode.storyline,
        project_id: projectId,
      },
      creationType
    );

    if (sourceNode.type === SupportedNodeTypes.scene) {
      let edgesToCreate = [] as any;

      const sourceEdge = {
        id: getEdgeId(),
        source: sourceNode.id,
        target: newNode.id,
      };

      edgesToCreate.push(sourceEdge);

      const rightNodes = findRightwardPath(sourceNode, []);
      const targetNode = rightNodes[0];

      if (targetNode) {
        const targetEdge = {
          id: getEdgeId(),
          source: newNode.id,
          target: targetNode.id,
        };

        edgesToCreate.push(targetEdge);
      }

      edgesToCreate = await createEdgesAPI(projectId, edgesToCreate);
      onAddEdge(edgesToCreate);

      if (targetNode) {
        const edge = store
          .getState()
          .storyboard.edges.find((ed: any) => ed.source === sourceNode.id);

        if (edge) {
          deleteEdgeAPI(edge.id);
          onEdgeDelete(edge.id);
        }
      }
    }

    if (newNode) {
      if (window.location.pathname === ROUTES.editor) {
        store.dispatch(setSceneId(newNode.id));
        const enabled = store.getState().collaboration.enabled;

        if (enabled) {
          ChannelManager.sendToChannel(Channels.heads, BROADCAST_EVENTS.scene_change, {
            userId: store.getState().app.currentUser?.id,
            sceneId: newNode.id,
          });
        }
      }

      onNodeUpdate(id, { selected: false });
      onCreateNode(newNode);
    }
  };

  const handleUpdateEdge = (changes: EdgeChange[]) => {
    onEdgesUpdate(changes);
  };

  const onEdgeConnect = (edge: Edge) => {
    const projectId = store.getState().app.projectId;

    createEdgeAPI(projectId, edge).then((res: any) => {
      if (res) {
        onAddEdge(res);
        const sourceNode = store
          .getState()
          .storyboard.nodes.find((node) => node.id === edge.source) as any;

        const restNodes = updateStorylineOfChildren(sourceNode);

        execute({
          type: 'add-edge',
          executeFn: () => {
            onAddEdge(res);
            createEdgeAPI(projectId, res, res.id);
            updateStorylineOfChildren(sourceNode);
          },
          undoFn: () => {
            const id = res.id;
            onEdgeDelete(id);
            deleteEdgeAPI(id);
            updateStorylineOfChildren(sourceNode, restNodes);
          },
        });
      }
    });
  };

  const createStoryline = async () => {
    const projectId = store.getState().app.projectId;
    const storyline = getUUID();

    await createStorylineAPI(projectId, storyline);

    return storyline;
  };

  const handleEdgesDelete = (edges: Edge[]) => {
    const lastEdge = edges.at(-1);
    const id = lastEdge?.source;
    updateStorylineOfChildren({ id });

    onEdgesDelete(edges).then((res) => {
      if (res) {
        execute({
          type: 'delete-edge',
          executeFn: () => {
            onEdgesDelete(edges);
          },
          undoFn: () => {
            const projectId = store.getState().app.projectId;
            createEdgesAPI(projectId, edges);
            onAddEdge(edges);
          },
        });
      }
    });
  };

  return {
    handleUpdateNode,
    handleNodesDelete,
    handleUpdateEdge,
    onEdgeConnect,
    handleEdgesDelete,
    handleCreateScene,
    handleCreateNode,
  };
};
