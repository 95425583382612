import { useProgress } from '@react-three/drei';
import { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { SupportedSceneObjectTypes } from 'src/types';
import { context } from './context';
import { useSceneViewer } from './hooks/useSceneViewer';
import LoadingScreen from './LoadingScreen';

const LoadingContainer = () => {
  const [hideLoading, setHideLoading] = useState<boolean>(false);

  const { progress } = useProgress();
  const { takeScreenshot } = useContext(context);
  const { setCameraFromCurrentViewport, disableLoading } = useSceneViewer();

  const following = useAppSelector((store) => store.collaboration.following);
  const showLoading = useAppSelector((store) => store.sceneViewer.showLoading);
  const firstFetchDone = useAppSelector((store) => store.sceneViewer.firstFetchDone);
  const sceneId = useAppSelector((store) => store.instance.current_sceneId);

  useEffect(() => {
    const isSceneFetchedAlready = firstFetchDone[sceneId];
    if (isSceneFetchedAlready && progress === 100) {
      setCameraFromCurrentViewport();
    } else if (showLoading && isSceneFetchedAlready) {
      const objectList = store
        .getState()
        .sceneViewer.sceneObjectList.filter(
          (item) => item.type !== SupportedSceneObjectTypes.viewport
        );

      if (objectList.length === 0) {
        console.log('No objects in scene.');
        setCameraFromCurrentViewport();
        disableLoading();
      }
    }
  }, [showLoading, firstFetchDone]);

  useEffect(() => {
    if (!following && !showLoading) {
      console.log('Following is null, setting camera from current viewport.');
      setCameraFromCurrentViewport();
    }
  }, [following]);

  // Loading Screen handler
  useEffect(() => {
    const isSceneFetchedAlready = firstFetchDone[sceneId];

    if (progress === 100 && showLoading && isSceneFetchedAlready) {
      disableLoading();
      takeScreenshot(false);
    }
  }, [progress, showLoading, firstFetchDone]);

  useEffect(() => {
    setHideLoading(false);
  }, [sceneId]);

  if (!showLoading || hideLoading || firstFetchDone[sceneId]) return null;

  return <LoadingScreen progress={progress} onClose={() => setHideLoading(true)} />;
};

export default LoadingContainer;
