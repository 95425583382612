import { createSlice } from '@reduxjs/toolkit';
import type { User } from 'src/types';

type UserID = string;

const initialState = {
  ids: [] as Array<UserID>,
  entities: {} as Record<UserID, User>,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, { payload: users }) {
      state.ids = users.map((user: any) => user.id);
      state.entities = {
        ...state.entities,
        ...users.reduce((acc: Record<string, User>, user: any) => {
          const { id, email, fullname, username } = user;
          acc[user.id] = { id, email, fullname, username };
          return acc;
        }, {}),
      };
    },
    addUser(state, { payload: user }) {
      state.ids.push(user.id);
      state.entities[user.id] = user;
    },

    clearUsers() {
      return initialState;
    },
  },
});

export const { setUsers, addUser, clearUsers } = userSlice.actions;

export default userSlice.reducer;
