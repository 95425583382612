import { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import './SceneViewer.css';
import { context, RightClickMenuItem, sceneRefInterface } from './context';
import { useDispatch } from 'react-redux';
import MainPanel from './MainPanel';
import store from 'src/store/store';
import { SupportedSceneObjectTypes, ViewportObject, HeadObject } from 'src/types';
import { setComments } from 'src/store/reducers/comments';
import { useSceneCollaboration } from '../../features/Collaboration/hooks/useSceneCollaboration';
import { useCache } from 'src/hooks/useCache';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from './hooks/useSceneViewer';
import { getSceneObject, getWorldTransform } from './helpers';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useFloatingHeads } from 'src/features/Collaboration/hooks/useFloatingHeads';
import { stringToColor } from 'src/utils/colors';
import LoadingContainer from './LoadingContainer';
import useSceneViewerShortcuts from './hooks/useSceneViewerShortcuts';

export default function SceneViewer({ children }: any) {
  const containerRef = useRef();
  const viewportSceneRef = useRef<sceneRefInterface>(null);
  const [snapToGround, setSnapToGround] = useState<boolean>(false);
  const [multiSelectConstrainProportions, setMultiSelectConstrainProportions] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const { cacheItem } = useCache();
  const { uploadSceneThumbnail } = useAssetUpload();
  const { getSceneObjectHandler, onCameraConfigUpdate, onSelectObject } = useSceneViewer();

  const sceneId = useAppSelector((store) => store.instance.current_sceneId);
  const following = useAppSelector((store) => store.collaboration.following);

  // Fetch all instances from Supabase on load
  useEffect(() => {
    //TODO: Refactor SceneViewer to use Redux
    dispatch(setComments([]));
    onSelectObject([]);
  }, [sceneId]);

  const groupInstanceHandler = getSceneObjectHandler();

  // * Keyboard Shortcuts
  useSceneViewerShortcuts();
  useFloatingHeads(onCameraConfigUpdate);
  useSceneCollaboration({
    groupInstanceHandler,
  });

  // Callback on delete key press.
  // const handleDeleteAction = () => {
  //   let primaryViewportIndex = -1;
  //   setSelectedObjects((prevSelectedObjects) => {
  //     prevSelectedObjects.map((selectedItem, selectedIndex) => {
  //       if (selectedItem.type === SupportedSceneObjectTypes.viewport) {
  //         const viewportObject = getSceneObject(
  //           SupportedSceneObjectTypes.viewport,
  //           selectedItem.id
  //         ) as ViewportObject;
  //         if (viewportObject) {
  //           if (viewportObject.localProperties.isPrimary) {
  //             primaryViewportIndex = selectedIndex;
  //           }
  //         }
  //       }
  //     });
  //     return [...prevSelectedObjects];
  //   });

  //   let primaryViewportId = null as null | string;
  //   let primaryViewportObject = getPrimaryViewport();
  //   if (primaryViewportObject) {
  //     primaryViewportId = primaryViewportObject.id;
  //   }

  //   const prevViewport = store.getState().sceneViewer.currentViewport;
  //   setSelectedObjects((prevSelectedObjects) => {
  //     let newViewport = prevViewport as string | null;
  //     prevSelectedObjects.map((selectedItem, selectedIndex) => {
  //       if (selectedItem.type === SupportedSceneObjectTypes.viewport) {
  //         if (selectedItem.id === prevViewport) {
  //           newViewport = primaryViewportId;
  //         }
  //       }
  //       if (selectedIndex !== primaryViewportIndex) {
  //         handleSceneObjectAction(SceneObjectActionTypes.delete, [
  //           {
  //             id: selectedItem.id,
  //             type: selectedItem.type,
  //           },
  //         ]);
  //       }
  //     });

  //     onCurrentViewportUpdate(newViewport);
  //     return [];
  //   });
  // };

  const removeGizmoandViewportFromScene = (object: any) => {
    if (!object) return;

    for (let i = object.children.length - 1; i >= 0; i--) {
      let child = object.children[i];
      if (child.name === 'viewport' || child.name === 'gizmo' || child.name === 'ground') {
        object.remove(child);
      } else if (child.type === 'Box3Helper') {
        object.remove(child);
      } else if (child.type === 'Group' || child.type === 'Scene') {
        child = removeGizmoandViewportFromScene(child);
        object.children[i] = child;
      }
    }

    return object;
  };

  // Function to take screenshot from all viewports
  const takeScreenshot = (save = true) => {
    const enabled = store.getState().collaboration.enabled;
    const following = store.getState().collaboration.following;

    if (enabled && following) {
      return;
    }

    const scene_id = store.getState().instance.current_sceneId;
    try {
      const renderer = new THREE.WebGLRenderer();

      let scene = viewportSceneRef.current?.scene.clone();
      scene = removeGizmoandViewportFromScene(scene);

      if (!scene) return;

      const headObjectList = store
        .getState()
        .sceneViewer.sceneObjectList.filter(
          (item) => item.type === SupportedSceneObjectTypes.head
        ) as HeadObject[];

      headObjectList.forEach((item) => {
        const aspect =
          item.backendProperties.metadata.width / item.backendProperties.metadata.height;

        const camera = new THREE.PerspectiveCamera(
          item.backendProperties.metadata.fov,
          aspect,
          0.1,
          1000
        );

        const worldTransform = getWorldTransform(item.id);

        camera.translateX(worldTransform.position[0]);
        camera.translateY(worldTransform.position[1]);
        camera.translateZ(worldTransform.position[2]);

        camera.setRotationFromEuler(
          new THREE.Euler(
            worldTransform.rotation[0],
            worldTransform.rotation[1],
            worldTransform.rotation[2]
          )
        );

        const width = item.backendProperties.metadata.width;
        const height = item.backendProperties.metadata.height;
        renderer.setSize(width, height);

        renderer.render(scene!, camera);

        const canvasBase64 = renderer.domElement.toDataURL('image/png', 0.7);
        cacheItem(scene_id, canvasBase64);

        if (save) {
          const viewportId = item.backendProperties.parent_group_id;

          if (viewportId) {
            const viewportObject = getSceneObject(
              SupportedSceneObjectTypes.viewport,
              item.backendProperties.parent_group_id!
            ) as ViewportObject;

            if (viewportObject)
              uploadSceneThumbnail(
                sceneId,
                viewportObject.backendProperties.metadata.label,
                canvasBase64
              );
          } else {
            console.error('Viewport not found for head object');
          }
        }

        renderer.clear();
      });

      renderer.domElement.remove();
      renderer.forceContextLoss();
      renderer.dispose();
    } catch (err) {
      console.error('Error in taking screenshot:', err);
    }
  };

  return (
    <context.Provider
      value={{
        snapToGround,
        setSnapToGround,
        multiSelectConstrainProportions,
        setMultiSelectConstrainProportions,
        takeScreenshot,
      }}
    >
      <div
        style={{
          transition: 'background-color 0.5s',
          background: following ? stringToColor(following) : 'transparent',
        }}
        className="sceneViewer"
        ref={containerRef as any}
      >
        <LoadingContainer />
        <MainPanel isFollowing={following} ref={viewportSceneRef} />
        {children}
      </div>
    </context.Provider>
  );
}
