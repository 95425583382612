import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { ModalType, SupportedNodeTypes } from 'src/types';
import { closeModal } from 'src/store/reducers/modals';
import { useCanvas } from 'src/features/StoryBoard/hooks/useCanvas';
import store from 'src/store/store';
import { useState } from 'react';
import { deleteComment } from 'src/store/reducers/comments';

const DeleteNodeModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { onDeleteNode, onNodesDelete } = useCanvas();
  const modal = useAppSelector((store) => store.modal.active[ModalType.delete]);

  const onClose = () => {
    dispatch(closeModal(ModalType.delete));
  };

  const onDelete = () => {
    const data = modal?.data;
    if (!data) return;

    const existingNodes = store.getState().storyboard.nodes;

    const nodes = existingNodes.filter((node) =>
      data.ids ? data.ids.includes(node.id) : node.id === data.id
    );

    if (nodes?.length) {
      setIsLoading(true);
      nodes.forEach((node) => {
        onDeleteNode(node.id);
        if (node.type === SupportedNodeTypes.comment) dispatch(deleteComment(node.id));
      });

      onNodesDelete(nodes, () => {})
        .then(() => {
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const isOpen = !!modal;

  if (!isOpen) return null;

  return (
    <Modal size="2xl" onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" mb={0}>
          You will not be able to undo this action. Are you sure ?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody></ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose}>Cancel</Button>
          <Button tabIndex={1} onClick={onDelete} colorScheme="red" isLoading={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteNodeModal;
