import { AspectRatio, Box, Flex, Image, SimpleGrid } from '@chakra-ui/react';
import { useState } from 'react';
import './Cards.css';
function Cards(props: any) {
  const { clickedAsset, data } = props;
  const [selectedCard, setSelectedCard] = useState();

  return (
    <SimpleGrid
      overflowY="auto"
      marginRight={2}
      columns={{ sm: 2, md: 3 }}
      spacing={4}
      cursor="pointer"
      h="full"
    >
      {data &&
        data.map((asset: any, index: any) => {
          const source = `https://unproject-asset-library-nv.s3.amazonaws.com/assets3DObjects/${asset.category}/${asset.thumbnail}`;

          return (
            <Box
              borderRadius={8}
              minH="fit-content"
              transition={'all .2s ease-in-out'}
              p={2}
              onClick={() => {
                setSelectedCard(asset.id);
                clickedAsset(asset);
              }}
              _hover={{ backgroundColor: 'rgba(50,50,50,255)' }}
              key={index}
            >
              <Flex flexDirection="column" align="center" gap={4}>
                <AspectRatio
                  w="full"
                  minH="140px"
                  minW="140px"
                  maxH="13rem"
                  maxW="13rem"
                  ratio={1 / 1}
                  objectFit="cover"
                  objectPosition="center"
                >
                  <Image src={source} borderRadius={8} />
                </AspectRatio>
                <label>{asset.label}</label>
              </Flex>
            </Box>
          );
        })}
    </SimpleGrid>
  );
}

export default Cards;
