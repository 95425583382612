import { SupportedFiles } from 'src/types';

export const getSupportedFiles = (files: FileList) => {
  return Array.from(files).filter((file) => {
    return getFileType(file);
  });
};

export const getFileType = (file: File): keyof typeof SupportedFiles | undefined => {
  if (file.type === '') {
    const typeFromName = file.name.split('.').pop();
    if (typeFromName && SupportedFiles['3D'].includes(typeFromName)) return '3D';
  }

  if (SupportedFiles.images.includes(file.type)) return 'images';
};

export const getFileExtension = (file: File): string => {
  const extension = file.name.split('.').pop();
  return (extension as string).toLowerCase();
}

export const getNameWithoutExtension = (fileName: string): string => {
  const filename_and_extensions = fileName.split('.');
  return filename_and_extensions[filename_and_extensions.length - 2];
}