import * as THREE from 'three'
import { extend, Object3DNode } from '@react-three/fiber'

class BentPlaneGeometry extends THREE.PlaneGeometry {
    constructor(angle: number, ...args: any) {
        super(...args);
        let p = this.parameters;
        let height = p.height;
        let width = p.width;
        let absAngle = Math.abs(angle);
        let ratio = absAngle / (2 * Math.PI);
        if (ratio === 0) {
            return;
        }
        let radius = width / ((2 * Math.PI) * ratio);
        let uv = this.attributes.uv;
        let pos = this.attributes.position;
        let mainV = new THREE.Vector2();
        let bendDirection = angle >= 0 ? 1 : -1; // Determine bend direction based on angle sign
        for (let i = 0; i < uv.count; i++) {
            let uvRatio = 0.5 - uv.getX(i);
            let currentAngle = bendDirection * uvRatio * absAngle;
            let x = 0.0;
            let z = 0.0;
            mainV.set(x, z).rotateAround(new THREE.Vector2(0, radius), currentAngle); // Rotate vertex around the center by current angle
            pos.setXYZ(i, bendDirection * -mainV.x, pos.getY(i), bendDirection * mainV.y); // Set vertex position
        }
        pos.needsUpdate = true;
    }
}


extend({ BentPlaneGeometry });

declare module '@react-three/fiber' {
    interface ThreeElements {
        bentPlaneGeometry: Object3DNode<BentPlaneGeometry, typeof BentPlaneGeometry>
    }
}