import { Figma } from 'src/features/Integrations';

const Integrations = () => {
  return (
    <>
      <Figma />
    </>
  );
};

export default Integrations;
