import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { addIntegrationAPI } from 'src/apis/integrations';
import { getFigmaAccessTokenFromCode } from 'src/services/figma';
import store from 'src/store/store';

export const Figma = () => {
  const [query] = useSearchParams();

  useEffect(() => {
    const code = query.get('code');
    const projectId = store.getState().app.projectId;
    const currentUser = store.getState().app.currentUser?.id;

    if (code && projectId && currentUser) {
      getFigmaAccessTokenFromCode(code)
        .then((res) => {
          const data = {
            auth_data: res,
            project_id: projectId,
            user_id: currentUser,
            auth_type: 'oauth',
            name: 'figma',
          };

          addIntegrationAPI(data)
            .then(() => {
              localStorage.setItem('unproject-figma', JSON.stringify(data));
              window.close();
            })
            .catch((err) => {
              console.error('Figma access token error', err);
            });
        })
        .catch((err) => {
          console.error('Figma access token error', err);
        });
    } else {
      console.error('Figma access token error', 'Missing code, project id or current user id.');
    }
  }, [query]);

  return <></>;
};
