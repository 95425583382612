import { useState } from 'react';

import { Button, Flex, FormControl, Heading, Input, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'src/hooks';

const SignupScheme = z.object({
  fullname: z.string().min(3, 'Enter fullname'),
  email: z.string().email({
    message: 'Must be a valid email',
  }),
  password: z.string().min(1, 'Minimum 1 characters for password'),
});

type SignupSchemeType = z.infer<typeof SignupScheme>;

function SignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<any>('');

  const { signup } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SignupSchemeType>({ resolver: zodResolver(SignupScheme) });

  const handleSignup = async (formData: any) => {
    setIsLoading(true);
    setHasError(null);
    const { error } = await signup(formData);
    if (error) setHasError(error);
    setIsLoading(false);
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit(handleSignup)}>
        <Flex
          backgroundColor="rgba(26,32,44,255)"
          h="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            w="400px"
            position="relative"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="rgba(45,55,72,255)"
            p={12}
            borderRadius={8}
            boxShadow="lg"
          >
            <Heading color="rgba(239,239,241,255)" mb={6}>
              Sign Up
            </Heading>
            <FormControl isInvalid={!!errors.fullname?.message}>
              <Input
                autoFocus
                type="text"
                placeholder="Fullname"
                variant="filled"
                mb={3}
                {...register('fullname')}
              />
            </FormControl>
            <FormControl isInvalid={!!errors.email?.message}>
              <Input
                placeholder="Email"
                type="email"
                variant="filled"
                mb={3}
                {...register('email')}
              />
            </FormControl>
            <FormControl isInvalid={!!errors.password?.message}>
              <Input
                placeholder="Password"
                type="password"
                variant="filled"
                mb={6}
                {...register('password')}
              />
            </FormControl>
            <Button
              type="submit"
              isDisabled={isLoading || !isValid}
              isLoading={isLoading}
              colorScheme="teal"
              mb={8}
            >
              Sign Up
            </Button>
            <Flex>
              <Text color="white">
                Already have an account?{' '}
                <Link to="/" style={{ color: 'rgba(129,230,217,255)' }}>
                  Login
                </Link>
              </Text>
            </Flex>
            {hasError && (
              <Text align="center" position="absolute" bottom={-16} color="red.200" fontSize="sm">
                {hasError?.message}
              </Text>
            )}
          </Flex>
        </Flex>
      </form>
    </div>
  );
}

export default SignUp;
