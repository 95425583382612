import { Center } from '@chakra-ui/react';
import SceneSwitcher from '../SceneSwitcher';

type StoryLineHeaderProps = {
  scenes: any;
  setScenes: any;
};

const StoryLineHeader: React.FC<StoryLineHeaderProps> = ({ scenes, setScenes }) => {
  return (
    <Center w="full" px={6} py={2}>
      <SceneSwitcher scenes={scenes} setScenes={setScenes} />
    </Center>
  );
};

export default StoryLineHeader;
