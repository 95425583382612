import './LoadingScreen.css';
import { IconButton, Spinner } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

export default function LoadingScreen(props: any) {
  return (
    <div className="loadingScreen">
      <IconButton
        variant="ghost"
        position="absolute"
        top={5}
        onClick={() => props.onClose()}
        right={5}
        aria-label="close"
        icon={<CloseIcon />}
      />
      <p>Loading</p>
      <Spinner thickness="5px" speed="0.70s" color="#fff" size="xl" />
    </div>
  );
}
