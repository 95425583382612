import { Box, Stack } from '@chakra-ui/react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useMemo } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import { SupportedSceneObjectTypes } from 'src/types';
import { getShortId } from 'src/utils/ids';
import { SortableTree } from '../Tree/Tree';
import { useSceneInteractions } from './hooks/useSceneInteractions';
import { useSceneViewer } from './hooks/useSceneViewer';
import PropertyHeading from './propertyPanel/PropertyHeading';

type Node = any;

function buildTree(list: any[]): Node[] {
  const data = JSON.parse(JSON.stringify(list)) as Node[];

  const nodeMap = new Map<string, Node>();

  for (const item of data) {
    nodeMap.set(item.backendProperties.id, {
      id: item.backendProperties.id,
      data: {
        type: item.backendProperties.type,
        name: item.backendProperties.name,
        parent_group_id: item.backendProperties.parent_group_id,
        created_at: item.backendProperties.created_at,
      },
    });
  }

  for (const [_, node] of nodeMap) {
    const parent = nodeMap.get(node.data.parent_group_id);

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(node);
    }
  }

  const rootNodes = data
    .filter((node) => !node.backendProperties.parent_group_id)
    .map((node) => {
      return { ...nodeMap.get(node.id) };
    });

  return rootNodes;
}

const LayersPanel = () => {
  const { onSelectObject } = useSceneViewer();
  const { onGizmoUpdate } = useSceneInteractions();

  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);
  const tree = useAppSelector((store) => store.sceneViewer.sceneObjectList);

  const { key, foreground, background } = useMemo(() => {
    const foreground = [] as Node[];
    const background = [] as Node[];

    const backgroundAssets = new Set();

    tree.forEach((item) => {
      if (item.backendProperties.background) {
        const children = item.localProperties.children;
        if (children) {
          children.forEach((child) => {
            backgroundAssets.add(child.id);
          });
        }
      }
    });

    tree.forEach((item) => {
      if (backgroundAssets.has(item.id) || item.backendProperties.background) {
        background.push(item);
      } else {
        foreground.push(item);
      }
    });

    return {
      key: getShortId(),
      foreground: buildTree(foreground),
      background: buildTree(background),
    };
  }, [tree]);

  const onClick = (id: UniqueIdentifier, type: SupportedSceneObjectTypes) => {
    onGizmoUpdate({ show: [true, true, true] });
    onSelectObject([{ id, type }]);
  };

  return (
    <>
      <Stack className="panel" flex={2} flexShrink={0} px={2} overflow="hidden">
        <PropertyHeading mb={1}>Foreground</PropertyHeading>
        <Box overflowY="scroll">
          <SortableTree
            key={key}
            defaultItems={foreground}
            collapsible
            onClick={onClick}
            activeId={selectedObjects.at(0)?.id}
          />
        </Box>
      </Stack>
      <Stack className="panel" flex={1} flexShrink={0} px={2} overflow="hidden">
        <PropertyHeading mb={1}>Background</PropertyHeading>
        <Box overflowY="scroll">
          <SortableTree key={key} defaultItems={background} collapsible disableInteraction />
        </Box>
      </Stack>
    </>
  );
};

export default LayersPanel;
