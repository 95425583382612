import { memo, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { DUMMY_NODE, SupportedNodeTypes, ToolType } from 'src/types';
import { useCanvas } from '../hooks/useCanvas';
import { selectTool } from 'src/store/reducers/storyboard';
import useScreenToFlowPositionWithoutSnapping from '../hooks/useScreenPositionFlow';
import { useKeyboardListener } from '../hooks/useKeyboardListener';
import { setSelectedComment } from 'src/store/reducers/comments';
import { focusEditableElement } from 'src/utils/inputs';
import { useStoryboard } from '../hooks/useStoryboard';

const InsertNodeContainer = () => {
  const dispatch = useAppDispatch();
  const { onAddNode } = useCanvas();
  const { handleCreateNode } = useStoryboard();

  useKeyboardListener();
  const screenToFlowPosition = useScreenToFlowPositionWithoutSnapping();
  const activeTool = useAppSelector((store) => store.storyboard.tool);

  const handleSelectTool = (tool: ToolType | undefined) => dispatch(selectTool(tool));
  const handleSelectNode = () => dispatch(setSelectedComment(DUMMY_NODE));

  const onCreate = useCallback(
    (tool: ToolType) => (event: MouseEvent) => {
      const position = screenToFlowPosition({
        x: event.pageX,
        y: event.pageY - 20,
      });

      switch (tool) {
        case ToolType.text:
          return handleCreate(SupportedNodeTypes.text, position, {
            title: '',
          });

        case ToolType.scene:
          return handleCreate(SupportedNodeTypes.scene, position, {
            title: '',
          });
        case ToolType.comment: {
          onAddNode({
            id: DUMMY_NODE,
            position,
            type: SupportedNodeTypes.comment,
          });
          handleSelectNode();
          handleSelectTool(undefined);
          break;
        }
        default:
          return;
      }
    },
    []
  );

  useEffect(() => {
    if (activeTool) {
      const handleEvent = onCreate(activeTool);

      setTimeout(() => {
        window.addEventListener('click', handleEvent);
      }, 1);

      return () => {
        window.removeEventListener('click', handleEvent);
      };
    }
  }, [activeTool, onCreate]);

  const handleCreate = async (
    type: SupportedNodeTypes = SupportedNodeTypes.scene,
    position: any,
    data: any,
    focus = true
  ) => {
    try {
      const node = await handleCreateNode({
        data,
        type,
        position,
      });

      if (node) {
        if (focus) {
          setTimeout(() => {
            const el = document.getElementById(node.id);
            if (el) focusEditableElement(el);
          }, 1);
        }

        handleSelectTool(undefined);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return <></>;
};

export default memo(InsertNodeContainer);
