import { ChangeEventHandler, forwardRef, memo } from 'react';
import { Box, Input, Textarea } from '@chakra-ui/react';
import { NodeResizer } from 'reactflow';
import ResizeTextarea from 'react-textarea-autosize';
import { useCanvas } from '../../hooks/useCanvas';
import { useDebouncedCallback } from 'use-debounce';
import { updateNodeAPI } from 'src/apis';
import store from 'src/store/store';
import { useAppSelector } from 'src/store/reducers/hook';
import { ModeType } from 'src/types';
import TextToolbar, { textStyleOptions } from './TextToolbar';
import useUndoRedo from 'src/hooks/useUndoRedo';

export const Text = forwardRef<any, any>(
  ({ defaultValue, name, id, onChange, onBlur, placeholder, ...restInputProps }, ref) => {
    const onChangeHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      // setValue(event.target.value);
      onChange(event);
    };

    return (
      <Textarea
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        ref={ref}
        id={id}
        height="fit-content"
        name={name}
        rows={1}
        as={ResizeTextarea}
        value={defaultValue}
        size="xs"
        variant="unstyled"
        fontSize={16}
        bgColor="none"
        colorScheme="blue"
        onChange={onChangeHandler}
        placeholder={placeholder}
        {...restInputProps}
      />
    );
  }
);

export const TextInput = forwardRef<any, any>(
  ({ defaultValue, name, id, onChange, onBlur, placeholder, ...restInputProps }, ref) => {
    const onChangeHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      onChange(event);
    };

    return (
      <Input
        minH="unset"
        overflow="hidden"
        w="100%"
        ref={ref}
        id={id}
        name={name}
        value={defaultValue}
        size="xs"
        variant="unstyled"
        fontSize={16}
        bgColor="none"
        colorScheme="blue"
        onChange={onChangeHandler}
        placeholder={placeholder}
        {...restInputProps}
      />
    );
  }
);

const TextNode = ({ id, type, position, style, data, selected, ...restPRops }: any) => {
  // State Listeners
  const isInsertMode = useAppSelector((state) => state.storyboard.mode.type === ModeType.insert);

  // Hooks
  const { onNodeUpdate } = useCanvas();
  const { execute, pause, update, resume } = useUndoRedo();

  const updateAPI = (id: string) => {
    const node = store.getState().storyboard.nodes.find((node) => node.id === id);
    if (node) {
      updateNodeAPI(node);

      const id = store.getState().history.pausedAt;
      if (id) {
        resume();
        update({
          id,
          executeFn: () => {
            onNodeUpdate(node.id, node);
            updateNodeAPI(node);
          },
        });
      }
    }
  };

  const debouncedUpdateAPI = useDebouncedCallback(updateAPI, 800);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value, name } = event.target;
    if (data[name] === value) return;

    const undoHistoryEnabled = store.getState().history.enabled;

    if (undoHistoryEnabled) {
      const node = store.getState().storyboard.nodes.find((node) => node.id === id);
      if (node) {
        execute({
          type: 'text-chanage',
          executeFn: () => {},
          undoFn: () => {
            onNodeUpdate(id, { data });
            updateNodeAPI(node);
          },
        });
        pause();
      }
    }

    onNodeUpdate(id, { data: { [name]: value } });
    debouncedUpdateAPI(id);
  };

  const updateNode = (id: string, data: any) => {
    onNodeUpdate(id, { data });
    updateAPI(id);
  };

  const textStyle = data.toolbar?.selected?.reduce((acc: any, key: string) => {
    return { ...acc, ...textStyleOptions[key].style };
  }, {});

  return (
    <Box style={{ pointerEvents: isInsertMode ? 'none' : 'all' }} height="fit-content">
      <NodeResizer color="#6d8fff" isVisible={selected} />
      <TextToolbar id={id} selected={data.toolbar?.selected} onSelect={updateNode} />
      <Text
        id={id}
        color="#eee"
        name="title"
        onChange={onChange}
        defaultValue={data.title}
        placeholder="Title"
        style={textStyle}
        p={2}
      />
    </Box>
  );
};

export default memo(TextNode);
