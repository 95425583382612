import { DeleteIcon } from '@chakra-ui/icons';
import {
  Flex,
  Box,
  Text,
  Heading,
  IconButton,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Stack,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiChat3Line } from 'react-icons/ri';
import { useAppSelector } from 'src/store/reducers/hook';
import { CommentType } from 'src/types';
import timeHelper from 'src/utils/time';
import VoiceNote from '../VoiceNotes/VoiceNote';
import { UserAvatar } from '../../components/Avatar';

type CommentProps = {
  id: string;
  type?: CommentType;
  onClick: (id: string) => void;
  onDelete: (id: string) => void;
};

const Replies = ({ id }: { id: string }) => {
  const replyCommentsLength = useAppSelector(
    (store) =>
      store.comments.ids.filter((commentId) => store.comments.entities[commentId].parent_id === id)
        ?.length
  );

  return (
    <>
      {replyCommentsLength > 0 && (
        <Stack gap={1} mt={2} mb={0} color="gray.500" direction="row" align="center">
          <RiChat3Line size={16} />
          <Text fontSize="xs" m={0}>
            {replyCommentsLength} {replyCommentsLength > 1 ? 'replies' : 'reply'}
          </Text>
        </Stack>
      )}
    </>
  );
};

const Comment = ({ id, onClick, onDelete, type = CommentType.text }: CommentProps) => {
  const comment = useAppSelector((store) => store.comments.entities[id]);
  const user = useAppSelector((store) => store.users.entities[comment?.user_id]);
  const selected = useAppSelector((store) => store.comments.selected) === id;

  if (!comment) return <></>;

  return (
    <Box
      p={3}
      width="100%"
      color="#e4e8f7"
      _hover={{
        bg: '#272b38',
        cursor: 'pointer',
      }}
      bg={selected ? '#272b38' : 'transparent'}
      onClick={(e) => {
        e.stopPropagation();
        onClick(comment.id);
      }}
      borderRadius="lg"
      transition="background-color 0.3s"
    >
      <Flex gap="4">
        <Flex flex="1" gap="4" alignItems="flex-start" flexWrap="wrap" mb={2} userSelect="none">
          <UserAvatar
            id={user?.id}
            color="white"
            name={user?.fullname || user?.username}
            size="sm"
          />
          <Box>
            <Heading size="xs" m={0}>
              {user?.fullname || user?.username}
            </Heading>
            <Text fontSize="xs" m={0} color="gray.500">
              {timeHelper(comment.created_at).fromNow()}
            </Text>
          </Box>
        </Flex>
        {type === CommentType.text && (
          <Menu>
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              as={IconButton}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              aria-label="Options"
              icon={<BsThreeDotsVertical />}
            />
            <MenuList>
              <MenuItem icon={<DeleteIcon />} onClick={() => onDelete(id)}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      {type === CommentType.text ? (
        <Text mb={0} whiteSpace="wrap" fontWeight="normal" fontSize="medium" lineHeight="140%">
          {comment.comment}
        </Text>
      ) : (
        <VoiceNote voiceNote={comment} />
      )}
      <Replies id={comment.id} />
    </Box>
  );
};

export default Comment;
