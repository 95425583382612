import { ControllerSubtype, SupportedControllerModels } from 'src/types';

const controllerModels = {
  [SupportedControllerModels.quest3]: {
    [ControllerSubtype.left]: {
      url: 'controllers/quest3/Quest3ControllerLeft.glb',
      info: {
        body: {
          meshNode: 'left_oculus_controller_mesh',
          material: 'controllerMATphongLT',
        },
        joystick: {
          meshNode: 'left_oculus_controller_mesh003',
          material: 'controllerMATphongLT',
        },
        trigger: {
          meshNode: 'left_oculus_controller_mesh006',
          material: 'controllerMATphongLT',
        },
        grip: {
          meshNode: 'left_oculus_controller_mesh005',
          material: 'controllerMATphongLT',
        },
        menu: {
          meshNode: 'left_oculus_controller_mesh001',
          material: 'controllerMATphongLT',
        },
        x: {
          meshNode: 'left_oculus_controller_mesh002',
          material: 'controllerMATphongLT',
        },
        y: {
          meshNode: 'left_oculus_controller_mesh004',
          material: 'controllerMATphongLT',
        },
      },
      position: [-2.0, 1.3, 0.0] as [number, number, number],
      rotation: [Math.PI / 8.0, Math.PI, Math.PI / 16.0] as [number, number, number],
      scale: [45.0, 45.0, 45.0] as [number, number, number],
      bbox: {
        min: [-0.1365812827349133, -0.3525176858697338, -0.14559446369855888],
        max: [0.14967851744261465, 0.0735636307045396, 0.18766545300255094],
      },
    },
    [ControllerSubtype.right]: {
      url: 'controllers/quest3/Quest3ControllerRight.glb',
      info: {
        body: {
          meshNode: 'right_oculus_controller_mesh',
          material: 'controllerMATphongRT',
        },
        joystick: {
          meshNode: 'right_oculus_controller_mesh005',
          material: 'controllerMATphongRT',
        },
        trigger: {
          meshNode: 'right_oculus_controller_mesh006',
          material: 'controllerMATphongRT',
        },
        grip: {
          meshNode: 'right_oculus_controller_mesh001',
          material: 'controllerMATphongRT',
        },
        menu: {
          meshNode: 'right_oculus_controller_mesh004',
          material: 'controllerMATphongRT',
        },
        x: {
          meshNode: 'right_oculus_controller_mesh002',
          material: 'controllerMATphongRT',
        },
        y: {
          meshNode: 'right_oculus_controller_mesh003',
          material: 'controllerMATphongRT',
        },
      },
      position: [2.0, 1.3, 0.0] as [number, number, number],
      rotation: [Math.PI / 8.0, -Math.PI, -Math.PI / 16.0] as [number, number, number],
      scale: [45.0, 45.0, 45.0] as [number, number, number],
      bbox: {
        min: [-0.1496784786322779, -0.3525176858697338, -0.14559446369855888],
        max: [0.13658311371533105, 0.0735636307045396, 0.18766545300255094],
      },
    },
  },
  [SupportedControllerModels.hands]: {
    [ControllerSubtype.left]: {
      info: {
        CPose: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/CPose_L.glb',
          bbox: {
            //-0.15, -0.2, -0.5
            min: [-0.11619321478201372, -0.2959866070800138, -0.4128893137383187],
            max: [0.12949768500345107, 0.059261538692647275, 0.06316251936343065],
          }, //
        },
        FingerPress: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/FingerPress_L.glb',
          bbox: {
            min: [-0.13022610523447048, -0.2732992917284579, -0.4064702923343724],
            max: [0.13822287343594564, 0.0639077894170611, 0.06811460352503307],
          },
        },
        Fist: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Fist_L.glb',
          bbox: {
            min: [-0.12223999942405361, -0.15803259431376115, -0.3639722355920778],
            max: [0.1890065276868277, 0.06391108607715534, 0.0681212933974038],
          },
        },
        Focused: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Focused_L.glb',
          bbox: {
            min: [-0.08604950766198355, -0.13663024687465064, -0.40802944987067225],
            max: [0.11085306740097967, 0.045145532092539686, 0.04811728831891848],
          },
        },
        Pinch: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Pinch_L.glb',
          bbox: {
            min: [-0.08656560454984447, -0.16235168165729508, -0.41339062757007206],
            max: [0.11385253899330386, 0.04470266218959129, 0.047622441735479804],
          },
        },
        Point: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Point_L.glb',
          bbox: {
            min: [-0.09620887626282304, -0.13625769264976906, -0.44850012458859556],
            max: [0.16865570798790333, 0.050268056078835, 0.05357952404485733],
          },
        },
        Raised: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Raised_L.glb',
          bbox: {
            min: [-0.09113836110129414, -0.1153049549420081, -0.4326565453246314],
            max: [0.19705991106589066, 0.04443271981813279, 0.04735975052962882],
          }, //
        },
        Relaxed: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Relaxed_L.glb',
          bbox: {
            min: [-0.09273621285026223, -0.1273823368158239, -0.42482764032243026],
            max: [0.1925887639171506, 0.04803049665895298, 0.05121252996044934],
          },
        },
        ThumbsUp: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/ThumbsUp_L.glb',
          bbox: {
            min: [-0.09296578017427326, -0.12126221474840626, -0.276225033789685],
            max: [0.2805319355795927, 0.04860493282222944, 0.051806863282974014],
          },
        },
      },
      position: [-2.0, 1.3, 0.0] as [number, number, number],
      rotation: [0.0, Math.PI, 0.0] as [number, number, number],
      scale: [45.0, 45.0, 45.0] as [number, number, number],
    },

    [ControllerSubtype.right]: {
      info: {
        CPose: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/CPose_R.glb',
          bbox: {
            min: [-0.12665987748219587, -0.2893518832200054, -0.40362765911747367],
            max: [0.11346374649661706, 0.05793315445045544, 0.06179001660088357],
          }, //
        },
        FingerPress: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/FingerPress_R.glb',
          bbox: {
            min: [-0.13802932071131746, -0.27276568013581926, -0.405658965327728],
            max: [0.1298352366577154, 0.06378301069159431, 0.06802933191054716],
          },
        },
        Fist: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Fist_R.glb',
          bbox: {
            min: [-0.18012590344577797, -0.1505168752371765, -0.34657783455825975],
            max: [0.11629485126172778, 0.060871600641121515, 0.0649271073285167],
          },
        },
        Focused: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Focused_R.glb',
          bbox: {
            min: [-0.10887229365669371, -0.13411022531760775, -0.4004660093384729],
            max: [0.08437499394977255, 0.04431286330084305, 0.047262956290403224],
          },
        },
        Pinch: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Pinch_R.glb',
          bbox: {
            min: [-0.12732057888794895, -0.18144846966144967, -0.46199954023622336],
            max: [0.09663928194960852, 0.04996086003726108, 0.05326143541604811],
          },
        },
        Point: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Point_R.glb',
          bbox: {
            min: [-0.1653766652097149, -0.13350095436706055, -0.43937547245135367],
            max: [0.09415567272509978, 0.049251042860021355, 0.052532329562493985],
          },
        },
        Raised: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Raised_R.glb',
          bbox: {
            min: [-0.19312347645660327, -0.11293822892394967, -0.42375390200000584],
            max: [0.08910601665709883, 0.043520702861883476, 0.04642021851206213],
          },
        },
        Relaxed: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/Relaxed_R.glb',
          bbox: {
            min: [-0.19405461296141, -0.1283095217611353, -0.4279075691273846],
            max: [0.09335863499754876, 0.04838009892353068, 0.05160340836697941],
          },
        },
        ThumbsUp: {
          url: 'https://unproject-asset-library-nv.s3.amazonaws.com/assets/hand/ThumbsUp_R.glb',
          bbox: {
            min: [-0.2744828793477234, -0.11861167637929515, -0.27016883247929524],
            max: [0.09083084124544155, 0.0475425306581202, 0.050709999008970996],
          },
        },
      },
      position: [2.0, 1.3, 0.0] as [number, number, number],
      rotation: [0.0, 0.0, 0.0] as [number, number, number],
      scale: [45.0, 45.0, 45.0] as [number, number, number],
    },
  },
};

export const getControllerModel = (type: SupportedControllerModels) => {
  return controllerModels[type];
};

export const getControllerBBox = (controllerProperties: any, subType: 'left' | 'right') => {
  if (controllerProperties === null) return;

  if (controllerProperties.type === SupportedControllerModels.quest3) {
    return (getControllerModel(controllerProperties.type)[subType] as any).bbox;
  } else if (controllerProperties.type === SupportedControllerModels.hands) {
    return (getControllerModel(controllerProperties.type)[subType] as any)['info'][
      controllerProperties['actionConfig'][subType]
    ].bbox;
  } else {
    console.error('!Unsupported Type: ', controllerProperties.type);
  }
  return undefined;
};
