// import { ControllerPropertyList } from './ControllerPropertyList';
import { useAppSelector } from 'src/store/reducers/hook';
import { SupportedControllerModels, SupportedSceneObjectTypes } from 'src/types';
import { HandPropertyList } from './HandPropertyList';

export const HandView = (props: {
  config: any;
  parentViewport: string;
  handleHandStateChange: Function;
}) => {
  return (
    <div className="handPreview">
      <HandProperties
        model={props.config.type}
        id={props.config.id}
        handleControllerStateChange={props.handleHandStateChange}
      />
    </div>
  );
};

const HandProperties = (props: {
  model: SupportedControllerModels;
  id: string;
  handleControllerStateChange: Function;
}) => {
  const controllers = useAppSelector((store) => store.sceneViewer.sceneObjectList).filter(
    (item) =>
      item.backendProperties.parent_group_id === props.id &&
      item.type === SupportedSceneObjectTypes.controller
  );

  return (
    <>
      {controllers.map((controller: any, index) => {
        const metadata = controller.backendProperties.metadata;

        return (
          <HandPropertyList
            key={index}
            action={metadata.actionConfig[metadata.subType]}
            type={metadata.type}
            parentController={metadata.subType}
            handleHandStateChange={props.handleControllerStateChange}
          />
        );
      })}
    </>
  );
};
