import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { BROADCAST_EVENTS, Channels, SupportedSceneObjectTypes } from 'src/types';
import { ChannelManager } from 'src/utils/Channel';

export const useAssetSelectionNotifier = () => {
  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);
  const [prevSelection, setPrevSelection] = useState<string | null>(null);

  const notifyOthersOnSelection = (
    selection: { id: string; type: SupportedSceneObjectTypes } | null
  ) => {
    const usersPresent = store.getState().collaboration.enabled;
    if (!usersPresent) return;

    const userId = store.getState().app.currentUser?.id;
    const enabled = store.getState().collaboration.enabled;

    if (enabled && userId)
      ChannelManager.sendToChannel(Channels.sync, BROADCAST_EVENTS.selection, {
        id: userId,
        selection,
      });
  };

  useEffect(() => {
    const selectedObject = selectedObjects.at(0);

    if (!selectedObject) {
      setPrevSelection(null);
      notifyOthersOnSelection(null);
    } else if (selectedObject && selectedObject.id !== prevSelection) {
      setPrevSelection(selectedObject.id);
      notifyOthersOnSelection(selectedObject);
    }
  }, [selectedObjects, prevSelection]);
};
