import { useMemo } from 'react';
import store from 'src/store/store';
import { Avatars } from '../../components/Avatar';

type AvatarGroupProps = {
  id: string;
  ids: string[];
};

const getUsersOfaComment = (commentId: string, ids: string[]) => {
  const comments = store.getState().comments.entities;

  return ids
    .filter((id) => comments[id].parent_id === commentId || comments[id].id === commentId)
    .map((id) => comments[id].user_id);
};

const AvatarsGroup = ({ id, ids }: AvatarGroupProps) => {
  const users = useMemo(() => {
    const userIds = getUsersOfaComment(id, ids);
    const uniqueUsers = Array.from(new Set(userIds));

    return uniqueUsers.map((id) => store.getState().users.entities[id]);
  }, [ids]);

  return <Avatars users={users} />;
};

export default AvatarsGroup;
