import store from 'src/store/store';
import {
  AssetObject,
  AssetObjectMetadataDefaults,
  GroupObject,
  SceneObject,
  SceneObjectLocalPropertiesDefaults,
  SceneObjectMetadata,
  SupportedSceneObjectTypes,
  UIObject,
  UIObjectMetadataDefaults,
  ViewportObject,
  ViewportObjectMetadataDefaults,
  cameraConfigInterface,
  HeadObjectMetadataDefaults,
  HeadObject,
  ControllerObject,
  SupportedGroupTypes,
  SupportedControllerModels,
  ControllerSubtype,
} from 'src/types';
import { getTransformationMatrix } from 'src/utils/helper';
import * as THREE from 'three';

export const getDefaultLocalProperties = (type: SupportedSceneObjectTypes) => {
  return SceneObjectLocalPropertiesDefaults;
};

export const getDefaultMetadata = (type: SupportedSceneObjectTypes) => {
  switch (type) {
    case SupportedSceneObjectTypes.asset:
      return AssetObjectMetadataDefaults;
    case SupportedSceneObjectTypes.viewport:
      return ViewportObjectMetadataDefaults;
    case SupportedSceneObjectTypes.ui:
      return UIObjectMetadataDefaults;
    case SupportedSceneObjectTypes.head:
      return HeadObjectMetadataDefaults;
    case SupportedSceneObjectTypes.group:
    case SupportedSceneObjectTypes.interactions:
    case SupportedSceneObjectTypes.controller:
      return {};
    default:
      return undefined;
  }
};

export const getControllerMetadata = (
  controller: SupportedControllerModels,
  subType: ControllerSubtype
) => {
  switch (controller) {
    case SupportedControllerModels.quest3:
      return {
        colorConfig: {
          joystick: '#ffffff',
          grip: '#ffffff',
          trigger: '#ffffff',
          menu: '#ffffff',
          x: '#ffffff',
          y: '#ffffff',
        },
      };
    case SupportedControllerModels.hands:
      return {
        actionConfig: {
          [subType]: 'Point',
        },
      };
    default:
      return {};
  }
};

const getSceneObjectType = (scene: any) => {
  const type = scene.type;
  if (type === SupportedSceneObjectTypes.asset) {
    return scene as AssetObject;
  } else if (type === SupportedSceneObjectTypes.viewport) {
    return scene as ViewportObject;
  } else if (type === SupportedSceneObjectTypes.ui) {
    return scene as UIObject;
  } else if (type === SupportedSceneObjectTypes.group) {
    return scene as GroupObject;
  } else if (type === SupportedSceneObjectTypes.interactions) {
    return scene as GroupObject;
  } else if (type === SupportedSceneObjectTypes.head) {
    return scene as HeadObject;
  } else if (type === SupportedSceneObjectTypes.controller) {
    return scene as ControllerObject;
  } else {
    console.error('Unsupported Object type in getSceneObject!: ', type);
    return undefined;
  }
};

export const getParentTypeFromChild = (type: SupportedSceneObjectTypes) => {
  switch (type) {
    case SupportedSceneObjectTypes.head:
    case SupportedSceneObjectTypes.interactions:
      return SupportedSceneObjectTypes.viewport;
    case SupportedSceneObjectTypes.controller:
      return SupportedSceneObjectTypes.interactions;
    default:
      return SupportedSceneObjectTypes.group;
  }
};

export const getSceneObject = (type: SupportedSceneObjectTypes, id: string) => {
  const index = store
    .getState()
    .sceneViewer.sceneObjectList.findIndex((item) => item.id === id && item.type === type);
  if (index === -1) return undefined;

  const sceneObject = { ...store.getState().sceneViewer.sceneObjectList[index] };
  return getSceneObjectType(sceneObject);
};

export const getSceneObjectNew = (id: string) => {
  const index = store.getState().sceneViewer.sceneObjectList.findIndex((item) => item.id === id);
  if (index === -1) return undefined;

  const sceneObject = { ...store.getState().sceneViewer.sceneObjectList[index] };
  return getSceneObjectType(sceneObject);
};

export const getPrimaryViewport = () => {
  let primaryViewportObject = undefined as undefined | ViewportObject;
  store.getState().sceneViewer.sceneObjectList.map((item) => {
    if (item.type === SupportedSceneObjectTypes.viewport) {
      let tempItem = item as ViewportObject;
      if (tempItem.localProperties.isPrimary) {
        primaryViewportObject = { ...tempItem };
      }
    }
  });
  return primaryViewportObject;
};

export const calculateCameraConfig = (
  position: [number, number, number],
  rotation: [number, number, number]
) => {
  const p0 = new THREE.Vector3(position[0], position[1], position[2]);
  const euler = new THREE.Euler(rotation[0], rotation[1], rotation[2]);
  const direction = new THREE.Vector3(0.0, 0.0, -1.0);
  direction.applyEuler(euler);
  const camPosition = p0.clone().sub(direction.clone().multiplyScalar(0.05));
  const targetPoint = p0.clone().add(direction.clone().multiplyScalar(2.0));
  const upVector = new THREE.Vector3(0.0, 1.0, 0.0);
  upVector.applyEuler(euler);

  return {
    position: [camPosition.x, camPosition.y, camPosition.z],
    target: [targetPoint.x, targetPoint.y, targetPoint.z],
    upVec: [upVector.x, upVector.y, upVector.z],
  } as cameraConfigInterface;
};

export const getWorldTransform = (id: string) => {
  let position = [0.0, 0.0, 0.0];
  let rotation = [0.0, 0.0, 0.0];
  let scale = [1.0, 1.0, 1.0];

  const sceneObject = getSceneObjectNew(id);
  if (sceneObject) {
    position = sceneObject.backendProperties.position;
    rotation = sceneObject.backendProperties.rotation;
    scale = sceneObject.backendProperties.scale;

    if (sceneObject.backendProperties.parent_group_id !== null) {
      const parentTransform = getWorldTransform(sceneObject.backendProperties.parent_group_id);

      const currentObject = new THREE.Object3D();
      currentObject.applyMatrix4(
        getTransformationMatrix(
          position as [number, number, number],
          rotation as [number, number, number],
          scale as [number, number, number]
        )
      );
      currentObject.applyMatrix4(
        getTransformationMatrix(
          parentTransform.position,
          parentTransform.rotation,
          parentTransform.scale
        )
      );
      // const positionVec = new THREE.Vector3(position[0], position[1], position[2]);
      // positionVec.applyMatrix4(
      //     getTransformationMatrix(
      //         parentTransform.position,
      //         parentTransform.rotation,
      //         parentTransform.scale
      //     )
      // );
      // position = [positionVec.x, positionVec.y, positionVec.z];
      position = [currentObject.position.x, currentObject.position.y, currentObject.position.z];
      rotation = [currentObject.rotation.x, currentObject.rotation.y, currentObject.rotation.z];
      scale = [currentObject.scale.x, currentObject.scale.y, currentObject.scale.z];
    }
  }

  return {
    position: position as [number, number, number],
    rotation: rotation as [number, number, number],
    scale: scale as [number, number, number],
  };
};

export const toLocalTransform = (
  id: string,
  transform: {
    position: [number, number, number];
    rotation: [number, number, number];
    scale: [number, number, number];
  }
) => {
  const sceneObject = getSceneObjectNew(id);
  if (sceneObject) {
    if (sceneObject.backendProperties.parent_group_id === null) {
      return transform;
    } else {
      const parentTransform = getWorldTransform(sceneObject.backendProperties.parent_group_id);
      const currentObject = new THREE.Object3D();
      currentObject.applyMatrix4(
        getTransformationMatrix(
          transform.position as [number, number, number],
          transform.rotation as [number, number, number],
          transform.scale as [number, number, number]
        )
      );

      currentObject.applyMatrix4(
        getTransformationMatrix(
          parentTransform.position,
          parentTransform.rotation,
          parentTransform.scale
        )
          .clone()
          .invert()
      );

      return {
        position: [currentObject.position.x, currentObject.position.y, currentObject.position.z],
        rotation: [currentObject.rotation.x, currentObject.rotation.y, currentObject.rotation.z],
        scale: [currentObject.scale.x, currentObject.scale.y, currentObject.scale.z],
      };
    }
  }
  return transform;
};

export const getCompositeTransform = (child_id: string, parent_id: string) => {
  let transform = {
    position: [0.0, 0.0, 0.0] as [number, number, number],
    rotation: [0.0, 0.0, 0.0] as [number, number, number],
    scale: [1.0, 1.0, 1.0] as [number, number, number],
  };

  const childObject = getSceneObjectNew(child_id);

  if (childObject) {
    transform = {
      position: childObject.backendProperties.position,
      rotation: childObject.backendProperties.rotation,
      scale: childObject.backendProperties.scale,
    };

    const parentObject = getSceneObjectNew(parent_id);
    if (parentObject) {
      const currentObject = new THREE.Object3D();
      currentObject.applyMatrix4(
        getTransformationMatrix(transform.position, transform.rotation, transform.scale)
      );

      currentObject.applyMatrix4(
        getTransformationMatrix(
          parentObject.backendProperties.position,
          parentObject.backendProperties.rotation,
          parentObject.backendProperties.scale
        )
      );

      transform = {
        position: [currentObject.position.x, currentObject.position.y, currentObject.position.z],
        rotation: [currentObject.rotation.x, currentObject.rotation.y, currentObject.rotation.z],
        scale: [currentObject.scale.x, currentObject.scale.y, currentObject.scale.z],
      };
    }
  }

  return transform;
};

export const calculateGroupBBox = (
  childrenList:
    | {
        type: SupportedSceneObjectTypes;
        id: string;
      }[]
    | null
) => {
  if (childrenList !== null && childrenList) {
    let isUndefined = false as boolean;
    let bbox = undefined as undefined | THREE.Box3;
    childrenList.forEach((child) => {
      const sceneObject = getSceneObject(child.type, child.id);
      let childBBox = undefined as undefined | THREE.Box3;
      if (sceneObject) {
        if (!SupportedGroupTypes.includes(sceneObject.type)) {
          if (sceneObject.localProperties.originalBBox) {
            let p = sceneObject.backendProperties.position;
            let r = sceneObject.backendProperties.rotation;
            let s = [1.0, 1.0, 1.0] as [number, number, number];

            if (sceneObject.type !== SupportedSceneObjectTypes.head) {
              s = sceneObject.backendProperties.scale;
            }

            childBBox = sceneObject.localProperties.originalBBox
              .clone()
              .applyMatrix4(getTransformationMatrix(p, r, s));
          }
        } else {
          childBBox = calculateGroupBBox(sceneObject.localProperties.children);
          if (childBBox) {
            childBBox.applyMatrix4(
              getTransformationMatrix(
                sceneObject.backendProperties.position,
                sceneObject.backendProperties.rotation,
                sceneObject.backendProperties.scale
              )
            );
          }
        }
      }

      if (childBBox === undefined) {
        isUndefined = true;
      }

      if (childBBox) {
        if (bbox === undefined) {
          bbox = childBBox.clone();
        } else {
          bbox = bbox.union(childBBox.clone());
        }
      }
    });

    if (isUndefined) {
      return undefined;
    }

    return bbox;
  }

  return undefined;
};

export const calculateGroupCenter = (
  childrenList:
    | {
        type: SupportedSceneObjectTypes;
        id: string;
      }[]
    | null
) => {
  if (childrenList !== null && childrenList) {
    let center = new THREE.Vector3();
    let bbox = undefined as undefined | THREE.Box3;
    childrenList.map((child) => {
      const sceneObject = getSceneObject(child.type, child.id);
      let childBBox = undefined as undefined | THREE.Box3;
      if (sceneObject) {
        const worldTransform = getWorldTransform(sceneObject.id);
        if (!SupportedGroupTypes.includes(sceneObject.type)) {
          if (sceneObject.localProperties.originalBBox) {
            let p = worldTransform.position;
            let r = worldTransform.rotation;
            let s = worldTransform.scale;
            // let s = [1.0, 1.0, 1.0] as [number, number, number];
            // if (sceneObject.type !== SupportedSceneObjectTypes.viewport) {
            //     s = worldTransform.scale;
            // }
            childBBox = sceneObject.localProperties.originalBBox
              .clone()
              .applyMatrix4(getTransformationMatrix(p, r, s));
          }
        } else {
          childBBox = calculateGroupBBox(sceneObject.localProperties.children);
          if (childBBox) {
            childBBox.applyMatrix4(
              getTransformationMatrix(
                worldTransform.position,
                worldTransform.rotation,
                worldTransform.scale
              )
            );
          }
        }
      }

      if (childBBox) {
        if (bbox === undefined) {
          bbox = childBBox.clone();
        } else {
          bbox = bbox.union(childBBox.clone());
        }
      }
    });

    if (bbox) {
      bbox.getCenter(center);
      return center;
    } else {
      return undefined;
    }
  }

  return undefined;
};

export const getOldestParent = (id: string): SceneObject<SceneObjectMetadata> | undefined => {
  const sceneObject = getSceneObjectNew(id);
  if (sceneObject) {
    if (sceneObject.backendProperties.parent_group_id !== null) {
      return getOldestParent(sceneObject.backendProperties.parent_group_id);
    } else {
      return sceneObject;
    }
  }
  return undefined;
};

export const getNextChild = (
  id: string,
  ref_id: string
): SceneObject<SceneObjectMetadata> | undefined => {
  const sceneObject = getSceneObjectNew(id);
  if (sceneObject) {
    if (sceneObject.backendProperties.parent_group_id === ref_id) {
      return sceneObject;
    } else {
      if (sceneObject.backendProperties.parent_group_id === null) {
        return undefined;
      } else {
        return getNextChild(sceneObject.backendProperties.parent_group_id, ref_id);
      }
    }
  }

  return undefined;
};

export const checkDescendent = (id: string, group_id: string) => {
  let isDescendent = false;
  const sceneObject = getSceneObjectNew(group_id);
  if (sceneObject?.localProperties.children) {
    sceneObject.localProperties.children.map((child) => {
      if (child.id === id) {
        isDescendent = true;
      } else {
        if (SupportedGroupTypes.includes(child.type)) {
          isDescendent = checkDescendent(id, child.id);
        }
      }
    });
  }

  return isDescendent;
};

export const checkBBoxUpdatedNeeded = (
  localPropertiesChanges: any,
  backendPropertiesChanges: any
) => {
  let updatedNeeded = false;
  if (backendPropertiesChanges['position'] !== undefined) {
    updatedNeeded = true;
  }
  if (backendPropertiesChanges['rotation'] !== undefined) {
    updatedNeeded = true;
  }
  if (backendPropertiesChanges['scale'] !== undefined) {
    updatedNeeded = true;
  }

  return updatedNeeded;
};
