import 'reactflow/dist/style.css';
import './nodes/style.css';
import ReactFlow, { Controls, MarkerType, useOnViewportChange } from 'reactflow';
import SceneSnapshot from './nodes/SceneSnapshot';
import TextNode from './nodes/TextNode';
import { memo, useCallback, useRef, useState } from 'react';
import ContextMenu from './ContextMenu';
import { useCanvas } from '../hooks/useCanvas';
import { useStoryboard } from '../hooks/useStoryboard';
import { useAppSelector } from 'src/store/reducers/hook';
import HelperLinesRenderer from './HelperLines';
import { ModeType } from 'src/types';
import CommentNode from './nodes/CommentNode';
import InsertNodeContainer from './InsertNodeContainer';
import Cursors from 'src/features/Collaboration/components/cursors';
import useStoryboardCursor from 'src/features/Collaboration/hooks/useCursorStateSynced';

const nodeTypes = {
  SNode: SceneSnapshot,
  TNode: TextNode,
  CNode: CommentNode,
};

const propOptions = { hideAttribution: true };

const StoryBoardCanvas = ({ readOnly = false }) => {
  const canvasRef = useRef(null);
  const [menu, setMenu] = useState(null);
  const [cursors, onMouseMove] = useStoryboardCursor();
  const { onEdgeMoveStart, setViewPort } = useCanvas();
  const {
    handleUpdateNode,
    onEdgeConnect,
    handleUpdateEdge,
    handleEdgesDelete,
    handleNodesDelete,
  } = useStoryboard();

  const selected = useAppSelector((store) => store.comments.selected);
  const { nodes, edges, mode, viewport } = useAppSelector((store) => store.storyboard);

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();

      if (readOnly) return;

      const pane = canvasRef?.current?.getBoundingClientRect();

      setMenu({
        id: node.id,
        top: event.clientY < pane.height - 100 && event.clientY,
        left: event.clientX < pane.width - 100 && event.clientX,
        right: event.clientX >= pane.width - 100 && pane.width - event.clientX,
        bottom: event.clientY >= pane.height - 100 && pane.height - event.clientY,
      });
    },
    [setMenu]
  );

  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const isEnabled = !readOnly && mode.type === ModeType.move;

  useOnViewportChange({
    onEnd: (vp) => {
      setViewPort(vp);
    },
  });

  return (
    <ReactFlow
      ref={canvasRef}
      nodes={nodes}
      edges={edges}
      fitViewOptions={{
        maxZoom: 1,
      }}
      maxZoom={100}
      className="echoweb-storyboard-canvas"
      nodesDraggable={isEnabled}
      panOnDrag={isEnabled}
      style={{ cursor: `${mode.cursor} !important` }}
      panOnScroll={!selected}
      zoomOnScroll={false}
      nodesConnectable={isEnabled}
      elementsSelectable={isEnabled}
      connectOnClick={false}
      nodeTypes={nodeTypes}
      proOptions={propOptions}
      onNodeMouseEnter={onEdgeMoveStart}
      onPaneClick={onPaneClick}
      onNodesChange={handleUpdateNode}
      onEdgesChange={handleUpdateEdge}
      multiSelectionKeyCode={['Shift', 'Meta', 'Ctrl']}
      onConnect={onEdgeConnect}
      deleteKeyCode={['Backspace', 'Delete']}
      edgesFocusable
      defaultEdgeOptions={{
        markerEnd: { type: MarkerType.Arrow, height: 16, width: 16, color: '#328cec' },
        style: {
          stroke: '#328cec',
          strokeWidth: 2,
        },
      }}
      onNodesDelete={handleNodesDelete}
      onEdgesDelete={handleEdgesDelete}
      onPointerMove={onMouseMove}
      elevateEdgesOnSelect
      elevateNodesOnSelect={false}
      onNodeContextMenu={onNodeContextMenu}
      {...(viewport ? { defaultViewport: viewport } : { fitView: true })}
    >
      <Cursors cursors={cursors} />
      <Controls className="storyboard-bg-color" />
      {!readOnly && (
        <>
          <HelperLinesRenderer />
          <InsertNodeContainer />
        </>
      )}
      {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
    </ReactFlow>
  );
};

export default memo(StoryBoardCanvas);
