import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks';
import store from 'src/store/store';
import { ROUTES } from 'src/utils/constants';

const Location = ({ children }: any) => {
  const location = useLocation();
  useEffect(() => {
    let title = 'Unproject';
    switch (location.pathname) {
      case ROUTES.login:
        title = 'Login';
        break;
      case ROUTES.register:
        title = 'Register';
        break;
      case ROUTES.projects:
        title = 'Unproject';
        break;
      case ROUTES.editor:
        title = store.getState().app.project?.name || 'Unproject';
        break;
      case ROUTES.home:
        title = store.getState().app.project?.name || 'Unproject';
        break;
      default:
        title = 'Unproject';
    }

    document.title = title;
  }, [location]);

  return children;
};

export const Authenticated = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <Location>
      <Outlet />
    </Location>
  ) : (
    <Navigate to={ROUTES.login} />
  );
};

export const Unauthenticated = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <Navigate to={ROUTES.projects} />
  ) : (
    <Location>
      <Outlet />;
    </Location>
  );
};
