import { sceneRefInterface } from './context';
import { forwardRef } from 'react';
import PropertyPanel from 'src/components/sceneViewer/propertyPanel/PropertyPanel';
import VoiceNotes from 'src/features/VoiceNotes';
import { useAppSelector } from 'src/store/reducers/hook';
import { InstanceToolType } from 'src/types';

const SidePanel = forwardRef<sceneRefInterface, any>((props, ref) => {
  const activePanelTool = useAppSelector((store) => store.instance.activeTool);

  const selectedObjects = useAppSelector((store) => store.sceneViewer.selectedObjects);

  let displayType = 'block' as 'block' | 'none';

  if (selectedObjects.length !== 0 || activePanelTool === InstanceToolType.comment) {
    displayType = 'block';
  } else {
    displayType = 'none';
  }

  let multiSelectAlign = false;
  if (selectedObjects.length > 1) {
    multiSelectAlign = true;
  }

  return (
    <div
      className={`sidePanel rightSidePanel panel ${multiSelectAlign ? 'multiSelectAlign' : ''}`}
      style={{
        display: displayType,
      }}
    >
      {activePanelTool === InstanceToolType.comment ? <VoiceNotes /> : <PropertyPanel />}
    </div>
  );
});

export default SidePanel;
