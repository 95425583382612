import { toast } from 'react-toastify';
import { useSceneViewer } from 'src/components/sceneViewer/hooks/useSceneViewer';
import store from 'src/store/store';
import { SceneObjectActionTypes, SceneObjectFileTypes, SupportedSceneObjectTypes } from 'src/types';
import { S3_BUCKET_URL, uploadFileToS3Async, uploadFileToS3WithProgressToast } from 'src/utils/aws';
import { getFileType, getNameWithoutExtension, getSupportedFiles } from 'src/utils/upload';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import short from 'short-uuid';
import * as OV from 'online-3d-viewer';
import { svgToPng } from 'src/utils/image';
import { getShortId } from 'src/utils/ids';
import { getFigmaAssetImage } from 'src/services/figma';
import timeHelper from 'src/utils/time';
import { DRACOLoader } from 'three/examples/jsm/Addons';

type UploadImageOptions = {
  uuid?: string;
  operation?: {
    type: SceneObjectActionTypes;
    data: Record<string, any>;
  };
  showToast?: boolean;
};

export const useAssetUpload = () => {
  const { handleSceneObjectAction } = useSceneViewer();

  const getS3AssetPath = (name: string, uuid: string = short.generate()) => {
    const projectId = store.getState().app.projectId;
    const fileName = String(name).trim().replace(/\s/g, '-');
    const key = `${uuid}/${fileName}`;

    return {
      path: `${projectId}/assets/${key}`,
      key,
    };
  };

  const uploadImage = async (file: File, options: UploadImageOptions = {}) => {
    const projectId = store.getState().app.projectId;

    const { operation, uuid, showToast = true } = options;

    const { path } = getS3AssetPath(file.name, uuid);

    try {
      uploadFileToS3WithProgressToast(file, path, {
        showToast,
      }).then(() => {
        let height = 1.0;
        let width = 1.0;
        const reader = new FileReader();

        reader.onload = (e) => {
          if (e.target) {
            const img = new Image();
            img.onload = (e) => {
              height = img.height / 1000.0;
              width = img.width / 1000.0;

              const action = operation?.type || SceneObjectActionTypes.insert;

              const updatedBackendProperties = {
                project_id: projectId,
                ...operation?.data?.backendProperties,
              };

              let updatedMetadata = {
                material_url: `${S3_BUCKET_URL}/${path}`,
                height: height,
                width: width,
                name: file.name,
              };

              if (updatedBackendProperties['metadata'] !== undefined) {
                updatedMetadata = {
                  ...updatedMetadata,
                  ...updatedBackendProperties['metadata'],
                };
              }
              updatedBackendProperties['metadata'] = updatedMetadata;

              handleSceneObjectAction(action, [
                {
                  id: null,
                  type: SupportedSceneObjectTypes.ui,
                  localProperties: {},
                  ...operation?.data,
                  backendProperties: updatedBackendProperties,
                },
              ]);
            };

            img.src = e.target?.result as string;
          }
        };

        reader.readAsDataURL(file);
      });
    } catch (err) {
      console.error('Error in file upload or database update:', err);
    }
  };

  const uploadImageFromUrl = async (url: string, options?: UploadImageOptions) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Image fetch failed with status: ${response.status}`);
    }

    const buffer = await response.arrayBuffer();
    const uint8Array = new Uint8Array(buffer);

    const blob = await svgToPng(uint8Array);

    let name = options?.operation?.data?.backendProperties?.metadata?.name;
    if (!name) {
      name = `${getShortId()}.png`;
    }

    const file = new File([blob], name, {
      type: 'image/png',
    });

    uploadImage(file, options);
  };

  const upload3DAsset = async (
    file: File,
    uuid?: string,
    toastId: number | string | null = null,
    threejsObjectJSON: any | undefined = undefined,
    filetype?: SceneObjectFileTypes
  ) => {
    const projectId = store.getState().app.projectId;
    const scene_id = store.getState().instance.current_sceneId;

    const { path, key } = getS3AssetPath(file.name, uuid);

    try {
      if (filetype === SceneObjectFileTypes.fbx) {
        const uploadFBX = async () => {
          handleSceneObjectAction(SceneObjectActionTypes.insert, [
            {
              id: null,
              type: SupportedSceneObjectTypes.asset,
              localProperties: {
                insertedThisSession: true,
                localThreejsObjectJSON: URL.createObjectURL(file),
              },
              backendProperties: {
                project_id: projectId,
                scene_id: scene_id,
                metadata: {
                  file: key,
                  filetype: SceneObjectFileTypes.fbx,
                },
              },
            },
          ]);

          uploadFileToS3WithProgressToast(file, path, { toastId });

          // handleSceneObjectAction(SceneObjectActionTypes.insert, [
          //   {
          //     id: null,
          //     type: SupportedSceneObjectTypes.asset,
          //     localProperties: {
          //       insertedThisSession: true,
          //     },
          //     backendProperties: {
          //       project_id: projectId,
          //       scene_id: scene_id,
          //       metadata: {
          //         file: key,
          //       },
          //     },
          //   },
          // ]);
        };
        uploadFBX();
      } else if (filetype === SceneObjectFileTypes.glb) {
        const loader = new GLTFLoader();
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          const dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
          loader.setDRACOLoader(dracoLoader);
          loader.load(
            fileReader.result as string,
            async () => {
              handleSceneObjectAction(SceneObjectActionTypes.insert, [
                {
                  id: null,
                  type: SupportedSceneObjectTypes.asset,
                  localProperties: {
                    insertedThisSession: true,
                    localThreejsObjectJSON: threejsObjectJSON,
                  },
                  backendProperties: {
                    project_id: projectId,
                    scene_id: scene_id,
                    metadata: {
                      file: key,
                      filetype: SceneObjectFileTypes.glb,
                    },
                  },
                },
              ]);

              uploadFileToS3WithProgressToast(file, path, { toastId });

              // handleSceneObjectAction(SceneObjectActionTypes.insert, [
              //   {
              //     id: null,
              //     type: SupportedSceneObjectTypes.asset,
              //     localProperties: {
              //       insertedThisSession: true,
              //     },
              //     backendProperties: {
              //       project_id: projectId,
              //       scene_id: scene_id,
              //       metadata: {
              //         file: key,
              //       },
              //     },
              //   },
              // ]);
            },
            undefined,
            (error) => {
              console.error(error);
              toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
            }
          );
        };
      }
    } catch (err) {
      console.error('Error in file upload or database update:', err);
    }
  };

  const convertToGLBAndUpload = (
    data: any,
    filename: string,
    uuid: string,
    toastId: number | string | null = null,
    animations: any[] = []
  ) => {
    const gltfExporter = new GLTFExporter();
    gltfExporter.parse(
      data,
      async (gltfJson) => {
        const blob = new Blob([gltfJson as ArrayBuffer], { type: 'application/octet-stream' });
        const newFilename = getNameWithoutExtension(filename) + '.glb';
        const convertedFile = new File([blob], newFilename, { type: blob.type });
        upload3DAsset(convertedFile, uuid, toastId, gltfJson, SceneObjectFileTypes.glb);
      },
      (error) => {
        console.error(error);
        toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
      },
      {
        binary: true,
        animations: animations,
      }
    );
  };

  const uploadSceneThumbnail = async (id: string, label: string, base64String: string) => {
    const projectId = store.getState().app.projectId;

    fetch(base64String).then((imageURL) => {
      imageURL.blob().then((imageBlob) => {
        const file = new File([imageBlob], label + '.png', {
          type: 'image/png',
        });

        try {
          const dstKey = projectId + '/scenes/' + id + '/viewportImages/' + file.name;

          uploadFileToS3Async(file, dstKey);
        } catch (err) {
          console.error('Error in uploading: ', err);
        }
      });
    });
  };

  const syncFigmaFile = async (asset: any) => {
    const node = asset.metadata.meta;

    if (!node) return;

    const figmaAuth = store.getState().integrations.auth['figma']?.auth_data?.access_token;

    if (!figmaAuth) return;

    const assetData = await getFigmaAssetImage(figmaAuth, node.node_url);

    if (!assetData) return;

    const imageUrl = Object.values(assetData.images).at(0) as string;

    if (!imageUrl) return;

    await uploadImageFromUrl(imageUrl, {
      operation: {
        type: SceneObjectActionTypes.update,
        data: {
          id: asset.id,
          backendProperties: {
            metadata: {
              name: asset.metadata.name,
              meta: {
                type: 'figma',
                node_url: node.node_url,
                lastUpdated: timeHelper().utc().format(),
              },
            },
          },
        },
      },
      showToast: false,
    });
  };

  const uploadAssets = async (fileList: FileList) => {
    const files = getSupportedFiles(fileList);
    const uuid = short.generate();
    const image_indices = [] as number[];
    files.map((file, index) => {
      const fileType = getFileType(file);
      if (fileType === 'images') {
        image_indices.push(index);
      }
    });

    if (image_indices.length) {
      const Imagepromises = [] as any[];
      for (let i = 0; i < image_indices.length; i++) {
        const index = image_indices[i];
        Imagepromises.push(
          uploadImage(files[index], {
            uuid,
            operation: {
              type: SceneObjectActionTypes.insert,
              data: {
                backendProperties: {
                  scene_id: store.getState().instance.current_sceneId,
                },
              },
            },
          })
        );
      }
      await Promise.all(Imagepromises);
    }

    if (image_indices.length < fileList.length) {
      processAndUpload3DAssets(fileList);
    }
  };

  const processAndUpload3DAssets = async (fileList: FileList) => {
    const threeloader = new OV.ThreeModelLoader();
    let settings = new OV.ImportSettings();

    const uuid = short.generate();
    let toastId: string | null | number = null;

    const glbFiles = [];
    const fbxFiles = [];
    const glbFormats = ['glb', 'gltf', 'GLB', 'GLTF'];
    const fbxFormats = ['fbx', 'FBX'];
    const otherFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      const fileType = fileList[i].name.split('.').pop();
      if (fileType && glbFormats.includes(fileType)) {
        glbFiles.push(fileList[i]);
      } else if (fileType && fbxFormats.includes(fileType)) {
        fbxFiles.push(fileList[i]);
      } else {
        otherFiles.push(fileList[i]);
      }
    }

    for (let i = 0; i < glbFiles.length; i++) {
      const file = glbFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(dracoLoader);
        loader.load(
          e.target?.result as string,
          async (gltf) => {
            convertToGLBAndUpload(gltf.scene, file.name, uuid, toastId, gltf.animations);
          },
          undefined,
          (error) => {
            console.error(error);
            toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
          }
        );
      };
      reader.readAsDataURL(file);
    }

    for (let i = 0; i < fbxFiles.length; i++) {
      const file = fbxFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const loader = new FBXLoader();
        loader.load(
          e.target?.result as string,
          async (fbx) => {
            upload3DAsset(file, uuid, toastId, fbx, SceneObjectFileTypes.fbx);
          },
          undefined,
          (error) => {
            console.error(error);
            toast.error(('Failed to load 3D model: ' + (error as any).message) as string);
          }
        );
      };
      reader.readAsDataURL(file);
    }

    const inputfiles = OV.InputFilesFromFileObjects(otherFiles);
    threeloader.LoadModel(inputfiles, settings, {
      onLoadStart: () => {
        toastId = toast(`Processing`, {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      },
      onFileListProgress: (current: any, total: any) => {},
      onFileLoadProgress: (current: any, total: any) => {},
      onImportStart: () => {},
      onVisualizationStart: () => {},
      onModelFinished: (importResult: any, threeObject: any) => {
        console.log(importResult, threeObject);
        console.log('non glb');
        convertToGLBAndUpload(threeObject, importResult.mainFile, uuid, toastId);
      },
      onTextureLoaded: () => {},
      onLoadError: (importError: any) => {
        if (toastId !== null) {
          toast.done(toastId);
        }
        if (importError.mainFile !== null) {
          console.error(importError);
          toast.error(('Failed to load 3D model: ' + (importError as any).message) as string);
        }
      },
      onSelectMainFile: (fileNames: any, selectFile: any) => {
        for (let i = 0; i < fileNames.length; i++) {
          selectFile(i);
        }
      },
    });
  };

  return {
    uploadAssets,
    uploadImage,
    upload3DAsset,
    getS3AssetPath,
    uploadImageFromUrl,
    uploadSceneThumbnail,
    syncFigmaFile,
    processAndUpload3DAssets,
  };
};
