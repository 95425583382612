import { useRef, useState } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import StoryLineHeader from './StoryLineHeader';
import StoryLine from './StoryLine';
import { useDebouncedCallback } from 'use-debounce';

const StoryLineDrawer = () => {
  const ref = useRef<BottomSheetRef>(null);
  const [scenes, setScenes] = useState<any>(null);
  const [expanded, setExpanded] = useState(false);

  const handleOnClick = (expanded: boolean) => {
    if (ref.current === null) return;

    ref.current.snapTo(({ snapPoints }) => (expanded ? snapPoints[2] : snapPoints[0]));
  };

  const handleOnHover = useDebouncedCallback((index: number) => {
    if (ref.current === null) return;

    ref.current.snapTo(({ snapPoints }) => snapPoints[index]);
  }, 200);

  return (
    <BottomSheet
      ref={ref}
      blocking={false}
      skipInitialTransition
      onPointerEnter={() => {
        if (!expanded) handleOnHover(1);
      }}
      onPointerLeave={() => {
        if (!expanded) handleOnHover(0);
      }}
      onClick={() => {
        setExpanded((s) => {
          handleOnClick(!s);
          return !s;
        });
      }}
      defaultSnap={({ snapPoints }) => snapPoints[0]}
      snapPoints={(props) => [48, 100, 280]}
      header={<StoryLineHeader scenes={scenes} setScenes={setScenes} />}
      open
      scrollLocking={false}
      expandOnContentDrag={false}
    >
      <StoryLine scenes={scenes} setScenes={setScenes} />
    </BottomSheet>
  );
};

export default StoryLineDrawer;
