import {
  REALTIME_LISTEN_TYPES,
  REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
  RealtimeChannel,
} from '@supabase/supabase-js';
import { memo, useEffect } from 'react';
import { TABLES } from 'src/utils/constants';
import { useEventHandler } from './hooks/useEventHandler';
import { BROADCAST_EVENTS, Channels } from 'src/types';
import store from '../../store/store';
import { useCollaboration } from '../../hooks/useCollaboration';
import { useAppSelector } from 'src/store/reducers/hook';
import { useUserPresence } from '../../hooks/useUserPresence';
import { ChannelManager } from 'src/utils/Channel';

const Collaboration = () => {
  const handler = useEventHandler();
  const enableRealtime = useAppSelector((store) => store.collaboration.enabled);

  useUserPresence();
  const { updateCollaborator } = useCollaboration();

  const handleCollaboration = (projectId: string, channel: RealtimeChannel) => {
    channel
      .on(
        REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
        {
          event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL,
          schema: 'public',
          table: TABLES.scene_instances,
          filter: `project_id=eq.${projectId}`,
        },
        handler.nodes
      )
      .on(
        REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
        {
          event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL,
          schema: 'public',
          table: TABLES.link_instances,
          filter: `project_id=eq.${projectId}`,
        },
        handler.edges
      )
      .on(
        REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
        {
          event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL,
          schema: 'public',
          table: TABLES.text_instances,
          filter: `project_id=eq.${projectId}`,
        },
        handler.nodes
      )
      .on(REALTIME_LISTEN_TYPES.BROADCAST, { event: BROADCAST_EVENTS.cursor }, (response) => {
        const { payload } = response;
        if (payload?.id) updateCollaborator(payload.id, payload);
      })
      .subscribe();
  };

  useEffect(() => {
    const projectId = store.getState().app.projectId;
    if (!projectId) return;

    const projectChannel = ChannelManager.createChannel(
      Channels.sync,
      `sync-storyboard:${projectId}`
    );

    if (enableRealtime && projectChannel) {
      handleCollaboration(projectId, projectChannel);
    }

    return () => {
      ChannelManager.removeChannel(Channels.sync);
    };
  }, [enableRealtime]);

  return <></>;
};

export default memo(Collaboration);
