import { Box, Flex } from '@chakra-ui/react';
import { ChangeEventHandler, MouseEventHandler, memo, useEffect } from 'react';
import { Text, TextInput } from './TextNode';
import { NodeProps, NodeResizer, Position } from 'reactflow';
import { useCanvas } from '../../hooks/useCanvas';
import { updateNodeAPI } from 'src/apis';
import { useDebouncedCallback } from 'use-debounce';
import store from 'src/store/store';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { setSceneId } from 'src/store/reducers/InstanceReducer';
import { ROUTES } from 'src/utils/constants';
import SceneThumbnail from '../SceneThumbnail';
import { ModeType } from 'src/types';
import useUndoRedo from 'src/hooks/useUndoRedo';
import NodeHandle from './NodeHandle';
import { useCache } from 'src/hooks/useCache';

interface SceneSnapshotDataType {
  title: string;
  thumbnail?: string;
  description: string;
}

const DEFAULT_HANDLE_STYLE = {
  width: 8,
  height: 8,
};

function SceneSnapshot(props: NodeProps<SceneSnapshotDataType>) {
  const { id, data, selected } = props;
  const isInsertMode = useAppSelector((state) => state.storyboard.mode.type === ModeType.insert);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { removeCacheItem } = useCache();
  const { onNodeUpdate } = useCanvas();
  const { execute, pause, update, resume } = useUndoRedo();

  const debouncedUpdateAPI = useDebouncedCallback((id) => {
    const node = store.getState().storyboard.nodes.find((node) => node.id === id);
    if (node) {
      updateNodeAPI(node);

      const id = store.getState().history.pausedAt;
      if (id) {
        resume();
        update({
          id,
          executeFn: () => {
            onNodeUpdate(node.id, node);
            updateNodeAPI(node);
          },
        });
      }
    }
  }, 800);

  const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { value, name } = event.target;

    const undoHistoryEnabled = store.getState().history.enabled;

    if (undoHistoryEnabled) {
      const node = store.getState().storyboard.nodes.find((node) => node.id === id);

      if (node) {
        execute({
          type: 'text-chanage',
          executeFn: () => {},
          undoFn: () => {
            onNodeUpdate(id, { data: { [name]: value } });
            updateNodeAPI(node);
          },
        });
        pause();
      }
    }

    onNodeUpdate(id, { data: { [name]: value } });
    debouncedUpdateAPI(id);
  };

  const handleOnDoubleClick: MouseEventHandler<any> = (e) => {
    if (e.detail === 2) {
      const node = store.getState().storyboard.nodes.find((node) => node.id === id) as any;
      if (!node) return;

      dispatch(setSceneId(node.id));
      navigate(ROUTES.editor);
    }
  };

  useEffect(() => {
    return () => {
      removeCacheItem(id);
    };
  }, []);

  return (
    <Box style={{ cursor: 'default', pointerEvents: isInsertMode ? 'none' : 'all' }} minW={200}>
      <NodeResizer color="#6d8fff" isVisible={selected} minWidth={200} keepAspectRatio />
      <NodeHandle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={1}
        style={{
          ...DEFAULT_HANDLE_STYLE,
          background: '#6d8fff',
          borderColor: '#eee',
          visibility: !selected ? 'hidden' : 'visible',
        }}
      />
      <NodeHandle
        type="target"
        position={Position.Left}
        id="d"
        isConnectable={1}
        style={{
          ...DEFAULT_HANDLE_STYLE,
          background: '#6d8fff',
          borderColor: '#eee',
          visibility: !selected ? 'hidden' : 'visible',
        }}
      />

      <Flex flexDir="column" bgSize="contain" gap={2} p={2} w="100%" borderRadius={4}>
        <TextInput
          id={id}
          name="title"
          p={2}
          borderRadius="md"
          minW="fit-content"
          position="absolute"
          left={0}
          top={-10}
          placeholder="Title"
          onChange={handleOnChange}
          color="#eee"
          fontSize="15"
          defaultValue={data.title}
        />
        <SceneThumbnail sceneId={id} onDoubleClick={handleOnDoubleClick} />
        <Text
          id={id}
          placeholder="Description"
          onChange={handleOnChange}
          name="description"
          color="#eee"
          fontSize="15"
          defaultValue={data.description}
        />
      </Flex>
    </Box>
  );
}

export default memo(SceneSnapshot);
