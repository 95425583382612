import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { identifyUser, trackPage } from 'src/services/analytics';
import { useAppSelector } from 'src/store/reducers/hook';
import { initWasmHandler } from 'src/utils/image';

export const useInit = () => {
  const location = useLocation();
  const currentUser = useAppSelector((store) => store.app.currentUser);

  useEffect(() => {
    initWasmHandler();
  }, []);

  // * Identify User
  useEffect(() => {
    if (currentUser) {
      identifyUser(currentUser.email, { id: currentUser.id, email: currentUser.email });
    }
  }, [currentUser]);

  // * Page View Tracker
  useEffect(() => {
    trackPage();
  }, [location]);
};
