export enum SupportedSceneObjectTypes {
  asset = 'asset',
  ui = 'ui',
  group = 'group',
  viewport = 'viewport',

  // Viewport's children
  head = 'head',
  interactions = 'interactions',
  controller = 'controller',
}
export enum SceneObjectFileTypes {
  glb = 'glb',
  fbx = 'fbx',
}
export enum ControllerSubtype {
  left = 'left',
  right = 'right',
}

export enum SupportedControllerModels {
  quest3 = 'quest3',
  hands = 'hands',
}

export enum SceneObjectActionTypes {
  insert = 'insert',
  update = 'update',
  delete = 'delete',
  group = 'group',
  ungroup = 'ungroup',
}

export const SupportedGroupTypes = [
  SupportedSceneObjectTypes.group,
  SupportedSceneObjectTypes.viewport,
  SupportedSceneObjectTypes.interactions,
];

export const ContantScaleObjectTypes = [
  SupportedSceneObjectTypes.head,
  SupportedSceneObjectTypes.controller,
  SupportedSceneObjectTypes.interactions,
  SupportedSceneObjectTypes.viewport,
];

export interface cameraConfigInterface {
  position: [number, number, number];
  target: [number, number, number];
  upVec: [number, number, number];
}

export interface bboxInfoInterface {
  refBBox: undefined | THREE.Box3;
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
}

export interface gizmoInfoInterface {
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
  show: [boolean, boolean, boolean];
}

export interface sceneObjectRefInterface {
  id: string;
  type: SupportedSceneObjectTypes;
  transform: THREE.Matrix4;
}

export interface refTransformsInterface {
  gizmoRef: THREE.Matrix4;
  bboxRef: THREE.Matrix4;
  sceneObjectsRef: sceneObjectRefInterface[];
}

export enum SceneActionSubType {
  copy = 'copy',
  ungroup = 'ungroup',
}

// Abstract Scene Object Interface
// Always define default values for non-optional local properties
export interface SceneObjectLocalProperties {
  originalBBox?: THREE.Box3;
  animationState?: {
    name: string;
    isPlaying: boolean | undefined;
    time: number | undefined;
    loop: THREE.AnimationActionLoopStyles | undefined;
  }[];
  annotationslength?: number;
  annotationShow?: boolean;
  insertedThisSession: boolean;
  children: {
    id: string;
    type: SupportedSceneObjectTypes;
  }[];
  subType?: SceneActionSubType;
  isPrimary: boolean; // used by viewport scene object
  localThreejsObjectJSON?: any;
}
export const SceneObjectLocalPropertiesDefaults: SceneObjectLocalProperties = {
  insertedThisSession: false,
  children: [],
  isPrimary: false,
};

export interface SceneObjectMetadata {}
export interface SceneObjectBackendProperties<Metadata extends SceneObjectMetadata> {
  id: string;
  project_id: string;
  scene_id: string;
  created_at: string;
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
  constrain_proportions: boolean;
  type: SupportedSceneObjectTypes;
  parent_group_id: string | null;
  hidden: boolean;
  locked: boolean;
  background: boolean;
  metadata: Metadata;
}

export interface SceneObject<Metadata extends SceneObjectMetadata> {
  id: string;
  type: SupportedSceneObjectTypes;
  localProperties: SceneObjectLocalProperties;
  backendProperties: SceneObjectBackendProperties<Metadata>;
}

// SCENE OBJECT === ASSET
export interface AssetObjectMetadata extends SceneObjectMetadata {
  file: string;
  filetype?: SceneObjectFileTypes;
  material_base: string | null;
  metadata: null | any;
}
export const AssetObjectMetadataDefaults: AssetObjectMetadata = {
  file: '',
  material_base: null,
  metadata: null,
};
export interface AssetObject extends SceneObject<AssetObjectMetadata> {
  type: SupportedSceneObjectTypes.asset;
}

// SCENE OBJECT === VIEWPORT

export interface ViewportObjectMetadata extends SceneObjectMetadata {
  type: string;
  label: string;
}

export const ViewportObjectMetadataDefaults: ViewportObjectMetadata = {
  type: 'viewport',
  label: 'viewport-primary',
};

export interface ControllerMetadata extends SceneObjectMetadata {
  type: SupportedControllerModels;
  subType: ControllerSubtype;
  actionConfig?: Record<string, any>;
  colorConfig?: Record<string, any>;
}

export interface HeadObjectMetadata extends SceneObjectMetadata {
  fov: number;
  height: number;
  width: number;
}

export const HeadObjectMetadataDefaults: HeadObjectMetadata = {
  fov: 60,
  height: 1024,
  width: 1024,
};

export interface HeadObject extends SceneObject<HeadObjectMetadata> {
  type: SupportedSceneObjectTypes.head;
}

export interface ViewportObject extends SceneObject<ViewportObjectMetadata> {
  type: SupportedSceneObjectTypes.viewport;
}

// SCENE OBJECT === UI
export interface UIObjectMetadata extends SceneObjectMetadata {
  height: number;
  width: number;
  corner_radius: number;
  corner_steps: number;
  curve_angle: number;
  curve_steps: number;
  material_url: string;
  meta?: any;
}

export const UIObjectMetadataDefaults: UIObjectMetadata = {
  height: 1,
  width: 1,
  corner_radius: 0,
  corner_steps: 20,
  curve_angle: 0,
  curve_steps: 20,
  material_url: '',
  meta: null,
};

export interface UIObject extends SceneObject<UIObjectMetadata> {
  type: SupportedSceneObjectTypes.ui;
}

export interface ControllerObject extends SceneObject<ControllerMetadata> {
  type: SupportedSceneObjectTypes.controller;
}

// SCENE OBJECT === Group
export interface GroupObject extends SceneObject<SceneObjectMetadata> {
  type: SupportedSceneObjectTypes.group;
}
