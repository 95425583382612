import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { supabase } from 'src/SupabaseClient';
import { identifyUser, resetUser, trackEvent } from 'src/services/analytics';
import { EVENTS, PROPERTIES } from 'src/services/analytics/events';
import { setCurrentUser } from 'src/store/reducers/app';
import { useAppDispatch, useAppSelector, useAppStore } from 'src/store/reducers/hook';
import { addUser } from 'src/store/reducers/users';
import store from 'src/store/store';
import { ROUTES } from 'src/utils/constants';
import { getCurrentUser } from 'src/utils/requests';

type LoginParams = {
  email: string;
  password: string;
};

type SignupParams = {
  fullname: string;
  email: string;
  password: string;
};

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clearStores } = useAppStore();
  const toast = useToast();
  const isAuthenticated = useAppSelector((store) => store.app.isAuthenticated);

  const login = async (params: LoginParams, redirectTo: string = ROUTES.home) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword(params);

      if (error) throw error;

      const lastLoggedInUser = store.getState().app.lastLoggedIn;

      if (data) {
        if (lastLoggedInUser !== data.user?.id) {
          clearStores();
        }

        identifyUser(data.user?.email, {
          [PROPERTIES.USER_ID]: data.user?.id,
          [PROPERTIES.USER_EMAIL]: data.user?.email,
        });

        dispatch(setCurrentUser(data.user));
        dispatch(addUser(getCurrentUser(data.user)));

        trackEvent(EVENTS.LOGGED_IN, {
          [PROPERTIES.USER_ID]: data.user.id,
          [PROPERTIES.USER_EMAIL]: data.user.email,
        });

        navigate(redirectTo, { replace: true });
      }

      return { error };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const signup = async (params: SignupParams) => {
    try {
      trackEvent(EVENTS.SIGNUP_START, { [PROPERTIES.USER_EMAIL]: params.email });
      const { data, error } = await supabase.auth.signUp({
        ...params,
        options: {
          data: {
            username: params.email,
            full_name: params.fullname,
          },
        },
      });

      if (error) {
        trackEvent(EVENTS.SIGNUP_ERROR, {
          [PROPERTIES.USER_EMAIL]: params.email,
          [PROPERTIES.ERROR_MSG]: error.message,
        });
        throw error;
      }

      if (data) {
        trackEvent(EVENTS.SIGNUP_COMPLETE, {
          [PROPERTIES.USER_EMAIL]: params.email,
          [PROPERTIES.FULLNAME]: params.fullname,
        });
        toast({
          title: 'Check your email for verification link',
          status: 'success',
          duration: 8000,
          isClosable: true,
        });
      }

      return { error };
    } catch (error) {
      return { error };
    }
  };

  const logout = async () => {
    try {
      await supabase.auth.signOut();

      resetUser();
      dispatch(setCurrentUser(null));
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return {
    login,
    logout,
    signup,
    isAuthenticated,
  };
};
