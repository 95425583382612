import { Select } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import { HeadObject, SupportedSceneObjectTypes, ViewportObject } from 'src/types';
import { getOldestParent } from './helpers';
import { useSceneViewer } from './hooks/useSceneViewer';

type ViewportSelectorProps = {
  current: any;
};

const ViewportSelector: React.FC<ViewportSelectorProps> = ({ current }) => {
  const { onCurrentViewportUpdate } = useSceneViewer();

  const sceneObjectList = useAppSelector((state) => state.sceneViewer.sceneObjectList);

  const viewportObjectList = useMemo(() => {
    return sceneObjectList.filter((item) => item.type === SupportedSceneObjectTypes.head);
  }, [sceneObjectList]) as HeadObject[];

  const handleCurrentViewportChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onCurrentViewportUpdate(event.target.value);
  };

  return (
    <Select
      backdropBlur="8px"
      color="white"
      bg="rgba(18, 17, 23, 0.6)"
      w="100%"
      h="25px"
      display="inline-block"
      fontSize="12px"
      userSelect="none"
      margin="0px 0px 5px 0px"
      variant="filled"
      borderRadius="10px"
      disabled={!current}
      onChange={(event) => handleCurrentViewportChange(event)}
      value={current}
      sx={{
        '> option': {
          background: 'black',
          color: 'white',
          backdropBlur: '8px',
        },
        ':hover': {
          backdropBlur: '8px',
          color: 'white',
          bg: 'rgba(18, 17, 23, 0.6)',
        },
      }}
    >
      {viewportObjectList?.map((item) => {
        const viewport = getOldestParent(item.id) as ViewportObject;

        if (!viewport) return <></>;

        return (
          <option value={viewport.id} key={viewport.id}>
            {viewport.backendProperties.metadata?.label}
          </option>
        );
      })}
    </Select>
  );
};

export default ViewportSelector;
