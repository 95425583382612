import { useEffect } from 'react';
import store from '../../../store/store';
import {
  REALTIME_LISTEN_TYPES,
  REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
  RealtimeChannel,
  RealtimePostgresChangesPayload,
} from '@supabase/supabase-js';
import { TABLES } from '../../../utils/constants';
import { BROADCAST_EVENTS, Channels } from '../../../types';
import { useUserPresence } from '../../../hooks/useUserPresence';
import { useAppSelector } from '../../../store/reducers/hook';
import { ChannelManager } from 'src/utils/Channel';
import { useCollaboration } from 'src/hooks/useCollaboration';

type SceneSocketProps = {
  groupInstanceHandler: (payload: RealtimePostgresChangesPayload<any>) => void;
};

export const useSceneCollaboration = ({ groupInstanceHandler }: SceneSocketProps) => {
  const { updateCollaborator } = useCollaboration();

  const enableRealtime = useAppSelector((state) => state.collaboration.enabled);
  const sceneId = useAppSelector((state) => state.instance.current_sceneId);

  useUserPresence(sceneId);

  const handleCollaboration = (channel: RealtimeChannel, projectId: string) => {
    channel
      .on(
        REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
        {
          event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL,
          schema: 'public',
          table: TABLES.group_instances,
          filter: `project_id=eq.${projectId}`,
        },
        groupInstanceHandler
      )
      .on(REALTIME_LISTEN_TYPES.BROADCAST, { event: BROADCAST_EVENTS.selection }, (response) => {
        const { payload } = response;
        if (payload?.id) {
          updateCollaborator(payload.id, { selection: payload.selection });
        }
      })
      .subscribe();
  };

  useEffect(() => {
    const projectId = store.getState().app.projectId;
    const userId = store.getState().app.currentUser?.id;

    if (!projectId || !userId) return;

    const channel = ChannelManager.createChannel(Channels.sync, `sync-scene:${projectId}`);

    if (enableRealtime && channel) handleCollaboration(channel, projectId);

    return () => {
      ChannelManager.removeChannel(Channels.sync);
    };
  }, [enableRealtime]);
};
