import { useEffect, useLayoutEffect, useRef } from 'react';
import { useAppDispatch } from 'src/store/reducers/hook';
import { selectTool } from 'src/store/reducers/storyboard';
import { createShorcutKeybindings, SHORTCUTS, ShortcutType } from 'src/utils/shortcuts';
import useUndoRedo from '../../../hooks/useUndoRedo';

const useKeyboardListener = () => {
  const dispatch = useAppDispatch();
  const { undo, redo, change } = useUndoRedo();

  useEffect(() => {
    change('storyboard');
    const getBoardToolShortcuts = () => {
      return Object.entries(SHORTCUTS)
        .filter(([key, shortcut]) => shortcut.type === ShortcutType.storyboard)
        .reduce((acc, [key, shortcut]) => {
          acc[key] = () => {
            dispatch(selectTool(shortcut.data?.type));
          };

          return acc;
        }, {} as any);
    };

    const unsubscribe = createShorcutKeybindings({
      ...getBoardToolShortcuts(),
      undo,
      redo,
    });

    return unsubscribe;
  }, []);
};

const useMouseAnchorHandler = () => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const handleMouseMovement = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      if (ref?.current) {
        // Get the coordinates of the cursor and translate the element to that position

        const x = clientX + 2;
        const y = clientY + 2;

        if (clientY > 50 && clientY < window.innerHeight && clientX > 0) {
          ref.current.style.transform = `translate(${x}px, ${y}px)`;
          ref.current.style.visibility = 'visible';
        } else {
          ref.current.style.visibility = 'hidden';
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMovement);

    return () => {
      window.removeEventListener('mousemove', handleMouseMovement);
    };
  }, []);

  return {
    ref,
  };
};

export { useKeyboardListener, useMouseAnchorHandler };
