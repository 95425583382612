import { useEffect } from 'react';
import store from '../store/store';
import { supabase } from '../SupabaseClient';
import {
  REALTIME_LISTEN_TYPES,
  REALTIME_PRESENCE_LISTEN_EVENTS,
  RealtimeChannel,
} from '@supabase/supabase-js';
import { useCollaboration } from './useCollaboration';
import { useAppDispatch } from '../store/reducers/hook';
import { clearCollaboration } from 'src/store/reducers/collaboration';

export const useUserPresence = (id?: string) => {
  const dispatch = useAppDispatch();
  const { addCollaborator, removeCollaborator, followCollaborator } = useCollaboration();

  const handleUsersPresence = (channel: RealtimeChannel) => {
    channel
      .on(REALTIME_LISTEN_TYPES.PRESENCE, { event: REALTIME_PRESENCE_LISTEN_EVENTS.SYNC }, () => {
        const p = channel.presenceState();

        if (!p || Object.values(p).length === 0) {
          dispatch(clearCollaboration());
        }

        setTimeout(() => {
          const enabled = store.getState().collaboration.enabled;
          const following = store.getState().collaboration.following;

          if (following && !enabled) {
            followCollaborator(null);
          }
        }, 4000);
      })
      .on(
        REALTIME_LISTEN_TYPES.PRESENCE,
        { event: REALTIME_PRESENCE_LISTEN_EVENTS.JOIN },
        (payload) => {
          addCollaborator(payload.newPresences as any);
        }
      )
      .on(
        REALTIME_LISTEN_TYPES.PRESENCE,
        { event: REALTIME_PRESENCE_LISTEN_EVENTS.LEAVE },
        (payload) => {
          removeCollaborator(payload.leftPresences.map((p) => p.id));
        }
      )
      .subscribe(async (status) => {
        if (status !== 'SUBSCRIBED') {
          return;
        }

        await channel.track({
          id: store.getState().app.currentUser?.id,
          joinedAt: Date.now(),
        });
      });
  };

  useEffect(() => {
    const projectId = store.getState().app.projectId;
    const userId = store.getState().app.currentUser?.id;

    if (!projectId || !userId) return;

    const storyboardChannel = supabase.channel(
      `users:${projectId}${id ? ':' + id : ''}`,

      {
        config: {
          broadcast: {
            self: false,
          },
        },
      }
    );

    setTimeout(() => {
      handleUsersPresence(storyboardChannel);
    }, 1000);

    return () => {
      supabase.removeChannel(storyboardChannel);
    };
  }, [id]);
};
