import React, { memo, useMemo } from 'react';
import { getConnectedEdges, Handle, useNodeId } from 'reactflow';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';

const NodeHandle = (props: any) => {
  const edges = useAppSelector((store) => store.storyboard.edges);
  const nodeId = useNodeId();

  const isHandleConnectable = useMemo(() => {
    const node = store.getState().storyboard.nodes.find((n) => n?.id === nodeId)!;

    if (node) {
      const connectedEdges = getConnectedEdges([node], edges);
      const isConnnectable = connectedEdges.find((e) =>
        props.position === 'left' ? e.target === nodeId : e.source === nodeId
      );

      return !isConnnectable;
    }
  }, [edges, nodeId, props.isConnectable]);

  return <Handle {...props} isConnectable={isHandleConnectable} />;
};

export default memo(NodeHandle);
