import { useEffect } from 'react';
import { useCanvas } from '../../hooks/useCanvas';
import store from 'src/store/store';
import { getAllNodesAPI, getAllEdgesAPI } from 'src/apis';

const StorboardContainer = () => {
  const { setBoardNodes, setBoardEdges, setBoardComments } = useCanvas();

  useEffect(() => {
    const projectId = store.getState().app.projectId;

    if (projectId) {
      getAllNodesAPI(projectId, (nodes) => setBoardNodes(nodes));
      getAllEdgesAPI(projectId, (edges) => setBoardEdges(edges));
    }

    return () => {
      if (window.location.pathname === '/editor') return;

      setBoardNodes([]);
      setBoardEdges([]);
      setBoardComments([]);
    };
  }, []);

  return <></>;
};

export default StorboardContainer;
