import {
  GizmoHelper,
  GizmoViewport,
  Grid,
  OrbitControls,
  PerspectiveCamera,
  Stage,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { DragEvent, DragEventHandler, forwardRef, useEffect, useRef, useState } from 'react';
import Scene from 'src/components/sceneViewer/scene/Scene';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { stringToColor } from 'src/utils/colors';
import { sceneRefInterface } from './context';
import { useSceneViewer } from './hooks/useSceneViewer';
import WASDcontroles from './WASDcontroles';
import { createShorcutKeybindings } from 'src/utils/shortcuts';
import { openContextMenu } from 'src/store/reducers/modals';
import useSceneMenu from './hooks/useSceneMenu';
import { setActiveTool } from 'src/store/reducers/InstanceReducer';
import { InstanceToolType } from 'src/types';

function Ground() {
  // scene_scale is used to scale the grid to the size of the scene. (1 -> m, 100 -> cm)

  const scene_scale = 1.0;
  const grid_scale_factor = 1.0 / scene_scale;
  const gridConfig = {
    cellSize: 0.5, // size of subdivision in meters
    cellThickness: 0.6,
    cellColor: '#6f6f6f',
    sectionSize: 1.0, // size of main-division in meters
    sectionThickness: 0.8,
    sectionColor: '#6f6f6f',
    fadeDistance: 30,
    fadeStrength: 0.5,
    followCamera: true,
    infiniteGrid: true,
  };
  return <Grid position={[0, -0.01, 0]} args={[5, 5, 5, 5]} {...gridConfig} name="ground" />;
}

const MainPanel = forwardRef<sceneRefInterface, any>((props, ref) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<any>();
  const orbitControlsRef = useRef<any>();
  const cameraRef = useRef<any>();
  const [tooglePointerControls, setTooglePointerControls] = useState<boolean>(false);
  const cameraConfig = useAppSelector((store) => store.sceneViewer.cameraConfig);
  const { notifyOthersOnCameraChange, onSelectObject } = useSceneViewer();
  const { uploadAssets } = useAssetUpload();
  const { handlePaste } = useSceneMenu();

  // When clicked on empty space in Canvas
  const onCanvasClicked = (event: MouseEvent) => {
    if ((event.target as any).id === 'mainPanel') {
      onSelectObject([]);
    }

    // on Right Click
    if (event.button === 2) {
      const sharedMem = store.getState().instance.sharedMemory;

      dispatch(
        openContextMenu({
          items: {
            paste: {
              function: handlePaste,
              args: [],
              disabled: sharedMem.length === 0,
            },
            addComment: {
              function: () => {
                dispatch(setActiveTool(InstanceToolType.comment));
              },
            },
          },
          position: {
            x: event.clientX,
            y: event.clientY,
          },
        })
      );
    } else {
      dispatch(
        openContextMenu({
          items: undefined,
        })
      );
    }
  };

  useEffect(() => {
    if (orbitControlsRef.current && cameraRef.current && cameraConfig) {
      orbitControlsRef.current.reset();
      cameraRef.current.position.set(
        cameraConfig.position[0],
        cameraConfig.position[1],
        cameraConfig.position[2]
      );
      cameraRef.current.up.set(cameraConfig.upVec[0], cameraConfig.upVec[1], cameraConfig.upVec[2]);
      orbitControlsRef.current.target.set(
        cameraConfig.target[0],
        cameraConfig.target[1],
        cameraConfig.target[2]
      );
    }
  }, [cameraConfig, orbitControlsRef, cameraRef]);

  const handlePointerLock = () => {
    setTooglePointerControls((prev) => {
      if (prev === true) {
        document.exitPointerLock();
      }

      return !prev;
    });
  };

  useEffect(() => {
    const unsubscribe = createShorcutKeybindings({
      pointerLock: handlePointerLock,
    });

    return unsubscribe;
  }, []);

  const handleUploadFiles: DragEventHandler = (event: DragEvent) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files.length) {
      uploadAssets(files);
    }
  };

  return (
    <div
      className="mainPanel"
      ref={containerRef}
      id="mainPanel"
      style={{
        transition: 'border 200ms ease-in',
        borderRadius: props.isFollowing ? '1rem' : 'none',
        border: props.isFollowing
          ? `5px solid ${stringToColor(props.isFollowing)}`
          : '5px solid transparent',
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => handleUploadFiles(e)}
    >
      <Canvas
        className="mainViewer"
        eventSource={containerRef}
        onPointerMissed={(event) => onCanvasClicked(event)}
        style={{ background: '#212121' }}
        gl={{
          antialias: true,
          powerPreference: 'high-performance',
        }}
        onCreated={({ gl }) => {
          gl.setPixelRatio(window.devicePixelRatio);
        }}
        flat
        shadows
      >
        <color attach="background" args={['#202020']} />
        <Stage
          shadows
          adjustCamera={false}
          center={{
            disable: true,
          }}
        >
          <directionalLight castShadow intensity={2.0} />
          <PerspectiveCamera
            makeDefault
            position={[-5.0, 10.0, 5.0]}
            near={0.025}
            ref={cameraRef}
          />
          <Scene addCamera={true} ref={ref}>
            {!props.isFollowing && (
              <GizmoHelper alignment="bottom-right" margin={[100, 100]}>
                <GizmoViewport labelColor="white" axisHeadScale={1} />
              </GizmoHelper>
            )}
          </Scene>
          <Ground />
          <WASDcontroles
            ref={cameraRef}
            orbitControlsRef={orbitControlsRef}
            controlsEnabled={tooglePointerControls}
          />

          <OrbitControls
            enabled={!tooglePointerControls}
            ref={orbitControlsRef}
            makeDefault
            onChange={(e) => {
              const isFollowing = store.getState().collaboration.following;
              if (cameraRef.current && !isFollowing) {
                const camera = cameraRef.current;
                notifyOthersOnCameraChange({
                  position: [camera.position.x, camera.position.y, camera.position.z],
                  rotation: [
                    camera.rotation.x,
                    camera.rotation.y,
                    camera.rotation.z,
                    camera.rotation.w,
                  ],
                });
              }
            }}
          />
        </Stage>
      </Canvas>
    </div>
  );
});

export default MainPanel;
