import { createSlice } from '@reduxjs/toolkit';
import { getCommandId } from 'src/utils/ids';

const MAX_HISTORY_LENGTH = 20;

export interface Command {
  id?: string;
  type?: string;
  executeFn: () => void;
  undoFn: () => void;
}

export interface History {
  [key: string]: {
    currentIndex: number;
    stack: Command[];
  };
}

const initialState = {
  enabled: true,
  history: {
    storyboard: {
      currentIndex: 0,
      stack: [] as Command[],
    },
  } as History,
  currentTag: 'storyboard' as string,
  pausedAt: undefined as string | undefined,
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    handleUndo: (state) => {
      state.history[state.currentTag].currentIndex -= 1;
    },
    handleRedo: (state) => {
      state.history[state.currentTag].currentIndex += 1;
    },
    enableHistory: (state, { payload: enabled }) => {
      if (state.enabled !== enabled && state.currentTag !== undefined) {
        state.enabled = enabled;
        state.pausedAt = !enabled ? state.history[state.currentTag].stack.at(-1)?.id : undefined;
      }
    },
    executeCommand: (state, { payload: cmd }) => {
      if (!state.enabled) return;

      state.history[state.currentTag].stack = state.history[state.currentTag].stack.slice(
        0,
        state.history[state.currentTag].currentIndex
      );
      state.history[state.currentTag].stack.push({
        id: getCommandId(cmd.type),
        ...cmd,
      });
      state.history[state.currentTag].currentIndex += 1;
    },
    updateCommand: (state, { payload: cmd }) => {
      state.history[state.currentTag].stack.forEach((h, index) => {
        if (h.id === cmd.id) state.history[state.currentTag].stack[index] = { ...h, ...cmd };
      });
    },
    resetHistory: (state) => {
      return initialState;
    },
    changeTag: (state, { payload: tag }) => {
      state.currentTag = tag;
      if (state.history[tag] === undefined) {
        state.history[tag] = {
          currentIndex: 0,
          stack: [] as Command[],
        };
      }
    },
  },
});

export const {
  handleUndo,
  handleRedo,
  executeCommand,
  enableHistory,
  updateCommand,
  resetHistory,
  changeTag,
} = historySlice.actions;

export default historySlice.reducer;
