import { Box } from '@chakra-ui/react';
import { useMouseAnchorHandler } from '../../hooks/useKeyboardListener';
import { BsLayers } from 'react-icons/bs';

const SceneAnchor = () => {
  const { ref } = useMouseAnchorHandler();

  return (
    <Box ref={ref} p={4} borderRadius={20} color="white" className="cursor-anchor">
      <BsLayers size={24} />
    </Box>
  );
};

export default SceneAnchor;
