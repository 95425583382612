import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { ControllerObject, SceneObjectActionTypes, SupportedSceneObjectTypes } from 'src/types';
import { Clone, useGLTF } from '@react-three/drei';
import { getControllerBBox, getControllerModel } from 'src/utils/controllers';
import { useSceneViewer } from '../hooks/useSceneViewer';

type SceneHandInteractionAssetProps = {
  config: ControllerObject;
  onClick?: Function;
  handleDrag?: Function;
  disablePivot?: boolean;
  onRightClick?: Function;
  onPointerOut?: () => void;
  onPointerOver?: () => void;
  onDoubleClick?: Function;
};

const defaultSceneHandInteractionAssetProps = {
  onClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  handleDrag: (l: THREE.Matrix4, dl: THREE.Matrix4, w: THREE.Matrix4, dw: THREE.Matrix4) => {},
  onRightClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  onDoubleClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  disablePivot: false,
};

function SceneHandInteractionAsset(
  props: SceneHandInteractionAssetProps & typeof defaultSceneHandInteractionAssetProps
) {
  return (
    <group
      position={props.config.backendProperties.position}
      rotation={props.config.backendProperties.rotation}
      onPointerOver={props.onPointerOver}
      onPointerOut={props.onPointerOut}
      scale={props.config.backendProperties.scale}
    >
      <SceneHandAsset
        config={props.config}
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        onDoubleClick={props.onDoubleClick}
      />
    </group>
  );
}

const SceneHandAsset = (props: {
  config: ControllerObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
}) => {
  const { handleSceneObjectAction } = useSceneViewer();

  if (props.config.localProperties.originalBBox === undefined) {
    const controllerBBox = getControllerBBox(
      props.config.backendProperties.metadata,
      props.config.backendProperties.metadata.subType
    );

    const bbox = new THREE.Box3(
      new THREE.Vector3(controllerBBox.min[0], controllerBBox.min[1], controllerBBox.min[2]),
      new THREE.Vector3(controllerBBox.max[0], controllerBBox.max[1], controllerBBox.max[2])
    );

    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: {
          originalBBox: bbox,
        },
        backendProperties: {},
      },
    ]);
  }

  try {
    const metadata = props.config.backendProperties.metadata;
    return (
      <group
        scale={[2.0, 2.0, 2.0]}
        onClick={(event) =>
          props.onClick(event, props.config.id, SupportedSceneObjectTypes.controller)
        }
        onDoubleClick={(event) =>
          props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.controller)
        }
      >
        <HandModel
          handModel={getControllerModel(metadata.type)[metadata.subType]}
          action={metadata.actionConfig?.[metadata.subType]}
        />
      </group>
    );
  } catch (error) {
    return <></>;
  }
};

export const HandModel = (props: any) => {
  const object = useGLTF(props.handModel.info[props.action]['url'] as string);

  return (
    <group rotation={props.handModel.rotation}>
      <Clone object={object.scene} />
    </group>
  );
};

SceneHandInteractionAsset.defaultProps = defaultSceneHandInteractionAssetProps;
export default SceneHandInteractionAsset;
