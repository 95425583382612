import { Node } from 'reactflow';
import { supabase } from 'src/SupabaseClient';
import store from 'src/store/store';
import { SupportedNodeTypes } from 'src/types';
import { TABLES } from 'src/utils/constants';
import { nodeMapper, runBatch } from 'src/utils/requests';

const getNodeTable = (type: SupportedNodeTypes) => {
  switch (type) {
    case SupportedNodeTypes.scene:
      return TABLES.scene_instances;
    case SupportedNodeTypes.text:
      return TABLES.text_instances;
    case SupportedNodeTypes.comment:
      return TABLES.comment_instances;
    default:
      throw new Error('Invalid node type');
  }
};

export const getNodeTypeFromTable = (table: string) => {
  switch (table) {
    case TABLES.scene_instances:
      return SupportedNodeTypes.scene;
    case TABLES.text_instances:
      return SupportedNodeTypes.text;
    case TABLES.comment_instances:
      return SupportedNodeTypes.comment;
    default:
      throw new Error('Invalid table');
  }
};

// * Node APIs
export const getAllNodesAPI = async (projectId: string, callback: (data: any) => void) => {
  const { fulfilled } = await runBatch([
    supabase
      .from(TABLES.scene_instances)
      .select('*')
      .eq('project_id', projectId)
      .then(({ data }: any) => nodeMapper(data, SupportedNodeTypes.scene)) as any,
    supabase
      .from(TABLES.text_instances)
      .select('*')
      .eq('project_id', projectId)
      .then(({ data }: any) => nodeMapper(data, SupportedNodeTypes.text)) as any,
    supabase
      .from(TABLES.comment_instances)
      .select('*')
      .eq('project_id', projectId)
      .then(({ data }: any) => nodeMapper(data, SupportedNodeTypes.comment)) as any,
  ]);

  if (fulfilled.length) {
    const data = fulfilled.reduce((acc: any, res: any) => {
      return [...acc, ...res];
    }, []);

    // Finally, call the callback with the data
    callback(data);
  }
};

export const deleteNodeAPI = async (id: string, type: SupportedNodeTypes) => {
  const relation = getNodeTable(type);
  const { data, error } = await supabase.from(relation).delete().eq('id', id);
  return {
    error,
  };
};

export const createNodeAPI = async (node: any) => {
  const { type, ...restNode } = node;
  const relation = getNodeTable(type);
  const { data: result } = await supabase.from(relation).insert(restNode).select().single();

  return result;
};

export const updateNodeAPI = async (node: Node) => {
  const userId = store.getState().app.currentUser?.id;

  const { position, data, style, type } = node;
  const relation = getNodeTable(type as SupportedNodeTypes);

  await supabase
    .from(relation)
    .update({ position, data, style, updated_by: userId })
    .eq('id', node.id);
};

export const updateStorylineInNodeAPI = async (id: string, storyline: string) => {
  const userId = store.getState().app.currentUser?.id;

  const { data } = await supabase
    .from(TABLES.scene_instances)
    .update({ storyline, updated_by: userId })
    .eq('id', id);

  return data;
};

// Edge APIs

export const getAllEdgesAPI = async (projectId: string, callback: (data: any) => void) => {
  const { data: res } = await supabase
    .from(TABLES.link_instances)
    .select('*')
    .eq('project_id', projectId);

  if (res) {
    callback(res);
  }
};

export const createEdgeAPI = async (projectId: string, edge: any, entityId?: string) => {
  const { source, target, data } = edge;
  const row = {
    source,
    target,
    data,
    project_id: projectId,
    ...(entityId ? { id: entityId } : {}),
  };

  const { data: res } = await supabase.from(TABLES.link_instances).insert(row).select().single();

  if (res) return res;
};

export const createEdgesAPI = async (projectId: string, edges: any[]) => {
  const rows = edges.map((edge) => {
    const { source, target, data } = edge;
    return {
      source,
      target,
      data,
      project_id: projectId,
    };
  });

  const { data: res } = await supabase.from(TABLES.link_instances).insert(rows).select();

  if (res) return res;
};

export const updateEdgeAPI = async (edge: any) => {
  const { id, data } = edge;

  await supabase.from(TABLES.link_instances).update({ data }).eq('id', id);
};

export const deleteEdgeAPI = async (id: string) => {
  await supabase.from(TABLES.link_instances).delete().eq('id', id);
};
