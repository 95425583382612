import { RealtimeChannel, REALTIME_LISTEN_TYPES } from '@supabase/supabase-js';
import { useEffect } from 'react';
import { calculateCameraConfig } from 'src/components/sceneViewer/helpers';
import { useCollaboration } from 'src/hooks/useCollaboration';
import { useAppSelector } from 'src/store/reducers/hook';
import { setSceneId } from 'src/store/reducers/InstanceReducer';
import store from 'src/store/store';
import {
  BROADCAST_EVENTS,
  Channels,
  IFloatingControllers,
  IFloatingHead,
  ISceneContext,
} from 'src/types';
import { ChannelManager } from 'src/utils/Channel';
import { quaternionToEuler } from 'src/utils/helper';

export const useFloatingHeads = (onCameraConfigUpdate: any) => {
  const { updateFloatingUserDetails, isCollaborator } = useCollaboration();

  const enableRealtime = useAppSelector((state) => state.collaboration.enabled);

  const handleFloatingHeads = (channel: RealtimeChannel) => {
    channel
      .on<IFloatingHead>(
        REALTIME_LISTEN_TYPES.BROADCAST,
        { event: BROADCAST_EVENTS.head },
        (response) => {
          const { payload } = response;

          if (payload) {
            const { userId, ...head } = payload;
            if (!isCollaborator(userId)) return;

            const isFollowing = store.getState().collaboration.following === userId;

            const position = head.position as any;
            const rotation = head.rotation as any;

            if (isFollowing) {
              onCameraConfigUpdate(
                calculateCameraConfig(
                  [position.x, position.y, position.z],
                  quaternionToEuler([rotation.x, rotation.y, rotation.z, rotation.w]) as any
                )
              );
            }

            updateFloatingUserDetails(userId, { head });
          }
        }
      )
      .on<IFloatingControllers>(
        REALTIME_LISTEN_TYPES.BROADCAST,
        { event: BROADCAST_EVENTS.controllers },
        (response) => {
          const { payload } = response;

          if (payload) {
            const { userId, ...controllers } = payload;
            if (!isCollaborator(userId)) return;

            updateFloatingUserDetails(userId, { controllers });
          }
        }
      )
      .on<ISceneContext>(
        REALTIME_LISTEN_TYPES.BROADCAST,
        { event: BROADCAST_EVENTS.scene_change },
        (response) => {
          const { payload } = response;

          if (payload) {
            const { userId, sceneId } = payload;
            if (!isCollaborator(userId)) return;

            updateFloatingUserDetails(userId, {
              context: {
                sceneId,
              },
            });

            const isFollowing = store.getState().collaboration.following === userId;
            if (isFollowing) store.dispatch(setSceneId(sceneId));
          }
        }
      )
      .subscribe();
  };

  useEffect(() => {
    const projectId = store.getState().app.projectId;
    const userId = store.getState().app.currentUser?.id;

    if (!projectId || !userId) return;

    const channel = ChannelManager.createChannel(Channels.heads, `heads:${projectId}`);

    if (enableRealtime && channel) handleFloatingHeads(channel);

    return () => {
      ChannelManager.removeChannel(Channels.heads);
    };
  }, [enableRealtime]);
};
