import { useState, useEffect, useDeferredValue } from 'react';
import { Canvas, Vector3 } from '@react-three/fiber';
import { PerspectiveCamera, Stage } from '@react-three/drei';
import Scene from 'src/components/sceneViewer/scene/Scene';
import { useAppSelector } from 'src/store/reducers/hook';
import { HeadObject, SupportedSceneObjectTypes, ViewportObject } from 'src/types';
import { getOldestParent, getWorldTransform } from './helpers';
import ViewportSelector from './ViewportSelector';
import store from 'src/store/store';

type CameraViewerPropsType = {
  cameraViewerStyle: {
    background: string;
    display: 'block' | 'none';
    width: string;
    height: string;
    margin: string;
  };
  position: [number, number, number];
  rotation: [number, number, number];
  aspect: number;
  fov: number;
};

type CameraViewerProps = {
  onClick: () => void;
};

const CameraViewer: React.FC<CameraViewerProps> = ({ onClick }) => {
  const sceneObjectListLength = useAppSelector((store) => store.sceneViewer.sceneObjectList);
  const currentViewport = useAppSelector((store) => store.sceneViewer.currentViewport);
  const deferredUpdate = useDeferredValue(sceneObjectListLength);

  const [cameraViewerProps, setCameraViewerProps] = useState<CameraViewerPropsType>({
    cameraViewerStyle: {
      background: '#202020',
      display: 'none',
      width: '240px',
      height: '240px',
      margin: '0px 0px',
    },
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    aspect: 1.0,
    fov: 60.0,
  });

  useEffect(() => {
    const viewportObjectList = store
      .getState()
      .sceneViewer.sceneObjectList.filter(
        (item) => item.type === SupportedSceneObjectTypes.head
      ) as HeadObject[];

    let currentViewportIndex = viewportObjectList.findIndex((item) => {
      const viewport = getOldestParent(item.id) as ViewportObject;
      return viewport?.id === currentViewport;
    });

    if (currentViewportIndex !== -1 && viewportObjectList[currentViewportIndex]) {
      const headObject = viewportObjectList[currentViewportIndex].backendProperties;
      const transform = getWorldTransform(headObject.id);

      cameraViewerProps.position = transform.position;
      cameraViewerProps.fov = headObject.metadata.fov;
      cameraViewerProps.aspect = headObject.metadata.width / headObject.metadata.height;
      cameraViewerProps.rotation = transform.rotation;
      cameraViewerProps.cameraViewerStyle.display = 'block';

      let width = 240;
      let height = 240;
      let margin_top = 0;
      let margin_left = 0;
      if (cameraViewerProps.aspect > 1.0) {
        width = 240;
        height = parseInt((width / cameraViewerProps.aspect).toString());
        margin_top = parseInt(((240 - height) / 2.0).toString());
        margin_left = 0;
      } else if (cameraViewerProps.aspect < 1.0) {
        height = 240;
        width = parseInt((height * cameraViewerProps.aspect).toString());
        margin_top = 0;
        margin_left = parseInt(((240 - width) / 2.0).toString());
      }

      cameraViewerProps.cameraViewerStyle.height = height.toString() + 'px';
      cameraViewerProps.cameraViewerStyle.width = width.toString() + 'px';
      cameraViewerProps.cameraViewerStyle.margin =
        margin_top.toString() + 'px ' + margin_left.toString() + 'px';

      setCameraViewerProps({ ...cameraViewerProps });
    }
  }, [currentViewport, deferredUpdate]);

  return (
    <div className="panel">
      <ViewportSelector current={currentViewport} />
      <div className="cameraViewer" onClick={onClick}>
        <Canvas
          className="cameraViewer-canvas"
          style={cameraViewerProps.cameraViewerStyle}
          flat
          shadows
        >
          <Stage shadows={true} adjustCamera={false}>
            <PerspectiveCamera
              makeDefault
              position={cameraViewerProps.position as Vector3}
              fov={cameraViewerProps.fov}
              aspect={cameraViewerProps.aspect}
              rotation={cameraViewerProps.rotation}
            />
            <directionalLight castShadow />
            <Scene disablePivot={true} />
          </Stage>
        </Canvas>
      </div>
    </div>
  );
};

export default CameraViewer;
