import { toast } from 'react-toastify';
import AWS from 'aws-sdk';

export const S3_BUCKET_URL = `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`;

export const getS3Instance = () => {
  AWS.config.update({
    accessKeyId: String(process.env.REACT_APP_AWS_ACCESS_KEY_ID),
    secretAccessKey: String(process.env.REACT_APP_AWS_SECRET_ACCESS_KEY),
  });

  return new AWS.S3({
    params: { Bucket: String(process.env.REACT_APP_S3_BUCKET) },
    region: String(process.env.REACT_APP_AWS_REGION),
  });
};

type UploadWithToastOptions = {
  toastId?: string | number | null;
  showToast?: boolean;
};

export const uploadFileToS3WithProgressToast = async (
  file: File,
  dstKey: string,
  options: UploadWithToastOptions = { toastId: null, showToast: true }
) => {
  const s3 = getS3Instance();

  if (file) {
    const params = {
      Bucket: String(process.env.REACT_APP_S3_BUCKET),
      Key: dstKey,
      Body: file,
      CacheControl: 'private, max-age=86400',
    };

    let toastId = options.toastId; // Changed type here

    try {
      const upload = s3.upload(params);

      if (options.showToast) {
        upload.on('httpUploadProgress', (evt) => {
          // Calculate the upload percentage
          const percent = Math.round((evt.loaded / evt.total) * 100);
          // Display or update the toast
          if (!toastId) {
            toastId = toast(`Uploading: ${percent}%`, {
              position: 'bottom-center',
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          } else {
            toast.update(toastId, { render: `Uploading: ${percent}%` });
          }
        });
      }

      await upload.promise();

      if (options.showToast && toastId) {
        toast.done(toastId);
      }
    } catch (err) {
      console.error('Error uploading file:', err);

      if (options.showToast && toastId) {
        toast.dismiss(toastId);
      }

      toast.error('Save failed', {
        position: 'bottom-center',
      });

      throw err;
    }
  }
};

// This does not wait for the upload to finish, even if called with await.
export const uploadFileToS3Async = async (file: File, dstKey: string) => {
  const s3 = getS3Instance();

  if (file) {
    const params = {
      Bucket: String(process.env.REACT_APP_S3_BUCKET),
      Key: dstKey,
      Body: file,
      CacheControl: 'private, max-age=86400',
    };

    try {
      const upload = s3.putObject(params).promise();
    } catch (err) {
      console.error('Error uploading file:', err);
      throw err;
    }
  }
};

export const getFileFromS3 = async (filename: string) => {
  const s3 = getS3Instance();

  const params = {
    Bucket: String(process.env.REACT_APP_S3_BUCKET),
    Key: filename,
  };

  try {
    const data = await s3.getObject(params).promise();
    return data.Body;
  } catch (err) {
    console.error('Error getting file:', err);
    throw err;
  }
};

export const copyFileInS3 = async (srcBucket: string, srcKey: string, dstKey: string) => {
  const s3 = getS3Instance();

  const params = {
    Bucket: String(process.env.REACT_APP_S3_BUCKET),
    CopySource: `${srcBucket}/${srcKey}`,
    Key: dstKey,
    CacheControl: 'private, max-age=86400',
  };

  try {
    const copy = s3.copyObject(params).promise();
    return copy;
  } catch (err) {
    console.error('Error copying file:', err);
    throw err;
  }
};

export const getS3SceneImageKey = ({
  projectId,
  sceneId,
  viewportLabel = 'viewport-primary',
}: {
  projectId: string;
  sceneId: string;
  viewportLabel?: string;
}) => {
  const key = `${projectId}/scenes/${sceneId}/viewportImages/${viewportLabel}.png`;

  return {
    key,
    url: `${S3_BUCKET_URL}/${key}`,
  };
};
