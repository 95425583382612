// @ts-nocheck
import { AddIcon, CopyIcon } from '@chakra-ui/icons';
import { AspectRatio, AspectRatioProps, Box, IconButton, Image } from '@chakra-ui/react';
import { MouseEventHandler, useMemo, useState } from 'react';
import { useCache } from 'src/hooks/useCache';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { NodeCreationType } from 'src/types';
import { getS3SceneImageKey } from 'src/utils/aws';
import { useStoryboard } from '../hooks/useStoryboard';

const IMAGE_FALLBACK = `fallbacks/storyboardscenethumbnailfallback.png`;

const SceneThumbnail = ({
  sceneId,
  onDoubleClick,
  onError,
  ...restProps
}: {
  sceneId: string;
  onDoubleClick?: MouseEventHandler;
  onError?: (e: any) => void;
} & AspectRatioProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState<NodeAction>(null);
  const cache = useAppSelector((store) => store.cache.items);

  const { getCacheItem } = useCache();
  const { handleCreateScene } = useStoryboard();

  const thumbnail = useMemo(() => {
    const projectId = store.getState().app.projectId;

    const { url } = getS3SceneImageKey({ projectId, sceneId });
    const imgInCache = getCacheItem(sceneId);

    return imgInCache || url;
  }, [sceneId, cache]);

  const handleCreate = async (creationType: NodeCreationType) => {
    try {
      setIsLoading(creationType);
      await handleCreateScene(sceneId, creationType);
      setIsLoading(null);
    } catch (err) {
      setIsLoading(null);
      console.error(err);
    }
  };

  return (
    <Box
      w="full"
      h="full"
      position="relative"
      onPointerEnter={() => {
        setShowOptions(true);
      }}
      onPointerLeave={() => {
        setShowOptions(false);
      }}
    >
      {showOptions && (
        <>
          <IconButton
            bg="white"
            colorScheme="white"
            aria-label="Left Scene"
            rounded="full"
            size="sm"
            position="absolute"
            right={-4}
            top="25%"
            zIndex={4}
            isLoading={isLoading === NodeCreationType.duplicate}
            isDisabled={isLoading}
            icon={<CopyIcon fontSize={16} />}
            onClick={() => {
              handleCreate(NodeCreationType.duplicate);
            }}
          />
          <IconButton
            aria-label="Left Scene"
            colorScheme="white"
            bg="white"
            position="absolute"
            isLoading={isLoading === NodeCreationType.new}
            isDisabled={isLoading}
            rounded="full"
            size="sm"
            zIndex={4}
            right={-4}
            bottom="25%"
            onClick={() => {
              handleCreate(NodeCreationType.new);
            }}
            icon={<AddIcon />}
          />
        </>
      )}

      <AspectRatio
        ratio={1 / 1}
        onClick={onDoubleClick}
        bg="#202020"
        overflow="hidden"
        {...restProps}
      >
        <Image
          objectFit="cover"
          objectPosition="center"
          src={thumbnail}
          onError={(e) => {
            e.currentTarget.src = IMAGE_FALLBACK;
            onError?.(e);
          }}
        />
      </AspectRatio>
    </Box>
  );
};

export default SceneThumbnail;
