import { RealtimeChannel, RealtimeChannelOptions } from '@supabase/supabase-js';
import { supabase } from 'src/SupabaseClient';
import { BROADCAST_EVENTS, Channels } from 'src/types';

class RealtimeChannelManager {
  private static instance: RealtimeChannelManager;
  private channel: Record<Channels, RealtimeChannel> = {} as any;

  private constructor() {}

  public static getInstances() {
    if (!RealtimeChannelManager.instance) {
      RealtimeChannelManager.instance = new RealtimeChannelManager();
    }
    return RealtimeChannelManager.instance;
  }

  public createChannel(name: Channels, key: string, config: RealtimeChannelOptions['config'] = {}) {
    this.channel[name] = supabase.channel(key, {
      config: {
        broadcast: {
          self: false,
        },
        ...config,
      },
    });

    return this.channel[name];
  }

  public getChannel(name: Channels) {
    return this.channel[name];
  }

  public sendToChannel(name: Channels, event: BROADCAST_EVENTS, payload: any) {
    if (this.channel[name]) {
      this.channel[name].send({
        event,
        type: 'broadcast',
        payload,
      });
    }
  }

  public removeChannel(name: Channels) {
    if (this.channel[name]) {
      supabase.removeChannel(this.channel[name]);
      delete this.channel[name];
    }
  }
}

export const ChannelManager = RealtimeChannelManager.getInstances();
