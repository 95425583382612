import {
  Suspense,
  useEffect,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useContext,
  useMemo,
} from 'react';
import SceneAsset from 'src/components/sceneViewer/scene/SceneAsset';
import SceneGroupAsset from 'src/components/sceneViewer/scene/SceneGroupAsset';
import * as THREE from 'three';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { context, ContextType, sceneRefInterface } from 'src/components/sceneViewer/context';
import {
  applyProportionalScaling,
  getTransformationMatrix,
  getPRS,
  quaternionToEuler,
} from 'src/utils/helper';
import { useDispatch } from 'react-redux';
import SceneUiAsset from 'src/components/sceneViewer/scene/SceneUiAsset';
import SceneComment from 'src/components/sceneViewer/scene/SceneComment';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import {
  AssetObject,
  DUMMY_NODE,
  GroupObject,
  InstanceToolType,
  SceneObjectActionTypes,
  SupportedNodeTypes,
  SupportedSceneObjectTypes,
  UIObject,
  ViewportObject,
  bboxInfoInterface,
  gizmoInfoInterface,
  refTransformsInterface,
  sceneObjectRefInterface,
  ContantScaleObjectTypes,
  SupportedGroupTypes,
} from 'src/types';
import { addComment, setSelectedComment } from 'src/store/reducers/comments';
import AssetController from './AssetController';
import { PivotControls } from 'src/components/pivotControls';
import BoundingBox from 'src/components/sceneViewer/scene/BoundingBox';
import { useSceneViewer } from '../hooks/useSceneViewer';
import {
  checkDescendent,
  getNextChild,
  getOldestParent,
  getSceneObject,
  getWorldTransform,
  toLocalTransform,
} from '../helpers';
import { useSceneInteractions } from '../hooks/useSceneInteractions';
import FloatingUsers from '../FloatingUser';
import useSceneMenu from '../hooks/useSceneMenu';
import { useSceneObjectSelection } from '../hooks/useSelectObjects';
import { openContextMenu } from 'src/store/reducers/modals';

type SceneProps = {
  children?: ReactNode;
  addCamera?: boolean;
  disablePivot?: boolean;
};

const Scene = forwardRef<sceneRefInterface, SceneProps>((props, ref) => {
  // State Variables
  const { multiSelectConstrainProportions, setMultiSelectConstrainProportions } =
    useContext<ContextType>(context);

  const { scene } = useThree();
  const dispatch = useDispatch();
  const { createContextMenu } = useSceneMenu();
  const { handleSceneObjectAction, onCurrentViewportUpdate, onSelectObject, getSelectedObjects } =
    useSceneViewer();
  const { onBBoxUpdate, onGizmoUpdate, onRefTransformUpdate } = useSceneInteractions();
  const commentIds = useAppSelector((store) => store.comments.ids);
  const commentMode =
    useAppSelector((store) => store.instance.activeTool) === InstanceToolType.comment;
  const sceneObjectList = useAppSelector((store) => store.sceneViewer.sceneObjectList);

  const viewportObjectList = useMemo(() => {
    return sceneObjectList.filter((item) => item.type === SupportedSceneObjectTypes.viewport);
  }, [sceneObjectList]) as ViewportObject[];
  const assetObjectList = useMemo(() => {
    return sceneObjectList.filter((item) => item.type === SupportedSceneObjectTypes.asset);
  }, [sceneObjectList]) as AssetObject[];
  const uiObjectList = useMemo(() => {
    return sceneObjectList.filter((item) => item.type === SupportedSceneObjectTypes.ui);
  }, [sceneObjectList]) as UIObject[];
  const groupObjectList = useMemo(() => {
    return sceneObjectList.filter((item) => item.type === SupportedSceneObjectTypes.group);
  }, [sceneObjectList]) as GroupObject[];

  const gizmoInfo = useAppSelector((store) => store.sceneViewer.gizmoInfo);
  const bboxInfo = useAppSelector((store) => store.sceneViewer.bboxInfo);

  const comments = useMemo(() => {
    return commentIds.filter((id) => {
      const comment = store.getState().comments.entities[id];
      return !comment.parent_id && !comment.filename;
    });
  }, [commentIds]);

  useSceneObjectSelection();

  const handleSelectNode = () => dispatch(setSelectedComment(DUMMY_NODE));

  const addDummyCommentNode = (type: SupportedSceneObjectTypes, selectedInstance: string) => {
    const instance = getSceneObject(type, selectedInstance);
    if (instance) {
      dispatch(
        addComment({
          id: DUMMY_NODE,
          position: instance.backendProperties.position,
          type: SupportedNodeTypes.comment,
        })
      );

      handleSelectNode();
    }
  };

  // Callback function to add new comment to scene
  const handleAddComments = () => {
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length === 1) {
      addDummyCommentNode(selectedObjects[0].type, selectedObjects[0].id);
    }
  };

  useEffect(() => {
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length === 1) {
      let sceneObject = getSceneObject(selectedObjects[0].type, selectedObjects[0].id);
      // assetObejct, viewportObeject, UIObject, or GroupObject
      if (sceneObject) {
        const worldTransform = getWorldTransform(selectedObjects[0].id);
        const scale = ContantScaleObjectTypes.includes(sceneObject.type)
          ? [1.0, 1.0, 1.0]
          : worldTransform.scale;

        onGizmoUpdate({
          position: worldTransform.position,
          rotation: [0.0, 0.0, 0.0],
          scale: worldTransform.scale,
        } as Partial<gizmoInfoInterface>);

        onBBoxUpdate({
          position: worldTransform.position,
          rotation: worldTransform.rotation,
          scale: scale,
        } as Partial<bboxInfoInterface>);
      }
      // else {
      //   if (selectedObjects[0].type === SupportedSceneObjectTypes.interactions) {
      //     const id_and_subtype = selectedObjects[0].id.split('$');
      //     const viewportObject = getSceneObject(
      //       SupportedSceneObjectTypes.viewport,
      //       id_and_subtype[0]
      //     ) as ViewportObject;
      //     if (viewportObject) {
      //       if (viewportObject.backendProperties.metadata.controller_properties !== null) {
      //         let pRelative =
      //           viewportObject.backendProperties.metadata.controller_properties.transform[
      //             id_and_subtype[1]
      //           ].position;
      //         let rRelative =
      //           viewportObject.backendProperties.metadata.controller_properties.transform[
      //             id_and_subtype[1]
      //           ].rotation;
      //         let sRelative =
      //           viewportObject.backendProperties.metadata.controller_properties.transform[
      //             id_and_subtype[1]
      //           ].scale;
      //         const finalPosition = new THREE.Vector3(pRelative[0], pRelative[1], pRelative[2]);

      //         const viewportMatrix = getTransformationMatrix(
      //           viewportObject.backendProperties.position,
      //           viewportObject.backendProperties.rotation,
      //           [1.0, 1.0, 1.0] as [number, number, number]
      //         ).clone();

      //         finalPosition.copy(finalPosition.applyMatrix4(viewportMatrix));
      //         let p = [finalPosition.x, finalPosition.y, finalPosition.z];
      //         let r = rRelative;
      //         let s = sRelative;

      //         onGizmoUpdate({
      //           position: p,
      //           rotation: [0.0, 0.0, 0.0],
      //           scale: s,
      //         } as Partial<gizmoInfoInterface>);

      //         onBBoxUpdate({
      //           position: p,
      //           rotation: r,
      //           scale: s,
      //         } as Partial<bboxInfoInterface>);
      //       }
      //     }
      //   }
      // }
    } else if (selectedObjects.length > 1) {
      // We want to calculate change without using viewport
      // Because for viewport scale is constant
      let done = false;
      const changeMatrix = new THREE.Matrix4();
      for (let i = 0; i < selectedObjects.length; i++) {
        if (selectedObjects[i].type !== SupportedSceneObjectTypes.viewport) {
          const sceneObject = getSceneObject(selectedObjects[i].type, selectedObjects[i].id);
          const oldMatrix = store
            .getState()
            .sceneViewer.refTransforms.sceneObjectsRef.find(
              (item) => item.id === selectedObjects[i].id && item.type === selectedObjects[i].type
            );
          if (sceneObject && sceneObject.type !== SupportedSceneObjectTypes.viewport && oldMatrix) {
            const newTransform = getWorldTransform(sceneObject.id);
            done = true;
            changeMatrix.copy(
              getTransformationMatrix(
                newTransform.position,
                newTransform.rotation,
                newTransform.scale
              )
                .clone()
                .multiply(oldMatrix.transform.clone().invert())
            );
            break;
          }
        }
      }

      // If all viewports or all interactions
      if (!done) {
        const sceneObject = getSceneObject(selectedObjects[0].type, selectedObjects[0].id);
        const oldMatrix = store
          .getState()
          .sceneViewer.refTransforms.sceneObjectsRef.find(
            (item) => item.id === selectedObjects[0].id && item.type === selectedObjects[0].type
          );
        if (sceneObject && oldMatrix) {
          done = true;
          const newTransform = getWorldTransform(sceneObject.id);
          changeMatrix.copy(
            getTransformationMatrix(
              newTransform.position,
              newTransform.rotation,
              newTransform.scale
            )
              .clone()
              .multiply(oldMatrix.transform.clone().invert())
          );
        }
      }

      // if (!done) {
      //   if (selectedObjects[0].type === SupportedSceneObjectTypes.interactions) {
      //     const oldMatrix = store
      //       .getState()
      //       .sceneViewer.refTransforms.sceneObjectsRef.find(
      //         (item) => item.id === selectedObjects[0].id && item.type === selectedObjects[0].type
      //       );

      //     const id_and_subtype = selectedObjects[0].id.split('$');
      //     const viewportObject = getSceneObject(
      //       SupportedSceneObjectTypes.viewport,
      //       id_and_subtype[0]
      //     ) as ViewportObject;
      //     if (
      //       viewportObject &&
      //       oldMatrix &&
      //       viewportObject.backendProperties.metadata.controller_properties !== null
      //     ) {
      //       done = true;
      //       let pRelative =
      //         viewportObject.backendProperties.metadata.controller_properties.transform[
      //           id_and_subtype[1]
      //         ].position;
      //       let rRelative =
      //         viewportObject.backendProperties.metadata.controller_properties.transform[
      //           id_and_subtype[1]
      //         ].rotation;
      //       let sRelative =
      //         viewportObject.backendProperties.metadata.controller_properties.transform[
      //           id_and_subtype[1]
      //         ].scale;
      //       const finalPosition = new THREE.Vector3(pRelative[0], pRelative[1], pRelative[2]);

      //       const viewportMatrix = getTransformationMatrix(
      //         viewportObject.backendProperties.position,
      //         viewportObject.backendProperties.rotation,
      //         [1.0, 1.0, 1.0] as [number, number, number]
      //       ).clone();

      //       finalPosition.copy(finalPosition.applyMatrix4(viewportMatrix));
      //       let p = [finalPosition.x, finalPosition.y, finalPosition.z];
      //       let r = rRelative;
      //       let s = sRelative;

      //       changeMatrix.copy(
      //         getTransformationMatrix(
      //           p as [number, number, number],
      //           r as [number, number, number],
      //           s as [number, number, number]
      //         )
      //           .clone()
      //           .multiply(oldMatrix.transform.clone().invert())
      //       );
      //     }
      //   }
      // }

      // Apply change matrix on bbox ref
      // Do not update ref as ref is updated directly after drag end
      const oldBBoxMatrix = store.getState().sceneViewer.refTransforms.bboxRef.clone();
      const finalBBoxMatrix = new THREE.Matrix4();
      finalBBoxMatrix.copy(changeMatrix.clone().multiply(oldBBoxMatrix));
      let prs = getPRS(finalBBoxMatrix);

      onGizmoUpdate({
        position: prs.position,
        rotation: [0.0, 0.0, 0.0],
        scale: prs.scale,
      } as Partial<gizmoInfoInterface>);

      onBBoxUpdate({
        position: prs.position,
        rotation: prs.rotation,
        scale: prs.scale,
      } as Partial<bboxInfoInterface>);
    }
  }, [sceneObjectList]);

  useImperativeHandle(
    ref,
    () => ({
      scene: scene,
    }),
    [scene]
  );

  const isAssetSelectedByOtherUser = (id: string) => {
    const userId = store.getState().app.currentUser?.id;

    const usersObj = store.getState().collaboration.storyboard;
    const activeUsers = Object.keys(usersObj).filter((user) => user !== userId);

    return activeUsers.some((user) => {
      const userSelection = usersObj[user].selection;
      if (userSelection && userSelection.id === id) return true;
      return false;
    });
  };

  // Event Utils
  const onAssetClick = (
    event: ThreeEvent<MouseEvent>,
    id: string,
    type: SupportedSceneObjectTypes,
    overrideToNormalAlways: boolean = false,
    removeRCMenu: boolean = true
  ) => {
    event.stopPropagation();
    const selectedObjects = getSelectedObjects();

    if (isAssetSelectedByOtherUser(id)) return;

    if (removeRCMenu) {
      dispatch(openContextMenu({ items: undefined }));
    }

    // CTRL Key is pressed when clicking objects
    if ((event.ctrlKey || event.metaKey || event.shiftKey) && !overrideToNormalAlways) {
      onGizmoUpdate({
        show: [true, true, true],
      } as Partial<gizmoInfoInterface>);
      // If already selected then remove it
      const oldestParent = getOldestParent(id);
      if (oldestParent === undefined) return;
      const index = selectedObjects.findIndex(
        (item) => item.id === oldestParent.id && item.type === oldestParent.type
      );

      if (index !== -1) {
        const newArray = [...selectedObjects.slice(0, index), ...selectedObjects.slice(index + 1)];
        onSelectObject(newArray);
      } else {
        onSelectObject([
          ...selectedObjects,
          {
            id: oldestParent.id,
            type: oldestParent.type,
          },
        ]);
      }
    }

    // Normal case
    else {
      setMultiSelectConstrainProportions(false);
      // Originally only one object is selected

      if (!checkSelected(id, type)) {
        if (selectedObjects.length === 1) {
          if (selectedObjects[0].id !== id) {
            onGizmoUpdate({
              show: [true, true, true],
            } as Partial<gizmoInfoInterface>);
          }
        } else {
          onGizmoUpdate({
            show: [true, true, true],
          } as Partial<gizmoInfoInterface>);
        }

        const oldestParent = getOldestParent(id);

        if (oldestParent === undefined) return;

        if (oldestParent.type === SupportedSceneObjectTypes.viewport) {
          // setCurrentViewport(id);
          onCurrentViewportUpdate(oldestParent.id);
        }

        onSelectObject([
          {
            id: oldestParent.id,
            type: oldestParent.type,
          },
        ]);
      }
    }

    if (commentMode) {
      handleAddComments();
    }
  };

  // When gizmo drag start
  // set sceneObjectsRef in refTransforms to selectedObjects
  const handleAssetDragStart = () => {
    const sceneObjectsRef = [] as sceneObjectRefInterface[];
    const selectedObjects = getSelectedObjects();

    selectedObjects.forEach((selectedObject) => {
      let p = [0.0, 0.0, 0.0];
      let r = [0.0, 0.0, 0.0];
      let s = [1.0, 1.0, 1.0];
      let sceneObject = getSceneObject(selectedObject.type, selectedObject.id);
      if (sceneObject) {
        const worldTransform = getWorldTransform(selectedObject.id);
        // p = sceneObject.backendProperties.position;
        // r = sceneObject.backendProperties.rotation;
        // if (sceneObject.type !== SupportedSceneObjectTypes.viewport) {
        //   s = sceneObject.backendProperties.scale;
        // }

        p = worldTransform.position;
        r = worldTransform.rotation;
        s = worldTransform.scale;
      }
      // else {
      //   if (selectedObject.type === SupportedSceneObjectTypes.interactions) {
      //     const id_and_subtype = selectedObject.id.split('$');
      //     const viewportObject = getSceneObject(
      //       SupportedSceneObjectTypes.viewport,
      //       id_and_subtype[0]
      //     ) as ViewportObject;
      //     if (
      //       viewportObject &&
      //       viewportObject.backendProperties.metadata.controller_properties !== null
      //     ) {
      //       let pRelative =
      //         viewportObject.backendProperties.metadata.controller_properties.transform[
      //           id_and_subtype[1]
      //         ].position;
      //       let rRelative =
      //         viewportObject.backendProperties.metadata.controller_properties.transform[
      //           id_and_subtype[1]
      //         ].rotation;
      //       let sRelative =
      //         viewportObject.backendProperties.metadata.controller_properties.transform[
      //           id_and_subtype[1]
      //         ].scale;
      //       const finalPosition = new THREE.Vector3(pRelative[0], pRelative[1], pRelative[2]);

      //       const viewportMatrix = getTransformationMatrix(
      //         viewportObject.backendProperties.position,
      //         viewportObject.backendProperties.rotation,
      //         [1.0, 1.0, 1.0] as [number, number, number]
      //       ).clone();

      //       finalPosition.copy(finalPosition.applyMatrix4(viewportMatrix));
      //       p = [finalPosition.x, finalPosition.y, finalPosition.z];
      //       r = rRelative;
      //       s = sRelative;
      //     }
      //   }
      // }

      sceneObjectsRef.push({
        id: selectedObject.id,
        type: selectedObject.type,
        transform: getTransformationMatrix(
          p as [number, number, number],
          r as [number, number, number],
          s as [number, number, number]
        ),
      });
    });

    const p = store.getState().sceneViewer.gizmoInfo.position;
    const s = store.getState().sceneViewer.gizmoInfo.scale;

    const updatedBbox = store.getState().sceneViewer.bboxInfo;
    onRefTransformUpdate({
      gizmoRef: getTransformationMatrix(p, [0.0, 0.0, 0.0], s),
      sceneObjectsRef: [...sceneObjectsRef],
      bboxRef: getTransformationMatrix(
        updatedBbox.position,
        updatedBbox.rotation,
        updatedBbox.scale
      ),
    } as Partial<refTransformsInterface>);
  };

  const handleAssetDrag = (
    l: THREE.Matrix4,
    dl: THREE.Matrix4,
    w: THREE.Matrix4,
    dw: THREE.Matrix4
  ) => {
    // Check if scaling should be constrained
    let constrainProportions = false;
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length === 1) {
      const sceneObject = getSceneObject(selectedObjects[0].type, selectedObjects[0].id);
      if (sceneObject) {
        if (sceneObject.type !== SupportedSceneObjectTypes.viewport) {
          constrainProportions = sceneObject.backendProperties.constrain_proportions;
        }
      }
    } else if (selectedObjects.length > 1) {
      constrainProportions = multiSelectConstrainProportions;
    }

    const pendingUpdates = [] as any[];

    // Apply the new transformation on all the selected objects
    const refTransforms = store.getState().sceneViewer.refTransforms;
    selectedObjects.forEach((selectedObject, selectedObjectIndex) => {
      const index = refTransforms.sceneObjectsRef.findIndex(
        (item) => item.id === selectedObject.id && item.type === selectedObject.type
      );
      if (index === -1) return;

      // Calculate object transform Matrix.
      const objectMatrix = refTransforms.sceneObjectsRef[index].transform.clone();

      // Matrix using initial transform (transform at drag start).
      // NOTE: Taking old rotation as zero because the gizmo always has zero rotation.
      const oldMatrix = refTransforms.gizmoRef.clone();

      // Matrix using current transform
      const newMatrix = w.clone();
      const finalGroupPosition = new THREE.Vector3();
      const finalGroupRotation = new THREE.Quaternion();
      const finalGroupScale = new THREE.Vector3();
      newMatrix.decompose(finalGroupPosition, finalGroupRotation, finalGroupScale);
      // correct scale if constrain Proportions
      if (constrainProportions) {
        let newScale = [finalGroupScale.x, finalGroupScale.y, finalGroupScale.z];
        // let oldScale = [...bboxInfo.refScale] as [number, number, number];
        let oldScale = new THREE.Vector3();
        refTransforms.gizmoRef.decompose(new THREE.Vector3(), new THREE.Quaternion(), oldScale);
        newScale = applyProportionalScaling([oldScale.x, oldScale.y, oldScale.z], [...newScale] as [
          number,
          number,
          number,
        ]);
        finalGroupScale.set(newScale[0], newScale[1], newScale[2]);
      }
      newMatrix.copy(
        new THREE.Matrix4().compose(finalGroupPosition, finalGroupRotation, finalGroupScale)
      );

      // Calculate delta transform.
      const gizmoChangeMatrix = new THREE.Matrix4();
      gizmoChangeMatrix.copy(newMatrix.multiply(oldMatrix.invert()));

      // Apply delta transform on Object Matrix
      objectMatrix.copy(gizmoChangeMatrix.clone().multiply(objectMatrix));

      const finalObjectPosition = new THREE.Vector3();
      const finalObjectRotation = new THREE.Quaternion();
      const finalObjectScale = new THREE.Vector3();
      objectMatrix.decompose(finalObjectPosition, finalObjectRotation, finalObjectScale);

      const p = [finalObjectPosition.x, finalObjectPosition.y, finalObjectPosition.z];
      const r = quaternionToEuler([
        finalObjectRotation.x,
        finalObjectRotation.y,
        finalObjectRotation.z,
        finalObjectRotation.w,
      ]);
      let s = [finalObjectScale.x, finalObjectScale.y, finalObjectScale.z];

      const localTransform = toLocalTransform(selectedObject.id, {
        position: p as [number, number, number],
        rotation: r as [number, number, number],
        scale: s as [number, number, number],
      });

      // Update to database
      pendingUpdates.push({
        type: selectedObject.type,
        id: selectedObject.id,
        localProperties: {},
        backendProperties:
          // * If the object is a viewport, we only need to update position and rotation
          selectedObject.type === SupportedSceneObjectTypes.viewport
            ? {
                position: localTransform.position,
                rotation: localTransform.rotation,
              }
            : {
                position: localTransform.position,
                rotation: localTransform.rotation,
                scale: localTransform.scale,
              },
      });
      // else {
      //   const id_and_subtype = selectedObject.id.split('$');
      //   const viewportObject = getSceneObject(
      //     SupportedSceneObjectTypes.viewport,
      //     id_and_subtype[0]
      //   ) as ViewportObject;
      //   // const viewportIndex = viewportInstances.findIndex(item => item.id === id_and_subtype[0]);
      //   if (
      //     viewportObject &&
      //     viewportObject.backendProperties.metadata.controller_properties !== null
      //   ) {
      //     const viewportMatrix = getTransformationMatrix(
      //       viewportObject.backendProperties.position,
      //       viewportObject.backendProperties.rotation,
      //       [1.0, 1.0, 1.0] as [number, number, number]
      //     );

      //     const relativePosition = new THREE.Vector3(p[0], p[1], p[2]);
      //     relativePosition.copy(relativePosition.applyMatrix4(viewportMatrix.invert()));

      //     let updatedMetadata = structuredClone(viewportObject.backendProperties.metadata);
      //     updatedMetadata.controller_properties['transform'][id_and_subtype[1]]['position'] = [
      //       relativePosition.x,
      //       relativePosition.y,
      //       relativePosition.z,
      //     ];
      //     updatedMetadata.controller_properties['transform'][id_and_subtype[1]]['rotation'] = r;
      //     updatedMetadata.controller_properties['transform'][id_and_subtype[1]]['scale'] = s;

      //     pendingUpdates.push({
      //       id: id_and_subtype[0],
      //       type: SupportedSceneObjectTypes.viewport,
      //       localProperties: {},
      //       backendProperties: {
      //         metadata: updatedMetadata,
      //       },
      //     });
      //   }
      // }
    });

    handleSceneObjectAction(SceneObjectActionTypes.update, pendingUpdates);
  };

  const handleAssetDragEnd = () => {
    handleAssetDragStart();
  };

  const onPointerOver = (asset: any) => {
    const enableHover = store.getState().instance.activeTool === InstanceToolType.comment;
    if (!enableHover || !asset) return;
    document.body.style.cursor = 'pointer';

    try {
      const worldTransform = getWorldTransform(asset.id);

      onGizmoUpdate({
        position: worldTransform.position,
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
        show: [true, true, true],
      } as Partial<gizmoInfoInterface>);

      onBBoxUpdate({
        position: worldTransform.position,
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
        refBBox: asset.localProperties.originalBBox,
      } as Partial<bboxInfoInterface>);
    } catch (err) {
      console.error(err);
    }
  };

  const onPointerOut = () => {
    const enableHover = store.getState().instance.activeTool === InstanceToolType.comment;

    if (!enableHover) return;

    document.body.style.cursor = 'auto';
  };

  const onBBoxRightClick = (event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length > 1) {
      createContextMenu(event);
    } else {
      if (selectedObjects.length === 1) {
        onAssetRightClick(event, selectedObjects[0].id, selectedObjects[0].type);
      }
    }
  };

  const checkSelected = (id: string, type: SupportedSceneObjectTypes) => {
    let isSelected = false;
    const selectedObjects = getSelectedObjects();
    // In selected
    selectedObjects.forEach((item) => {
      if (item.id === id && item.type === type) {
        isSelected = true;
      }

      if (SupportedGroupTypes.includes(item.type)) {
        isSelected = checkDescendent(id, item.id);
      }
    });

    return isSelected;
  };

  // When Right Clicked on asset directly
  const onAssetRightClick = (
    event: ThreeEvent<MouseEvent>,
    id: string,
    type: SupportedSceneObjectTypes
  ) => {
    event.stopPropagation();

    const isSelected = checkSelected(id, type);
    if (!isSelected) {
      // Override to Normal case for onAssetClick always;
      onAssetClick(event, id, type, true, false);
    }

    createContextMenu(event);
  };

  const onAssetDoubleClick = (
    event: ThreeEvent<MouseEvent>,
    id: string,
    type: SupportedSceneObjectTypes
  ) => {
    event.stopPropagation();
    const selectedObjects = getSelectedObjects();

    if (selectedObjects.length === 1) {
      const sceneObject = getNextChild(id, selectedObjects[0].id);
      if (sceneObject) {
        onSelectObject([
          {
            id: sceneObject.id,
            type: sceneObject.type,
          },
        ]);
      }
    }
  };

  const scaleMatrix = new THREE.Matrix4().scale(
    new THREE.Vector3(gizmoInfo.scale[0], gizmoInfo.scale[1], gizmoInfo.scale[2])
  );

  return (
    <>
      <ambientLight intensity={2.0} />
      <color attach="background" args={['#15151a']} />
      {/* Adding Assets to Scene*/}
      {assetObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) {
          return null;
        }
        return (
          <Suspense key={data.id}>
            <SceneAsset
              key={data.id}
              config={data}
              onPointerOver={() => onPointerOver(data)}
              onPointerOut={onPointerOut}
              onClick={
                props.disablePivot || data.backendProperties.background ? () => {} : onAssetClick
              }
              onRightClick={
                props.disablePivot || data.backendProperties.background
                  ? () => {}
                  : onAssetRightClick
              }
              disablePivot={props.disablePivot || data.backendProperties.background}
            />
          </Suspense>
        );
      })}
      <Suspense>{props.children}</Suspense>
      {uiObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) {
          return null;
        }
        return (
          <Suspense key={data.id}>
            <SceneUiAsset
              config={data}
              onPointerOver={() => onPointerOver(data)}
              onPointerOut={onPointerOut}
              onClick={
                props.disablePivot || data.backendProperties.background ? () => {} : onAssetClick
              }
              onRightClick={
                props.disablePivot || data.backendProperties.background
                  ? () => {}
                  : onAssetRightClick
              }
            />
          </Suspense>
        );
      })}

      {viewportObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) return null;

        return (
          <Suspense key={data.id}>
            <SceneGroupAsset
              config={data}
              onPointerOver={() => onPointerOver(data)}
              onPointerOut={onPointerOut}
              onClick={
                props.disablePivot || data.backendProperties.background ? () => {} : onAssetClick
              }
              onRightClick={
                props.disablePivot || data.backendProperties.background
                  ? () => {}
                  : onAssetRightClick
              }
              onDoubleClick={
                props.disablePivot || data.backendProperties.background
                  ? () => {}
                  : onAssetDoubleClick
              }
            />
          </Suspense>
        );
      })}

      {groupObjectList.map((data) => {
        if (data.backendProperties.parent_group_id !== null) return null;
        return (
          <Suspense key={data.id}>
            <SceneGroupAsset
              config={data}
              onPointerOver={() => onPointerOver(data)}
              onPointerOut={onPointerOut}
              onClick={
                props.disablePivot || data.backendProperties.background ? () => {} : onAssetClick
              }
              onRightClick={
                props.disablePivot || data.backendProperties.background
                  ? () => {}
                  : onAssetRightClick
              }
              onDoubleClick={
                props.disablePivot || data.backendProperties.background
                  ? () => {}
                  : onAssetDoubleClick
              }
            />
          </Suspense>
        );
      })}

      <AssetController
        bbox={bboxInfo}
        gizmo={gizmoInfo}
        pivotProps={{
          disablePivot: props.disablePivot,
          onDrag: handleAssetDrag,
          onDragStart: handleAssetDragStart,
          onDragEnd: handleAssetDragEnd,
        }}
      />
      {!commentMode && !props.disablePivot && (
        <group position={gizmoInfo.position} rotation={gizmoInfo.rotation}>
          <PivotControls
            matrix={scaleMatrix}
            activeAxes={[
              gizmoInfo.show[0] && !props.disablePivot,
              gizmoInfo.show[1] && !props.disablePivot,
              gizmoInfo.show[2] && !props.disablePivot,
            ]}
            fixed={true}
            scale={100}
            depthTest={false}
            onDrag={(l, dl, w, dw) => handleAssetDrag(l, dl, w, dw)}
            onDragStart={() => handleAssetDragStart()}
            onDragEnd={() => handleAssetDragEnd()}
            autoTransform={false}
          />
        </group>
      )}
      {!props.disablePivot && (gizmoInfo.show[0] || gizmoInfo.show[1] || gizmoInfo.show[2]) ? (
        bboxInfo.refBBox !== undefined ? (
          <group
            position={bboxInfo.position}
            rotation={bboxInfo.rotation}
            scale={bboxInfo.scale}
            onContextMenu={(event) => onBBoxRightClick(event)}
            renderOrder={5}
          >
            <BoundingBox bbox={bboxInfo.refBBox} color={0xffff00} />
          </group>
        ) : undefined
      ) : undefined}
      {!props.disablePivot && (
        <>
          {comments.map((id) => {
            const node = store.getState().comments.entities[id];
            return <SceneComment key={id} node={node} />;
          })}
        </>
      )}
      <FloatingUsers />
    </>
  );
});

// Scene.defaultProps = defaultSceneProps
export default Scene;
