export const migrations = {
  2: (state: any) => {
    return {
      ...state,
      sceneViewer: {
        ...state.sceneViewer,
        firstFetchDone: {},
      },
    };
  },
  3: (state: any) => {
    return {
      ...state,
      sceneViewer: {
        ...state.sceneViewer,
        selectedObjects: [],
      },
    };
  },
};
