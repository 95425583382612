// * TODO: Convert to Typescript Project

export const EVENTS = {
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',
  SIGNUP_START: 'Signup Start',
  SIGNUP_ERROR: 'Signup Error',
  SIGNUP_COMPLETE: 'Signup Complete',
  INVITE_USER: 'Invite User',
  CREATE_SCENE: 'Create Scene',
  DELETE_SCENE: 'Delete Scene',
};

export const PROPERTIES = {
  USER_ID: 'user_id',
  USER_EMAIL: 'user_email',
  INVITEE: 'invitee',
  INVITER: 'inviter',
  ERROR_MSG: 'error_msg',
  FULLNAME: 'fullname',
};
