import { VStack } from '@chakra-ui/react';
import UserAccessItem from './UserAccessItem';
import { updateInvitee } from 'src/apis/users';
import store from 'src/store/store';
import type { Invitee, ProjectMember, RoleType } from 'src/types';
import { useAppSelector } from 'src/store/reducers/hook';
import { updateRoleOfUser } from 'src/apis/projects';

type InviteListProps = {
  projectMembers: ProjectMember[];
  setProjectMembers: (projectMembers: ProjectMember[]) => void;
  invitees: Invitee[];
  setInvitees: (invitees: Invitee[]) => void;
};

const InviteList = ({ projectMembers, invitees, setInvitees }: InviteListProps) => {
  const currentUserEmail = useAppSelector((state) => state.app.currentUser?.email);

  const onChange = async (userId: string, role: RoleType) => {
    const projectId = store.getState().app.projectId;
    updateRoleOfUser(projectId, userId, role);
  };

  const onInviteUpdate = async (userId: string, role: RoleType) => {
    const projectId = store.getState().app.projectId;

    updateInvitee(projectId, userId, role).then((res) => {
      setInvitees(
        invitees.map((invitee: Invitee) =>
          invitee.email === userId ? { ...invitee, role } : invitee
        )
      );
    });
  };

  return (
    <VStack
      minHeight={100}
      spacing={4}
      align="stretch"
      height="100%"
      maxH="400px"
      overflowY="scroll"
    >
      {projectMembers.map((user: any) => (
        <UserAccessItem
          key={user.username + user.role}
          id={user.id}
          disabled={currentUserEmail === user.email}
          name={user.username}
          role={user.role}
          handleRoleChange={(newRole) => onChange(user.id, newRole)}
        />
      ))}
      {invitees.map((user: any) => (
        <UserAccessItem
          opacity={0.5}
          key={user.username + user.role}
          id={user.id}
          disabled={currentUserEmail === user.email}
          name={user.email}
          role={user.role}
          handleRoleChange={(newRole) => onInviteUpdate(user.email, newRole)}
        />
      ))}
    </VStack>
  );
};

export default InviteList;
