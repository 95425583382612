import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { BsFolder } from 'react-icons/bs';
import { MdLogout } from 'react-icons/md';
import { BsCardImage } from 'react-icons/bs';

import { useEffect, useState } from 'react';
import { FaAlignJustify, FaRegClock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { setProject } from 'src/store/reducers/app';
import { setSharedMemory } from 'src/store/reducers/InstanceReducer';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { deleteProjectAPI, getAllProjectsAPI, updateProjectAPI } from 'src/apis/projects';
import { ROUTES } from 'src/utils/constants';
import NewProjectButton from 'src/features/Projects/components/NewProject.button';
import { useAuth } from 'src/hooks';
import { UserAvatar } from '../components/Avatar';
import { changeViewport } from 'src/store/reducers/storyboard';
import { Tables } from 'src/types';
import Project from 'src/components/Project';

function NewProjectCreation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  const currentUser = useAppSelector((state) => state.app.currentUser);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [projects, setProjects] = useState<Tables<'projects'>[]>([]);

  useEffect(() => {
    getAllProjectsAPI().then((data: any[]) => setProjects(data ?? []));
  }, []);

  const handleClick = (project: Tables<'projects'>) => {
    dispatch(setSharedMemory([]));
    dispatch(setProject({ id: project.id, name: project.name }));
    dispatch(changeViewport(undefined));
    navigate(ROUTES.home);
  };

  const onRename = async (id: string, name: string) => {
    await updateProjectAPI(id, { name });
  };

  const onDelete = async (id: string) => {
    await deleteProjectAPI(id);
    setProjects((s) => s.filter((project) => project.id !== id));
  };

  const onSelectIndex = (event: any) => {
    setSelectedIndex(event);
  };

  return (
    <Flex backgroundColor="rgba(28,28,28,255)" h="100vh" color="white" flexDirection="column">
      <Flex
        backgroundColor="rgba(40,40,40,255)"
        p={2}
        paddingLeft={6}
        paddingRight={6}
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <FaAlignJustify cursor="pointer" size="20px" />
        </Flex>
        <Flex alignItems="center" gap="10px">
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<UserAvatar name={currentUser?.fullname ?? currentUser?.username} />}
              borderRadius="full"
              variant="ghost"
            />
            <MenuList>
              <MenuItem pointerEvents="none">
                <Box flexDirection="column" color="gray.400">
                  {currentUser?.fullname && (
                    <Text m={0} fontSize="sm">
                      {currentUser?.fullname}
                    </Text>
                  )}
                  <Text fontSize="xs" m={0}>
                    {currentUser?.username}
                  </Text>
                </Box>
              </MenuItem>
              <MenuItem color="red.400" icon={<MdLogout />} onClick={logout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Flex p={3} paddingTop={10}>
        <Tabs
          onChange={onSelectIndex}
          isManual={true}
          w="100vw"
          display="flex"
          orientation="vertical"
          variant="unstyled"
          defaultIndex={selectedIndex}
        >
          <TabList className="tablist">
            <Tab value={0} h={10} w="full">
              <Flex
                className={
                  selectedIndex == 0
                    ? 'projects_page_button project_page_seclectedIndex'
                    : 'projects_page_button'
                }
                alignItems="center"
              >
                <FaRegClock />
                <Flex>
                  <span>Recently added</span>
                </Flex>
              </Flex>
            </Tab>
            <Tab value={1} h={10} w="full">
              <Flex
                className={
                  selectedIndex == 1
                    ? 'projects_page_button project_page_seclectedIndex'
                    : 'projects_page_button'
                }
                alignItems="center"
              >
                <BsFolder />
                <Flex>
                  <span>Drafts</span>
                </Flex>
              </Flex>
            </Tab>
            <Tab value={2} h={10} w="full">
              <Flex
                className={
                  selectedIndex == 2
                    ? 'projects_page_button project_page_seclectedIndex'
                    : 'projects_page_button'
                }
                alignItems="center"
                justifyContent="center"
              >
                <BsCardImage />
                <Flex>
                  <span>Gallery</span>
                </Flex>
              </Flex>
            </Tab>
          </TabList>

          <Flex flexGrow="1" px={3}>
            <TabPanels>
              <TabPanel>
                <Flex flexDirection="column">
                  <Flex justifyContent="space-between">
                    <Text fontSize="30px">Recently Added</Text>
                    <NewProjectButton onCreate={(data) => setProjects((s) => [...s, data])} />
                  </Flex>
                  <SimpleGrid
                    columns={{ sm: 2, md: 3, lg: 4, xl: 5, '2xl': 7 }}
                    spacing={4}
                    cursor="pointer"
                  >
                    {projects?.map((project) => (
                      <Project
                        key={project.id}
                        project={project}
                        onClick={handleClick}
                        onRename={onRename}
                        onDelete={onDelete}
                      />
                    ))}
                  </SimpleGrid>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Flex>
        </Tabs>
      </Flex>
    </Flex>
  );
}

export default NewProjectCreation;
