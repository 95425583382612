import { Button, ScaleFade, Stack } from '@chakra-ui/react';
import { CopyIcon, DeleteIcon } from '@chakra-ui/icons';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { openModal } from 'src/store/reducers/modals';
import { ModalType, NodeCreationType, SupportedNodeTypes } from 'src/types';
import { toast } from 'react-toastify';
import { useStoryboard } from '../hooks/useStoryboard';

export default function ContextMenu({ id, top, left, right, bottom, ...props }) {
  const dispatch = useAppDispatch();
  const { handleCreateScene } = useStoryboard();

  const contextNode = useAppSelector((store) =>
    store.storyboard.nodes.find((node) => node.id === id)
  );

  const duplicateNode = useCallback(async () => {
    try {
      handleCreateScene(id, NodeCreationType.duplicate);
    } catch (err) {
      toast.error('Failed to duplicate node');
      console.error(err);
    }
  }, [id]);

  const onDelete = () => {
    dispatch(
      openModal({
        type: ModalType.delete,
        data: {
          id,
        },
      })
    );
  };

  return (
    <ScaleFade initialScale={0.9} in>
      <Stack
        style={{ top, left, right, bottom }}
        zIndex={10}
        position="absolute"
        direction="column"
        bg="#3d3d3d"
        py={2}
        px={1}
        borderRadius={5}
        width={140}
        {...props}
      >
        {contextNode.type !== SupportedNodeTypes.comment && (
          <Button
            justifyContent="start"
            leftIcon={<CopyIcon />}
            colorScheme="gray"
            variant="ghost"
            size="sm"
            onClick={duplicateNode}
          >
            Duplicate
          </Button>
        )}
        <Button
          size="sm"
          justifyContent="start"
          leftIcon={<DeleteIcon />}
          colorScheme="gray"
          variant="ghost"
          onClick={onDelete}
        >
          Delete
        </Button>
      </Stack>
    </ScaleFade>
  );
}
