import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { BsSend } from 'react-icons/bs';
import { NodeProps } from 'reactflow';
import ResizeTextarea from 'react-textarea-autosize';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { Comment, CommentsType, DUMMY_NODE, ModeType, SupportedNodeTypes } from 'src/types';
import { forwardRef, useRef, useState } from 'react';
import { addCommentAPI, createNodeAPI, deleteCommentAPI } from 'src/apis';
import store from 'src/store/store';
import { changeNodeId, removeNode } from 'src/store/reducers/storyboard';
import { addComment, deleteComment, setSelectedComment } from 'src/store/reducers/comments';
import NodeComment from '../NodeComments';
import { toast } from 'react-toastify';
import AvatarsGroup from 'src/features/Comments/AvatarGroup';

type CommentNodeProps = {
  id: string;
};

const CommentScheme = z.object({
  comment: z.string().min(1, 'Comment should not be empty'),
});

type CommentSchemaType = z.infer<typeof CommentScheme>;

type CommentBoxProps = {
  id: string;
  type: CommentsType;
  commentIds: string[];
  isLoading: boolean;
  setLoading: any;
  position: { x: number; y: number };
};

export const CommentBox = forwardRef<HTMLTextAreaElement, CommentBoxProps>(
  ({ id, setLoading, position, isLoading, type, commentIds }, textAreaRef) => {
    const dispatch = store.dispatch;
    const scrollRef = useRef<HTMLDivElement>(null);

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isValid },
    } = useForm<CommentSchemaType>({ resolver: zodResolver(CommentScheme) });

    const getCommentTypeData = (type: CommentsType) => {
      switch (type) {
        case CommentsType.scene:
          return {
            scene_id: store.getState().instance.current_sceneId,
            position,
          };
        default:
          return {};
      }
    };

    const { ref, ...restRegister } = register('comment');

    const onAddComment = async (projectId: string, data: Partial<Comment>) => {
      try {
        const newComment = await addCommentAPI(
          {
            project_id: projectId,
            ...(data.id ? { id: data.id } : {}),
            ...(data.parent_id ? { parent_id: data.parent_id } : {}),
            comment: data.comment,
            ...getCommentTypeData(type),
          },
          type
        );

        dispatch(addComment(newComment));

        if (type === CommentsType.scene) {
          dispatch(deleteComment(DUMMY_NODE));
          dispatch(setSelectedComment(newComment.id));
        }
        setTimeout(() => {
          scrollRef?.current?.scrollIntoView(false);
        });
      } catch (err) {
        console.error(err);
      }
    };

    const onComment = async (data: CommentSchemaType) => {
      try {
        const projectId = store.getState().app.projectId;
        setLoading(true);
        if (id === DUMMY_NODE) {
          let node;

          if (type === CommentsType.storyboard) {
            node = await createNodeAPI({
              type: SupportedNodeTypes.comment,
              position: position,
              project_id: projectId,
            });
          }

          await onAddComment(projectId, {
            comment: data.comment,
            id: node?.id,
          });

          if (node) {
            dispatch(changeNodeId({ oldId: id, newId: node.id }));
          }
        } else {
          await onAddComment(projectId, { comment: data.comment, parent_id: id });
        }
        reset();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const handleDeleteComment = async (id: string) => {
      try {
        await deleteCommentAPI(id);
        dispatch(deleteComment(id));
      } catch (err) {
        toast('Comment deleted');
        console.error(err);
      }
    };

    return (
      <Card bg="#3d3d3d" maxW="400" id="8">
        <form onSubmit={handleSubmit(onComment)}>
          <CardBody id="card-body">
            <NodeComment
              ref={scrollRef}
              id={id}
              commentIds={commentIds}
              onDelete={handleDeleteComment}
            />
            <FormControl isInvalid={!!errors.comment?.message}>
              <Textarea
                id={id}
                minW={360}
                as={ResizeTextarea}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(onComment)();
                  }
                }}
                placeholder="Add a comment"
                ref={(e) => {
                  ref(e);
                  if (textAreaRef) (textAreaRef as any).current = e;
                }}
                {...restRegister}
                resize="none"
                bg="transparent"
                variant="outline"
                color="white"
                _focus={{
                  outline: 'none',
                }}
              />
            </FormControl>
          </CardBody>
          <CardFooter pt={0}>
            <Button
              type="submit"
              isDisabled={!isValid || isLoading}
              width="100%"
              leftIcon={<BsSend size={20} />}
            >
              Comment
            </Button>
          </CardFooter>
        </form>
      </Card>
    );
  }
);

const CommentNode: React.FC<NodeProps<CommentNodeProps>> = (props) => {
  const dispatch = useAppDispatch();
  const firstFieldRef = useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSelected = useAppSelector((store) => store.comments.selected === props.id);
  const commentIds = useAppSelector((store) => store.comments.ids);
  const isInsertMode = useAppSelector((store) => store.storyboard.mode.type === ModeType.insert);

  const handleOpen = () => {
    dispatch(setSelectedComment(props.id));
    onOpen();
  };

  const handleClose = () => {
    if (props.id === DUMMY_NODE) dispatch(removeNode(props.id));
    dispatch(setSelectedComment(undefined));
    onClose();
  };

  return (
    <Box
      bg="#4f4f4f"
      zIndex={2}
      borderRadius="full"
      className="nowheel"
      style={{
        pointerEvents: isInsertMode ? 'none' : 'all',
        border: 'none',
      }}
      _hover={
        !isSelected
          ? {
              bg: '#5a5a5a',
              cursor: 'pointer',
              transform: 'scale(1.2)',
            }
          : {}
      }
      transform={isSelected ? 'scale(1.2)' : 'scale(1)'}
      transformOrigin="bottom left"
      transition="all 0.2s"
      shadow="lg"
      borderBottomLeftRadius={5}
    >
      <Popover
        placement="right-start"
        isOpen={isOpen || isSelected}
        initialFocusRef={firstFieldRef}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <PopoverTrigger>
          <Box p={2}>
            {isLoading ? (
              <Spinner p={0} thickness="3px" speed="0.65s" color="blue.500" size="lg" />
            ) : props.id !== DUMMY_NODE ? (
              <AvatarsGroup ids={commentIds} id={props.id} />
            ) : (
              <Box p={1} />
            )}
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="fit-content" p={0}>
            {(isOpen || isSelected) && (
              <CommentBox
                id={props.id}
                type={CommentsType.storyboard}
                commentIds={commentIds}
                ref={firstFieldRef}
                isLoading={isLoading}
                setLoading={setIsLoading}
                position={{ x: props.xPos, y: props.yPos }}
              />
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

export default CommentNode;
