export const TABLES = {
  instances: 'instances',
  scene_instances: 'scene_instances',
  text_instances: 'text_instances',
  comment_instances: 'comment_instances',
  link_instances: 'link_instances',
  camera_instances: 'camera_instances',
  voicenote_instances: 'voicenote_instances',
  project_users: 'project_users',
  projects: 'projects',
  users: 'users',
  invites: 'invites',
  comments: 'comments',
  ui_instances: 'ui_instances',
  scene_comments: 'scene_comments',
  integrations_auth: 'integrations_auth',
  group_instances: 'group_instances',
};

export enum ROUTES {
  login = '/',
  home = '/home',
  editor = '/editor',
  register = '/signup',
  dashboard = '/dashboard',
  integrations = '/integrations',
  projects = '/projects',
}

export const IS_DEV = process.env.NODE_ENV === 'development';
// export const IS_DEV = false;
