import { NodeChange } from 'reactflow';
import store from 'src/store/store';
import { DUMMY_NODE } from 'src/types';

const euclDistance = (p1: any, p2: any) => {
  const d = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  return d;
};

export const canvasEventHander = (changes: NodeChange[], callback?: (node: any) => void) => {
  if (changes.length > 1) {
    return;
  }

  const change = changes.at(0) as any;

  switch (change.type) {
    case 'dimensions':
      if (change.id === DUMMY_NODE) return;
      const changedNode = store.getState().storyboard.nodes.find((node) => node.id === change.id);

      if (changedNode) {
        const style = changedNode.style ?? {};
        if (style.width === change.width && style.height === change.height) return;

        callback?.(changedNode);
        return {
          node: changedNode,
          type: change.type,
        };
      }

      break;
    case 'position':
      if (change.id === DUMMY_NODE) return;
      const node = store.getState().storyboard.nodes.find((node) => node.id === change.id);

      if (node) {
        callback?.(node);
        return {
          node,
          type: change.type,
        };
      }
      break;
    default:
      console.info('Unhandled event type', { change });
  }
};
