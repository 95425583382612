import DeleteNodeModal from './DeleteNode';
import FigmaImportSettings from './FigmaImportSettings';
import Preview3DAsset from './Preview3DAsset';
import { SceneAICompanion } from './SceneAICompanion';

const Modals = () => {
  return (
    <>
      <SceneAICompanion />
      <DeleteNodeModal />
      <Preview3DAsset />
      <FigmaImportSettings />
    </>
  );
};

export default Modals;
