import { Box } from '@chakra-ui/react';
import Collaborators from 'src/features/Collaboration/components/Collaborators';
import { followUser } from 'src/store/reducers/collaboration';
import { useAppDispatch } from 'src/store/reducers/hook';
import { setSceneId } from 'src/store/reducers/InstanceReducer';
import store from 'src/store/store';

const TopRightPanel = () => {
  const dispatch = useAppDispatch();

  const onUserClick = (userId: string) => {
    const currentlyFollowing = store.getState().collaboration.following;
    dispatch(followUser(userId === currentlyFollowing ? null : userId));

    if (currentlyFollowing) {
      const sceneIdOfFollowedUser =
        store.getState().collaboration.floating?.[currentlyFollowing]?.context?.sceneId;
      const currentScene = store.getState().instance.current_sceneId;

      if (sceneIdOfFollowedUser && sceneIdOfFollowedUser !== currentScene) {
        dispatch(setSceneId(sceneIdOfFollowedUser));
      }
    }
  };

  return (
    <Box position="absolute" p={1} top="15px" right="15px">
      <Collaborators onClick={onUserClick} />
    </Box>
  );
};

export default TopRightPanel;
