import PropertyField from './PropertyField';
import PropertyHeading from './PropertyHeading';
import { ChangeEvent } from 'react';
import { radToDeg, degToRad } from 'three/src/math/MathUtils';
import {
  HeadObject,
  SceneObjectActionTypes,
  SupportedSceneObjectTypes,
  ViewportObject,
} from 'src/types';
import { InteractionsProperties } from './interactionsProperties/InteractionsProperties';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { getWorldTransform, toLocalTransform } from '../helpers';
import { Stack } from '@chakra-ui/react';

// scene_scale is used to scale the grid to the size of the scene. (1 = cm, 0.01 = m)
const scene_scale = 1.0;

export default function CameraProperties(props: { id: string }) {
  const { handleSceneObjectAction } = useSceneViewer();
  const viewportSceneObject = useAppSelector((store) =>
    store.sceneViewer.sceneObjectList.find(
      (item) => item.type === SupportedSceneObjectTypes.viewport && item.id === props.id
    )
  ) as ViewportObject;

  if (!viewportSceneObject) return null;

  const onChange = (object: any) => {
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: object.id,
        type: object.type,
        localProperties: {},
        backendProperties: {
          ...object,
        },
      },
    ]);
  };

  return (
    <Stack spacing={0.5}>
      <ViewportProperties viewport={viewportSceneObject} onChange={onChange} />
      <HeadProperties id={viewportSceneObject.id} onChange={onChange} />
      <InteractionContainer id={viewportSceneObject.id} />
    </Stack>
  );
}

type Props = {
  id: string;
  onChange?: (object: any) => void;
};

const ViewportProperties = ({
  viewport,
  onChange,
}: { viewport: ViewportObject } & Partial<Props>) => {
  const handleViewportPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const objectCopy = structuredClone(viewport.backendProperties);
    const worldTransform = getWorldTransform(objectCopy.id);
    const selectedValue = event.target.value;

    if (field === 'position') {
      const newPosition = [...objectCopy.position];
      newPosition[propIndex] = parseFloat(selectedValue) / scene_scale;
      // objectCopy.position = newPosition as any;
      const localTransform = toLocalTransform(objectCopy.id, {
        position: newPosition as [number, number, number],
        rotation: worldTransform.rotation,
        scale: worldTransform.scale,
      });

      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'rotation') {
      const newRotation = [...objectCopy.rotation];
      newRotation[propIndex] = degToRad(parseFloat(selectedValue));
      // objectCopy.rotation = newRotation as any;

      const localTransform = toLocalTransform(objectCopy.id, {
        position: worldTransform.position,
        rotation: newRotation as [number, number, number],
        scale: worldTransform.scale,
      });
      objectCopy.position = localTransform.position as [number, number, number];
      objectCopy.rotation = localTransform.rotation as [number, number, number];
      objectCopy.scale = localTransform.scale as [number, number, number];
    } else if (field === 'label') {
      const newLabel = selectedValue;
      if (newLabel !== 'viewport-primary') {
        objectCopy.metadata.label = newLabel;
      }
    }

    onChange?.(objectCopy);
  };

  if (!viewport) return null;

  const worldTransform = getWorldTransform(viewport.id);
  const isPrimary = viewport.localProperties.isPrimary;

  return (
    <div>
      <PropertyHeading>Transform</PropertyHeading>
      <label className="label">Position</label>
      <PropertyField
        value={(worldTransform.position[0] * scene_scale).toFixed(2)}
        field="position"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <PropertyField
        value={(worldTransform.position[1] * scene_scale).toFixed(2)}
        field="position"
        instanceIndex={-1}
        propIndex={1}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <PropertyField
        value={(worldTransform.position[2] * scene_scale).toFixed(2)}
        field="position"
        instanceIndex={-1}
        propIndex={2}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />

      <br />
      <label className="label">Rotation</label>
      <PropertyField
        value={radToDeg(worldTransform.rotation[0]).toFixed(2)}
        field="rotation"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />

      <PropertyField
        value={radToDeg(worldTransform.rotation[1]).toFixed(2)}
        field="rotation"
        instanceIndex={-1}
        propIndex={1}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />

      <PropertyField
        value={radToDeg(worldTransform.rotation[2]).toFixed(2)}
        field="rotation"
        instanceIndex={-1}
        propIndex={2}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <label className="label">Label</label>
      <PropertyField
        value={viewport.backendProperties.metadata.label}
        field="label"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="string"
        disabled={isPrimary}
      />
    </div>
  );
};

export const InteractionContainer = ({ id }: Props) => {
  const interactionObject = useAppSelector((store) =>
    store.sceneViewer.sceneObjectList.find(
      (item) =>
        item.backendProperties.parent_group_id === id &&
        item.type === SupportedSceneObjectTypes.interactions
    )
  ) as any;

  return <InteractionsProperties config={interactionObject?.backendProperties} parent={id} />;
};

export const HeadProperties = ({ id, onChange }: Props) => {
  const sceneObject = useAppSelector((store) =>
    store.sceneViewer.sceneObjectList.find(
      (item) =>
        item.backendProperties.parent_group_id === id &&
        item.type === SupportedSceneObjectTypes.head
    )
  ) as HeadObject;

  const handleViewportPropertyChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    propIndex: number
  ) => {
    const selectedValue = event.target.value;
    const objectCopy = structuredClone(sceneObject.backendProperties);

    if (field === 'height') {
      const newHeight = parseInt(selectedValue);
      objectCopy.metadata.height = newHeight;
    } else if (field === 'width') {
      const newWidth = parseInt(selectedValue);
      objectCopy.metadata.width = newWidth;
    } else if (field === 'fov') {
      const newFov = parseFloat(selectedValue);
      objectCopy.metadata.fov = newFov;
    }

    onChange?.(objectCopy);
  };

  if (!sceneObject) return null;

  return (
    <div>
      <PropertyHeading>Camera Properties</PropertyHeading>
      <label className="label">Height</label>
      <PropertyField
        value={sceneObject.backendProperties.metadata.height * scene_scale}
        field="height"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <label className="label">Width</label>
      <PropertyField
        value={sceneObject.backendProperties.metadata.width * scene_scale}
        field="width"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
      <label className="label">FOV</label>
      <PropertyField
        value={sceneObject.backendProperties.metadata.fov.toFixed(2)}
        field="fov"
        instanceIndex={-1}
        propIndex={0}
        handlePropertyChange={handleViewportPropertyChange}
        type="number"
      />
    </div>
  );
};
