import { ArrowBackIcon, AddIcon, ChatIcon } from '@chakra-ui/icons';
import './TopPanel.css';
import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/react';
import { FaLocationArrow } from 'react-icons/fa';
import { HiSparkles } from 'react-icons/hi2';
import { FaFigma } from 'react-icons/fa6';

import { CiImageOn } from 'react-icons/ci';
import { BsCameraVideo, BsColumns } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import store from 'src/store/store';
import { useRef } from 'react';
import { GrCube } from 'react-icons/gr';
import AssetDrawer from '../AssetDrawer';
import { InstanceToolType, ModalType, SupportedSceneObjectTypes, ViewportObject } from 'src/types';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { setActiveTool } from 'src/store/reducers/InstanceReducer';
import { openModal } from 'src/store/reducers/modals';
import { generateNewViewportLabel } from 'src/utils/helper';
import { useSceneViewer } from './hooks/useSceneViewer';
import { useAssetUpload } from 'src/features/SceneViewer/hooks/useAssetUpload';

export default function TopPanel() {
  const activeTooltype = useAppSelector((store) => store.instance.activeTool);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uploadAssets } = useAssetUpload();
  const { onCreateViewport } = useSceneViewer();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputImageRef = useRef<any>(null);
  const input3DAssetRef = useRef<any>(null);

  const goBack = () => {
    navigate(-1);
  };

  const addViewport = () => {
    const scene_id = store.getState().instance.current_sceneId;

    const viewportObjectList = store
      .getState()
      .sceneViewer.sceneObjectList.filter(
        (item) => item.type === SupportedSceneObjectTypes.viewport
      ) as ViewportObject[];

    const label = generateNewViewportLabel(viewportObjectList);

    const newViewport = {
      scene_id: scene_id,
      position: [0.0, 1.7, 3.0],
      name: label,
      metadata: {
        label,
      },
    };

    onCreateViewport(newViewport);
  };

  const handleAssetChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      uploadAssets(e.target.files);
      e.target.value = '';
      onClose();
    }
  };

  const handleClickImage = () => {
    // open file input box on click of another element
    inputImageRef.current.click();
  };

  const handleClick3DAsset = () => {
    // open file input box on click of another element
    input3DAssetRef.current.click();
  };

  const handleFigmaImport = () => {
    dispatch(
      openModal({
        type: ModalType.figmaImport,
      })
    );
  };

  const onSelectClick = () => {
    dispatch(setActiveTool(undefined));
  };

  const onCommentsClick = () => {
    dispatch(setActiveTool(InstanceToolType.comment));
  };

  const onAICompanionClick = () => {
    dispatch(
      openModal({
        type: ModalType.sceneAI,
        data: {},
      })
    );
  };

  return (
    <div className="topPanel">
      <div className="container">
        <Tooltip bg="grey" label="Back to storyboard">
          <Button onClick={goBack} className="btn">
            <Icon color="white">
              <ArrowBackIcon />
            </Icon>
          </Button>
        </Tooltip>
        <Menu>
          <Tooltip bg="grey" label="Add">
            <MenuButton
              as={IconButton}
              icon={<AddIcon />}
              className="btn"
              p={2}
              color="white"
              transition="all 0.2s"
            />
          </Tooltip>
          <MenuList mt={2} px={2} py={2}>
            <MenuItem icon={<BsCameraVideo size={16} />} className="menuItem" onClick={addViewport}>
              <span>Add Viewport</span>
            </MenuItem>
            <MenuItem
              icon={<CiImageOn size={16} />}
              onClick={handleClickImage}
              className="menuItem"
            >
              <input
                style={{ display: 'none' }}
                ref={inputImageRef}
                type="file"
                multiple
                onChange={handleAssetChange}
                accept=".jpg,.jpeg,.png"
              />
              <span>Upload Image</span>
            </MenuItem>
            <MenuItem icon={<FaFigma size={16} />} onClick={handleFigmaImport} className="menuItem">
              <span>Import from Figma</span>
            </MenuItem>
            <MenuItem icon={<GrCube size={16} />} onClick={handleClick3DAsset} className="menuItem">
              <input
                style={{ display: 'none' }}
                ref={input3DAssetRef}
                type="file"
                multiple
                onChange={handleAssetChange} // make seperate functions for this
                accept="*"
              />
              <span>Upload 3D Model</span>
            </MenuItem>
          </MenuList>
        </Menu>
        <Tooltip bg="grey" label="Asset library">
          <Button onClick={onOpen} className="btn">
            <Icon as={BsColumns} color="white"></Icon>
          </Button>
        </Tooltip>
        <AssetDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        <Tooltip bg="grey" label="comments (c)">
          <Button
            className={`btn btn-normal ${
              activeTooltype === InstanceToolType.comment ? 'btn-active' : ''
            }`}
            onClick={onCommentsClick}
          >
            <Icon color="white">
              <ChatIcon />
            </Icon>
          </Button>
        </Tooltip>
        <Tooltip bg="grey" label="select (v)">
          <Button
            className="btn btn_pointer"
            onClick={onSelectClick}
            style={{ transform: 'scaleX(-1)' }}
          >
            <FaLocationArrow color="white" />
          </Button>
        </Tooltip>
        <Tooltip bg="grey" label="Text to 3D">
          <Button className="btn btn-normal" width="auto" m={0} onClick={onAICompanionClick}>
            <HiSparkles size={18} color={theme.colors.yellow[200]} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
