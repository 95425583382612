import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { SceneObjectActionTypes, SupportedSceneObjectTypes, ViewportObject } from 'src/types';
import { useGLTF } from '@react-three/drei';
import { useSceneViewer } from '../hooks/useSceneViewer';
import { MeshStandardMaterial } from 'three';

type SceneViewportAssetProps = {
  config: ViewportObject;
  color?: string;
  onClick?: Function;
  handleDrag?: Function;
  disablePivot?: boolean;
  onRightClick?: Function;
  onPointerOut?: () => void;
  onPointerOver?: () => void;
  onDoubleClick?: Function;
};

const defaultSceneViewportAssetProps = {
  onClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  handleDrag: (l: THREE.Matrix4, dl: THREE.Matrix4, w: THREE.Matrix4, dw: THREE.Matrix4) => {},
  onRightClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  onDoubleClick: (event: ThreeEvent<MouseEvent>, id: string, type: SupportedSceneObjectTypes) => {},
  disablePivot: false,
};

function SceneViewportAsset(
  props: SceneViewportAssetProps & typeof defaultSceneViewportAssetProps
) {
  const scaleMatrix = new THREE.Matrix4();
  scaleMatrix.scale(new THREE.Vector3(1.0, 1.0, 1.0));

  return (
    <group
      position={props.config.backendProperties.position}
      rotation={props.config.backendProperties.rotation}
      onPointerOver={props.onPointerOver}
      onPointerOut={props.onPointerOut}
    >
      <CameraMesh
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        config={props.config}
        onDoubleClick={props.onDoubleClick}
        color={props.color}
      />
    </group>
  );
}

const CameraMesh = (props: {
  config: ViewportObject;
  onClick: Function;
  onRightClick: Function;
  onDoubleClick: Function;
  color?: string;
}) => {
  const viewportMesh = useGLTF('Viewport.glb').scene.clone();
  const { handleSceneObjectAction } = useSceneViewer();

  if (props.config.localProperties.originalBBox === undefined) {
    const bbox = new THREE.Box3().setFromObject(viewportMesh, true);
    handleSceneObjectAction(SceneObjectActionTypes.update, [
      {
        id: props.config.id,
        type: props.config.type,
        localProperties: {
          originalBBox: bbox,
        },
        backendProperties: {},
      },
    ]);
  }

  if (props.color) {
    viewportMesh.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        const material = new MeshStandardMaterial({
          color: props.color,
          emissive: props.color,
          roughness: 1,
          transparent: true,
          opacity: 0.5,
          metalness: 0,
        });

        child.material = material;
      }
    });
  }

  return (
    <primitive
      object={viewportMesh}
      onClick={(event: ThreeEvent<MouseEvent>) =>
        props.onClick(event, props.config.id, SupportedSceneObjectTypes.viewport)
      }
      onContextMenu={(event: ThreeEvent<MouseEvent>) =>
        props.onRightClick(event, props.config.id, SupportedSceneObjectTypes.viewport)
      }
      onDoubleClick={(event: ThreeEvent<MouseEvent>) =>
        props.onDoubleClick(event, props.config.id, SupportedSceneObjectTypes.viewport)
      }
    />
  );
};

SceneViewportAsset.defaultProps = defaultSceneViewportAssetProps;
export default SceneViewportAsset;
