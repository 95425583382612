import { AbsoluteCenter, Box, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { deleteCommentAPI, getAllComments } from 'src/apis';
import { deleteComment, setComments, setSelectedComment } from 'src/store/reducers/comments';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import Comment from './Comment';
import { ToolType } from 'src/types';
import { removeNode } from 'src/store/reducers/storyboard';

const Comments = () => {
  const dispatch = useAppDispatch();
  const tool = useAppSelector((store) => store.storyboard.tool === ToolType.comment);
  const comments = useAppSelector((store) => store.comments.ids).filter(
    (id) => !store.getState().comments.entities[id].parent_id
  );

  useEffect(() => {
    const projectId = store.getState().app.projectId;
    getAllComments(projectId).then((comments: any[]) => {
      dispatch(setComments(comments));
    });
  }, []);

  const onClick = (id: string) => {
    dispatch(setSelectedComment(id));
  };

  const handleDeleteComment = async (id: string) => {
    try {
      await deleteCommentAPI(id);
      dispatch(deleteComment(id));
      dispatch(removeNode(id));
    } catch (e) {
      console.error(e);
    }
  };

  if (!tool) return <></>;

  return (
    <Box
      color="white"
      id="comments"
      zIndex={10}
      position="absolute"
      right={0}
      top={0}
      height="100%"
    >
      <Stack
        height="100%"
        maxW={360}
        minW={360}
        shadow="lg"
        bg="none"
        background="#191b23a0"
        backdropFilter="auto"
        backdropBlur="20px"
      >
        <Flex flexDirection="column" px={4} py={1} mt={2}>
          <Box position="relative">
            <Divider />
            <AbsoluteCenter px={4} bg="#191b23" color="gray.600" borderRadius="md">
              Comments
            </AbsoluteCenter>
          </Box>
        </Flex>
        <Stack px={2}>
          {comments.map((id: string) => (
            <Comment id={id} key={id} onClick={onClick} onDelete={handleDeleteComment} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Comments;
