import { Tooltip } from '@chakra-ui/react';

const index = ({ children, label }: any) => {
  return (
    <Tooltip label={label} bg="#353642" color="white" fontSize="small" rounded={10} p={1} px={2}>
      {children}
    </Tooltip>
  );
};

export default index;
