interface StoryBoardNode {
  id: string;
  project_id: string;
  data: Record<string, any>;
  position: [number, number];
  created_at?: string;
}

export enum SupportedNodeTypes {
  scene = 'SNode',
  text = 'TNode',
  comment = 'CNode',
}

export interface SceneNode extends StoryBoardNode {
  type: SupportedNodeTypes.scene;
}

export interface TextNode extends StoryBoardNode {
  type: SupportedNodeTypes.text;
}

export interface Comment {
  id: string;
  parent_id?: string;
  comment?: string;
  created_at: string;
  user_id: string;
  project_id: string;
  filename?: string;
}

export enum ModeType {
  move = 'move',
  insert = 'insert',
}

export enum ModalType {
  delete = 'delete',
  sceneAI = 'scene-ai',
  assetPreview = 'asset-preview',
  figmaImport = 'figma-import',
  figmaImportSettings = 'figma-import-settings',
}

export type ModalAction = {
  type: ModalType;
  entity?: SupportedNodeTypes;
  data?: any;
};

export enum ToolType {
  text = 'text',
  scene = 'scene',
  comment = 'comment',
}

export interface Mode {
  type: ModeType;
  cursor: string;
  data?: {
    activeTool: ToolType;
  };
}

export enum CommentType {
  text = 'text',
  voice = 'voice',
}

export enum CommentsType {
  storyboard = 'storyboard',
  scene = 'scene',
}

export enum NodeCreationType {
  new = 'new',
  duplicate = 'duplicate',
}

export const DUMMY_NODE = 'dummy-node';

export const ModeState = {
  move: { type: ModeType.move, cursor: 'auto' },
  insert: { type: ModeType.insert, cursor: 'crosshair' },
};
