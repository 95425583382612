import { Center, Heading, IconButton, useToast } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { useAppSelector } from 'src/store/reducers/hook';
import store from 'src/store/store';
import { getConnectedNodes } from 'src/utils/storyboard';
import { tinykeys } from 'tinykeys';
import { useSceneViewer } from './hooks/useSceneViewer';

const SceneSwitcher = ({ scenes, setScenes }: any) => {
  const toast = useToast();
  const sceneId = useAppSelector((s) => s.instance.current_sceneId);
  const sceneNodes = useAppSelector((s) => s.storyboard.nodes);
  const { changeScene } = useSceneViewer();

  const [keyPressed, setKeyPressed] = useState<string | null>(null);

  const title = useMemo(() => {
    const scene = store.getState().storyboard.nodes.find((node) => node.id === sceneId);
    return scene?.data.title || '';
  }, [sceneId, sceneNodes]);

  useEffect(() => {
    if (!scenes || sceneNodes?.length || keyPressed) {
      const nodes = getConnectedNodes(sceneId);
      setScenes(nodes);
    }
  }, [keyPressed, sceneId, sceneNodes]);

  useEffect(() => {
    const onKeyUpListener = tinykeys(
      window,
      {
        Alt: (event) => {
          event.preventDefault();
          setTimeout(() => {
            toast.close('scene-switcher');
          }, 500);
        },
        Home: (event) => {
          event.preventDefault();
          setKeyPressed(null);
        },
        End: (event) => {
          event.preventDefault();
          setKeyPressed(null);
        },
        'Alt+ArrowLeft': (event) => {
          event.preventDefault();
          setKeyPressed(null);
        },
        'Alt+ArrowRight': (event) => {
          event.preventDefault();
          setKeyPressed(null);
        },
      },
      {
        event: 'keyup',
      }
    );

    const onKeyDownListener = tinykeys(
      window,
      {
        Home: (event) => {
          event.preventDefault();
          onArrowLeft(scenes);
        },
        End: (event) => {
          event.preventDefault();
          onArrowRight(scenes);
        },
        'Alt+ArrowLeft': (event) => {
          event.preventDefault();
          onArrowLeft(scenes);
        },
        'Alt+ArrowRight': (event) => {
          event.preventDefault();
          onArrowRight(scenes);
        },
      },
      { event: 'keydown' }
    );

    return () => {
      onKeyUpListener();
      onKeyDownListener();
    };
  }, [scenes]);

  const onArrowLeft = (scenes: any) => {
    if (!scenes?.left?.length) return;

    const id = scenes.left.at(0)?.id;
    if (id) changeScene(id);
    setKeyPressed('left');
  };

  const onArrowRight = (scenes: any) => {
    if (!scenes?.right?.length) return;

    const id = scenes.right.at(0)?.id;
    if (id) changeScene(id);
    setKeyPressed('right');
  };

  if (!scenes) return <></>;

  return (
    <Center gap={4} position="relative">
      <IconButton
        variant="ghost"
        aria-label="Left Scene"
        size="sm"
        isDisabled={scenes.left.length === 0}
        onClick={(e) => {
          e.stopPropagation();
          onArrowLeft(scenes);
        }}
        transform={keyPressed === 'left' ? 'translateX(-4px)' : 'translateX(0)'}
        icon={<FaArrowLeft />}
      />
      {title && (
        <Heading m={0} size="sm" textAlign="center">
          {title}
        </Heading>
      )}
      {scenes.left.length + 1}/{scenes.left.length + scenes.right.length + 1}
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onArrowRight(scenes);
        }}
        variant="ghost"
        size="sm"
        aria-label="Rigth Scene"
        isDisabled={scenes.right.length === 0}
        transform={keyPressed === 'right' ? 'translateX(4px)' : 'translateX(0)'}
        icon={<FaArrowRight />}
      />
    </Center>
  );
};

export default SceneSwitcher;
