import { Portal } from '@chakra-ui/react';
import TextAnchor from './TextAnchor';
import CommentAnchor from './CommentAnchor';
import SceneAnchor from './SceneAnchor';
import { useAppSelector } from 'src/store/reducers/hook';

type CursorAnchorsProps = {
  tool?: string;
};

const CursorAnchors = (props: CursorAnchorsProps) => {
  const tool = useAppSelector((store) => store.storyboard.tool);

  if (!tool) return null;

  return (
    <Portal>
      {
        {
          text: <TextAnchor />,
          comment: <CommentAnchor />,
          scene: <SceneAnchor />,
        }[tool]
      }
    </Portal>
  );
};

export default CursorAnchors;
