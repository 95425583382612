import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { MouseEventHandler, useMemo } from 'react';
import SceneThumbnail from 'src/features/StoryBoard/components/SceneThumbnail';
import { useAppSelector } from 'src/store/reducers/hook';
import { useSceneViewer } from '../hooks/useSceneViewer';

const StoryElement = ({ id, active }: any) => {
  const { changeScene } = useSceneViewer();
  const scene = useAppSelector((store) => store.storyboard.nodes.find((node) => node.id === id));

  if (!scene) return null;
  const isSelected = id === active;

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    changeScene(id);
  };

  const onError = (e: any) => {};
  return (
    <Stack dir="column" onClick={onClick}>
      <Box
        rounded="md"
        transition="all 0.2s"
        m={2}
        borderColor={isSelected ? 'blue.400' : 'transparent'}
        borderWidth="medium"
      >
        <SceneThumbnail sceneId={scene.id} w={40} cursor="pointer" rounded="md" onError={onError} />
      </Box>
      <Heading size="xs" textAlign="center" noOfLines={1}>
        {scene.data.title}
      </Heading>
    </Stack>
  );
};

const StoryLine = ({ scenes, setScenes }: any) => {
  const sceneId = useAppSelector((store) => store.instance.current_sceneId);

  const sortedScenes = useMemo(() => {
    if (!sceneId) return [];
    if (!scenes) return [sceneId];

    const left = scenes?.left?.toReversed()?.map((s: any) => s.id);
    const right = scenes?.right?.map((s: any) => s.id);

    return [...left, sceneId, ...right];
  }, [scenes]);

  return (
    <Flex justify="center" align="flex-start" px={6} py={2} gap={1} overflowX="auto" w="full">
      {sortedScenes.map((id: string) => (
        <StoryElement key={id} id={id} active={sceneId} />
      ))}
    </Flex>
  );
};

export default StoryLine;
