import { Suspense } from 'react';
import { useAppSelector } from 'src/store/reducers/hook';
import { SupportedControllerModels } from 'src/types';
import { stringToColor } from 'src/utils/colors';
import { quaternionToEuler } from 'src/utils/helper';
import SceneInteractionAsset from './scene/SceneInteractionAsset';
import SceneViewportAsset from './scene/SceneViewportAsset';

const FloatingUsers = () => {
  const following = useAppSelector((store) => store.collaboration.following);
  const users = useAppSelector((store) => store.collaboration.floating);
  const enabled = useAppSelector((store) => store.collaboration.enabled);

  if (!enabled || !users) return null;

  return (
    <>
      {Object.keys(users ?? {})
        .filter((key) => key !== following)
        .map((key) => {
          const userInfo = users[key];
          const { head, controllers } = (userInfo || {}) as any;

          const headPosition = head && ([head.position.x, head.position.y, head.position.z] as any);
          const headRotation =
            head &&
            (quaternionToEuler([
              head.rotation.x,
              head.rotation.y,
              head.rotation.z,
              head.rotation.w,
            ]) as any);

          const scale = [0.5, 0.5, 0.5];
          const color = stringToColor(key);

          return (
            <Suspense key={key}>
              {head && (
                <SceneViewportAsset
                  color={color}
                  config={
                    {
                      backendProperties: {
                        position: headPosition,
                        rotation: headRotation,
                        scale,
                      },
                      localProperties: {},
                    } as any
                  }
                />
              )}
              {controllers?.left && (
                <SceneInteractionAsset
                  config={
                    {
                      backendProperties: {
                        position: [
                          controllers.left.position.x,
                          controllers.left.position.y,
                          controllers.left.position.z,
                        ],
                        rotation: quaternionToEuler([
                          controllers.left.rotation.x,
                          controllers.left.rotation.y,
                          controllers.left.rotation.z,
                          controllers.left.rotation.w,
                        ]),
                        metadata: {
                          type: SupportedControllerModels.quest3,
                          subType: 'left',
                          colorConfig: {
                            root: color,
                          },
                        },
                        scale,
                      },
                      localProperties: {},
                    } as any
                  }
                  disablePivot
                />
              )}
              {controllers?.right && (
                <SceneInteractionAsset
                  config={
                    {
                      backendProperties: {
                        position: [
                          controllers.right.position.x,
                          controllers.right.position.y,
                          controllers.right.position.z,
                        ],
                        rotation: quaternionToEuler([
                          controllers.right.rotation.x,
                          controllers.right.rotation.y,
                          controllers.right.rotation.z,
                          controllers.right.rotation.w,
                        ]),
                        metadata: {
                          type: SupportedControllerModels.quest3,
                          subType: 'right',
                          colorConfig: {
                            root: color,
                          },
                        },
                        scale,
                      },
                      localProperties: {},
                    } as any
                  }
                />
              )}
            </Suspense>
          );
        })}
    </>
  );
};

export default FloatingUsers;
