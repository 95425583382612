import { FiShare2 } from 'react-icons/fi';
import { Button, useDisclosure } from '@chakra-ui/react';

import InviteModal from './components/InviteModal';

type Props = {};

const Invite = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        leftIcon={<FiShare2 size={18} />}
        colorScheme="blue"
        py={0}
        px={4}
      >
        Share
      </Button>

      <InviteModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Invite;
