export const throttle = (func: any, delay: number = 700) => {
  let inThrottle: any;
  return function (this: any, ...args: any) {
    const context = this;
    if (!inThrottle) {
      inThrottle = setTimeout(() => (inThrottle = false), delay);
      return func.apply(context, args);
    }
  };
};
