import './RightClickMenu.css';
import { SHORTCUTS } from 'src/utils/shortcuts';
import { Shortcut } from '../Shortcut';
import { useAppDispatch, useAppSelector } from 'src/store/reducers/hook';
import { openContextMenu } from 'src/store/reducers/modals';
import { useOutsideClick } from '@chakra-ui/react';
import { forwardRef, useRef } from 'react';

const Container = forwardRef<HTMLDivElement, any>((props, ref) => {
  const dispatch = useAppDispatch();

  useOutsideClick({
    ref: ref as any,
    handler: () => {
      if (props.isOpen) dispatch(openContextMenu({ items: undefined }));
    },
  });

  return <></>;
});

export default function RightClickMenu() {
  const { items: menuItems, position, isOpen } = useAppSelector((s) => s.modal.contextMenu);
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const onMenuItemClick = (key: string) => {
    if (!menuItems[key]?.disabled) {
      dispatch(openContextMenu({ items: undefined }));
      menuItems[key].function();
    }
  };

  const items = Object.keys(menuItems);

  if (!isOpen) return null;

  return (
    <div
      ref={ref}
      className="RightClickMenu"
      style={{ left: position.x - 16, top: position.y + 8 }}
    >
      <ul>
        {items.map((key) => {
          const shortcut = SHORTCUTS[key];

          return (
            <li
              key={key}
              style={shortcut?.style}
              onClick={(event) => onMenuItemClick(key)}
              className={
                menuItems[key]?.disabled ? 'RightClickMenu-li-disabled' : 'RightClickMenu-li-normal'
              }
            >
              {shortcut && <Shortcut key={key} shortcut={shortcut} kbd={false} />}
            </li>
          );
        })}
      </ul>
      <Container ref={ref} isOpen={isOpen} />
    </div>
  );
}
