import { createSlice } from '@reduxjs/toolkit';
import type { Comment } from 'src/types';

const initialState = {
  ids: [] as Array<string>,
  entities: {} as Record<string, Comment>,
  selected: undefined as string | undefined,
};

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments(state, { payload: comments }) {
      state.ids = comments.map((comment: Comment) => comment.id);
      state.entities = comments.reduce((acc: Record<string, Comment>, comment: any) => {
        acc[comment.id] = comment;
        return acc;
      }, {});
    },
    addComment(state, { payload: comment }) {
      state.ids.push(comment.id);
      state.entities[comment.id] = comment;
    },
    appendComments(state, { payload: comments }) {
      comments.forEach((comment: Comment) => {
        if (!state.entities[comment.id]) {
          state.ids.push(comment.id);
          state.entities[comment.id] = comment;
        }
      });
    },
    deleteComment(state, { payload: id }) {
      state.ids = state.ids.filter(
        (commentId) => commentId !== id && state.entities[commentId].parent_id !== id
      );
      delete state.entities[id];

      for (const key in state.entities) {
        if (state.entities[key].parent_id === id) {
          delete state.entities[key];
        }
      }

      // If the deleted comment was the selected comment, reset the selected comment
      if (state.selected === id) {
        state.selected = undefined;
      }
    },
    setSelectedComment(state, { payload: id }) {
      state.selected = id;
    },
    clearComments() {
      return initialState;
    },
  },
});

export const {
  setComments,
  appendComments,
  addComment,
  deleteComment,
  setSelectedComment,
  clearComments,
} = commentSlice.actions;

export default commentSlice.reducer;
