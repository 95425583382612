import React from 'react';
import StoryBoard from '../features/StoryBoard';
import Modals from 'src/components/Modals';

function Home() {
  return (
    <>
      <StoryBoard />
      <Modals />
    </>
  );
}
export default Home;
